import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";

export interface CountdownTimerProps {
  until: string;
  onEnd?: () => void;
}

const CountdownTimer = ({ until, onEnd }: CountdownTimerProps) => {
  const [diff, setDiff] = useState(0);

  const secondsLeftUntilDate = (targetDate: Date) => {
    const now = Date.now();
    const target = new Date(targetDate).getTime();
    const differenceMilliseconds = target - now;
    return Math.floor(differenceMilliseconds / 1000);
  };

  const onFinished = () => {
    if (onEnd) onEnd();
  };

  useEffect(() => {
    const updateDiff = () => {
      setDiff(secondsLeftUntilDate(new Date(until)));
    };

    updateDiff();
    const intervalId = setInterval(updateDiff, 1000);

    return () => clearInterval(intervalId);
  }, [until]);

  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      onFinished();
      return null;
    } else {
      return (
        <div className="flex space-x-2 text-white text-2xl font-bold">
          <span>{String(days).padStart(2, "0")}</span>:
          <span>{String(hours).padStart(2, "0")}</span>:
          <span>{String(minutes).padStart(2, "0")}</span>:
          <span>{String(seconds).padStart(2, "0")}</span>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col items-center">
      <span className="text-white text-2xl font-bold uppercase mb-2">
        {until && " Starting In"}
      </span>
      <Countdown date={Date.now() + diff * 1000} renderer={renderer} />
    </div>
  );
};

export default CountdownTimer;
