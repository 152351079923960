import React, { useEffect, useState } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch } from "store/index";
import DropdownSelect from "components/DropdownSelect";
import ButtonDc from "components/Button";
import ProfilePicModal from "components/ProfilePicModal";
import { setUploadingDocuments } from "store/wallet/walletSlice";
import LoadingAnim from "components/Loader";
interface defaultValue {
  text: string;
  value: string;
}

export default function UploadIdScreen({}) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const [imageUrl, setImageUrl] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState<defaultValue>({
    value: "Select ID type",
    text: "",
  });
  const { loader } = useAppSelector((state) => state.wallet);

  const options = [
    { text: "Passport", value: "passport" },
    { text: "Driver's License", value: "driver_license" },
    { text: "National ID", value: "national_id" },
  ];

  const onCancel = () => {
    navigate("/wallet/main");
  };

  const onContinue = () => {
    navigate("/wallet/upload-selfie");
  };

  const updateAvatar = (imgSrc: any) => {
    setImageUrl(imgSrc);
    dispatch(setUploadingDocuments(imageUrl));
    setModalOpen(false);
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="text-3xl text-white items-center font-normal max-md:text-xl">
                  Upload Id
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center gap-y-8">
            {!imageUrl ? (
              <div className="border-2 border-dotted border-placeholder rounded-xl h-[200px] w-[300px] max-md:h-[30vh] max-md:w-full mt-8 flex justify-center items-center">
                <span className="text-white">No Image Selected.</span>
              </div>
            ) : (
              <img
                src={imageUrl}
                alt="image"
                className="  h-[200px] w-[300px] max-md:h-2/3 max-md:w-full mt-8 flex justify-center items-center"
              />
            )}

            <span className="text-center text-white text-2xl">ID Type:</span>

            <DropdownSelect
              placeholder="Select ID Type"
              options={options}
              defaultValue={type}
              action={(data) => setType(data)}
            />
            <div className="flex flex-row justify-center gap-10 mt-16 max-md:flex-col">
              <div>
                <ButtonDc
                  text="Select Image"
                  short
                  action={() => setModalOpen(true)}
                />
              </div>
              <div>
                <ButtonDc
                  text="Proceed"
                  short
                  disabled={!imageUrl}
                  type={"primary"}
                  action={() => onContinue()}
                />
              </div>
            </div>
          </div>
        </div>
        {modalOpen && (
          <ProfilePicModal
            updateAvatar={updateAvatar}
            closeModal={() => setModalOpen(false)}
            title="Upload your Document"
            type="ID"
          />
        )}
        <LoadingAnim loading={loader} />
      </div>
    </div>
  );
}
