export const caseRegex = /(?=.*[a-z])(?=.*[A-Z])/;
export const onlyNumberRegex = /^[0-9]+/;
export const numberRegex = /^[0-9]*$/;
export const specialCharacterRegex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneNoRegex = /^[+(\s.\-/\d)]{5,30}$/;
export const usernameRegex = /^[a-zA-Z\d_-]{4,10}$/;

export const usernameOrEmail =
  /^(?:[a-zA-Z\d][a-zA-Z\d_-]{3,10}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i;

export const nameWithSpace = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9]*$/;
export const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z].*){8,}.+$/;

export const atLeastOneNumber = /^(?=.*\d).+$/;
export const atLeastOneLowercase = /(?=.*[a-z].*).+$/;
export const atLeastOneUppercase = /(?=.*[A-Z].*).+$/;
export const min8chars = /^.{8,}$/;

export const nameWithoutPureNumeric =
  /^(?=.*[A-Za-z])[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9]*$/;
export const paragraphRegex = /\S/;
