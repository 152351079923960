import React, { useEffect } from "react";
import "../styles/components/events.scss";
import Default from "assets/temp/default_profile.png";
import Prize from "../assets/prize-pool.png";
import { ReactComponent as PrizeIcon } from "../assets/prize.svg";
import { RootState } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getWinners } from "../store/winners/action";
import ImageHolder from "./ImagePlaceholder";
import miscsStyles from "styles/miscs/miscs.module.scss";
import { useNavigate } from "react-router-dom";
import { getOtherUser } from "store/user/action";

interface WinnerProps {
  name: string;
  image: string;
  date: string;
  score: string;
  _id: string;
}

const DailyWinners = () => {
  const user = useAppSelector((state: RootState) => state.auth);
  const prizePool = user.prizePool;
  const winners = useAppSelector((state) => state.winners).users;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getWinners());
  }, [dispatch]);

  const handleDailyWinnerPress = (item: WinnerProps) => {
    if (item._id === user.user._id) {
      return navigate(`/profile/${item._id}`);
    }
    dispatch(getOtherUser(item._id)).then(() =>
      navigate(`/profile/${item._id}`)
    );
  };

  return (
    <div className="flex flex-row md:h-[172px] gap-x-3 w-full flex-wrap gap-y-4 h-auto md:flex-nowrap">
      <div className="flex flex-row items-center p-1 eve rounded-lg min-w-fit max-md:w-full">
        <div className="flex flex-col p-4 ">
          <div className="flex w-[141px] h-[45px] justify-center items-center gap-2 rounded-lg bg-opacity-20 bg-white shadow-md">
            <PrizeIcon />
            <span className="text-white text-center font-inter font-semibold text-sm tracking-wide leading-normal ">
              Prize Pool
            </span>
          </div>
          <span className="text-white text-center font-inter font-semibold text-4xl  leading-normal tracking-tight ">
            ${parseInt(prizePool || "0").toFixed(2)}
          </span>
        </div>
        <ImageHolder uri={Prize} width={200} />
      </div>
      <div
        className={`flex flex-row h-[172px] rounded-[12px] overflow-hidden hover:[overflow:overlay] ${miscsStyles.beautifulScrollbarHidden}`}
      >
        {winners.map((winner, index) => (
          <div className="flex ">
            <button
              key={index}
              className=" w-[10em] flex flex-col p-2 items-center justify-center eve rounded-lg mx-1"
              onClick={() => handleDailyWinnerPress(winner)}
            >
              <ImageHolder
                uri={winner.profilePicture}
                className=" w-[6em] rounded-2xl"
              />
              <span className="text-white text-center font-inter font-semibold text-sm  leading-normal tracking-wide mt-1 ">
                {winner?.username}
              </span>
              <span className="text-center font-inter text-lightGreen text-lg font-semibold leading-normal tracking-tight  ">
                {winner.score} DC
              </span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DailyWinners;
