import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "assets/close-button.svg";

export default function TapGame() {
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(10);
  const [isGameActive, setIsGameActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isGameActive && timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else if (timeLeft === 0) {
      setIsGameActive(false);
      setShowModal(true);
    }
    return () => clearTimeout(timer);
  }, [isGameActive, timeLeft]);

  const startGame = () => {
    setScore(0);
    setTimeLeft(10);
    setIsGameActive(true);
    setShowModal(false);
  };

  const incrementScore = () => {
    if (isGameActive) {
      setScore(score + 1);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const handleClose = () => {
    navigate("/gaming/games");
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat">
      <div className="m-20 flex flex-row justify-between items-center w-[80vw] max-md:w-[95vw]">
        <span className="text-white text-3xl text-center max-md:text-2xl">
          Tap
        </span>
        <button
          className=" w-[25px] h-[25px] right-10 max-md:w-[15px]"
          onClick={handleClose}
        >
          <img src={CloseIcon} alt="Close" />
        </button>
      </div>
      <div className="h-1/2 w-1/2 flex flex-col items-center text-white gap-4 justify-center bg-gray-400 overflow-hidden rounded-xl bg-opacity-20">
        <h1 className="text-3xl font-bold mb-4">Tap the Button!</h1>
        <p className="text-xl mb-2">Score: {score}</p>
        <p className="text-xl mb-4">Time Left: {timeLeft}s</p>
        {!isGameActive ? (
          <button
            className="bg-blue-500 hover:bg-blueBg text-white font-bold py-2 px-4 rounded"
            onClick={startGame}
          >
            Start Game
          </button>
        ) : (
          <button
            className="bg-green-500 hover:bg-darkGreen text-white font-bold py-2 px-4 rounded"
            onClick={incrementScore}
          >
            Tap Me!
          </button>
        )}
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="flex flex-col items-center justify-center text-white bg-background p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl mb-4">Game Over</h2>
            <p className="text-xl mb-4">Your final score is {score}</p>
            <button
              className="bg-blue-500 hover:bg-blueBg text-white font-bold py-2 px-4 rounded"
              onClick={closeModal}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
