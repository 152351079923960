import React from "react";
import CloseIcon from "assets/close-button.svg";
import ButtonDc from "./Button";

interface LinkConsoleModalProps {
  visible: boolean;
  data?: any[];
  onClose?: () => void;
  onClick?: () => void;
}

const LinkConsoleModal: React.FC<LinkConsoleModalProps> = ({
  visible,
  data,
  onClick,
  onClose,
}) => {
  if (visible) {
    const Icon = data?.find((item) => item.hasOwnProperty("logo"));
    const btnIcon = data?.find((item) => item.hasOwnProperty("btnLogo"));
    const headerObj = data?.find((item) => item.hasOwnProperty("header"));
    const messageObj = data?.find((item) => item.hasOwnProperty("message"));
    const platFormObj = data?.find((item) => item.hasOwnProperty("platForm"));
    const Logo = Icon?.logo;
    

    return (
      <div className="w-full h-full bg-modalFade  fixed z-10 ">
        <div
          className="w-3/5 h-4/5 p-[30px] bg-cardBackground rounded-[34px] shadow-lg absolute top-1/2 
          left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-[50px] max-md:w-full"
        >
         
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <button
                className=" w-[25px] h-[25px] absolute right-10 top-10"
                onClick={onClose}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <div className="flex flex-col items-center gap-16 h-[60vh] justify-center  max-md:flex-col ">
              {Logo && <img src={Logo} alt="img" />}
              <span className="text-2xl max-md:text-xl text-white items-center text-center font-bold ">
                {headerObj.header}
              </span>
              <span className="text-xl max-md:text-sm text-subText items-center text-center w-1/2 ">
                {messageObj.message}
              </span>
            </div>
            <ButtonDc
              type="primary"
              className="max-md:w-full "
              textClassName="max-md:text-sm"
              text={`Connect your ${platFormObj?.platForm}`}
              action={() => onClick?.()}
            />
          </div>
       
      </div>
    );
  }
  return <></>;
};

export default LinkConsoleModal;
