import Api from "../services/api";
import { endMatchUrl, getLobbyUrl, setReadyForMatchUrl, startGameUrl } from "./urls";

export const getLobbyApi = (matchId: string) => {
  return Api.request({
    method: "get",
    url: getLobbyUrl,
    publicApi: false,
    data: {
      matchId,
    },
  });
};

export const setReadyForMatchApi = (matchId: string) => {
  return Api.request({
    method: "put",
    url: setReadyForMatchUrl,
    publicApi: false,
    body: {
      matchId,
    },
  });
};


export const startMatchApi = (matchId: string) => {
  return Api.request({
    method: "post",
    url: startGameUrl,
    publicApi: false,
    body: {
      matchId,
    },
  });
};

export const endMatchApi = (matchId: string) => {
  return Api.request({
    method: "post",
    url: endMatchUrl,
    publicApi: false,
    body: {
      matchId,
    },
  });
};

export const cancelMatchApi = (matchId: string) => {
  return Api.request({
    method: "post",
    url: endMatchUrl,
    publicApi: false,
    body: {
      matchId,
    },
  });
};