import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import CloseIcon from "assets/close-button.svg";

const GRID_SIZE = 4;

export default function Game2048({}) {
  const [grid, setGrid] = useState<number[][]>([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    initializeGame();
  }, []);

  const initializeGame = () => {
    const newGrid = Array(GRID_SIZE)
      .fill(null)
      .map(() => Array(GRID_SIZE).fill(0));
    addRandomTile(newGrid);
    addRandomTile(newGrid);
    setGrid(newGrid);
    setScore(0);
    setGameOver(false);
  };

  const addRandomTile = (currentGrid: number[][]) => {
    const emptyCells = [];
    for (let i = 0; i < GRID_SIZE; i++) {
      for (let j = 0; j < GRID_SIZE; j++) {
        if (currentGrid[i][j] === 0) {
          emptyCells.push({ i, j });
        }
      }
    }
    if (emptyCells.length > 0) {
      const { i, j } =
        emptyCells[Math.floor(Math.random() * emptyCells.length)];
      currentGrid[i][j] = Math.random() < 0.9 ? 2 : 4;
    }
  };

  const move = (direction: "up" | "down" | "left" | "right") => {
    if (gameOver) return;

    let newGrid = JSON.parse(JSON.stringify(grid));
    let moved = false;
    let newScore = score;

    const moveAndMergeTiles = (line: number[]) => {
      const movedLine = line.filter((tile) => tile !== 0);
      for (let i = 0; i < movedLine.length - 1; i++) {
        if (movedLine[i] === movedLine[i + 1]) {
          movedLine[i] *= 2;
          newScore += movedLine[i];
          movedLine.splice(i + 1, 1);
        }
      }
      while (movedLine.length < GRID_SIZE) {
        movedLine.push(0);
      }
      return movedLine;
    };

    if (direction === "left" || direction === "right") {
      for (let i = 0; i < GRID_SIZE; i++) {
        const line = newGrid[i];
        const movedLine = moveAndMergeTiles(
          direction === "left" ? line : line.reverse()
        );
        newGrid[i] = direction === "left" ? movedLine : movedLine.reverse();
        if (JSON.stringify(line) !== JSON.stringify(newGrid[i])) moved = true;
      }
    } else {
      for (let j = 0; j < GRID_SIZE; j++) {
        const line = newGrid.map((row: any[]) => row[j]);
        const movedLine = moveAndMergeTiles(
          direction === "up" ? line : line.reverse()
        );
        for (let i = 0; i < GRID_SIZE; i++) {
          newGrid[i][j] =
            direction === "up" ? movedLine[i] : movedLine[GRID_SIZE - 1 - i];
        }
        if (
          JSON.stringify(line) !==
          JSON.stringify(newGrid.map((row: any[]) => row[j]))
        )
          moved = true;
      }
    }

    if (moved) {
      addRandomTile(newGrid);
      setGrid(newGrid);
      setScore(newScore);
      if (isGameOver(newGrid)) {
        setGameOver(true);
      }
    }
  };

  const isGameOver = (currentGrid: number[][]) => {
    for (let i = 0; i < GRID_SIZE; i++) {
      for (let j = 0; j < GRID_SIZE; j++) {
        if (currentGrid[i][j] === 0) return false;
        if (i < GRID_SIZE - 1 && currentGrid[i][j] === currentGrid[i + 1][j])
          return false;
        if (j < GRID_SIZE - 1 && currentGrid[i][j] === currentGrid[i][j + 1])
          return false;
      }
    }
    return true;
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => move("left"),
    onSwipedRight: () => move("right"),
    onSwipedUp: () => move("up"),
    onSwipedDown: () => move("down"),
    delta: 10, // minimum distance(px) before a swipe starts
    preventScrollOnSwipe: true, // prevents scrolling during swipe
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
  });
  const getTileColor = (value: number) => {
    const colors: { [key: number]: string } = {
      2: "bg-2048-2 text-2048-light-text",
      4: "bg-2048-4 text-2048-light-text",
      8: "bg-2048-8 text-2048-dark-text",
      16: "bg-2048-16 text-2048-dark-text",
      32: "bg-2048-32 text-2048-dark-text",
      64: "bg-2048-64 text-2048-dark-text",
      128: "bg-2048-128 text-2048-dark-text",
      256: "bg-2048-256 text-2048-dark-text",
      512: "bg-2048-512 text-2048-dark-text",
      1024: "bg-2048-1024 text-2048-dark-text",
      2048: "bg-2048-2048 text-2048-dark-text",
    };
    return colors[value] || "bg-2048-super text-2048-dark-text";
  };
  const handleClose = () => {
    navigate("/gaming/games");
  };
  return (
    <div className="flex flex-col items-center  min-h-screen p-4  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat ">
      <div className="m-20 flex flex-row justify-between items-center w-[80vw] max-md:w-[95vw]">
        <span className="text-white text-3xl text-center max-md:text-2xl">
          Game 2048
        </span>
        <button
          className=" w-[25px] h-[25px] right-10 max-md:w-[15px]"
          onClick={handleClose}
        >
          <img src={CloseIcon} alt="Close" />
        </button>
      </div>
      <div className="md:h-1/2 md:w-1/2 p-10 flex flex-col items-center text-white gap-4 justify-center bg-gray-400 overflow-hidden rounded-xl bg-opacity-20">
        <h1 className="text-4xl text-center  font-bold mb-4">2048</h1>
        <div className="mb-4 text-center">
          <p className="text-xl">Score: {score}</p>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
            onClick={initializeGame}
          >
            New Game
          </button>
        </div>
        <div
          {...handlers}
          className="grid grid-cols-4 gap-2 bg-2048-board p-2 rounded touch-none cursor-grab select-none"
        >
          {grid.map((row, i) =>
            row.map((cell, j) => (
              <div
                key={`${i}-${j}`}
                className={`w-16 h-16 flex items-center justify-center text-2xl font-bold ${
                  cell ? getTileColor(cell) : "bg-2048-tile"
                }`}
              >
                {cell !== 0 && cell}
              </div>
            ))
          )}
        </div>
        {gameOver && (
          <div className="mt-4 text-2xl font-bold text-red-600">Game Over!</div>
        )}
        <div className="mt-4 text-center">
          <p>Swipe or use arrow keys to play</p>
        </div>
      </div>
    </div>
  );
}
