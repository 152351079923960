import React, { useState, useEffect, useRef, RefObject } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate } from "react-router-dom";

interface Bubble {
  x: number;
  y: number;
  color: string;
}

interface Position {
  x: number;
  y: number;
}

const BUBBLE_SIZE = 20;
const SHOOTER_Y = 480;
const SHOOTER_X_INITIAL = 125; // Half screen width
const BUBBLE_SPEED = 5;
const SHOOTER_SPEED = 4;
const GAME_WIDTH = 600; // Adjusted for smaller screen
const BUTTON_HEIGHT = 40; // Adjusted for button height

const generateInitialBubbles = (): Bubble[] => {
  return [
    { x: 100, y: 100, color: "red" },
    { x: 150, y: 120, color: "blue" },
    // Add more initial bubbles if needed
  ];
};

const generateRandomBubble = (x: number): Bubble => {
  const colors = ["red", "blue", "green", "yellow"];
  return {
    x,
    y: SHOOTER_Y - BUBBLE_SIZE,
    color: colors[Math.floor(Math.random() * colors.length)],
  };
};

export default function BubbleShooter() {
  const [bubbles, setBubbles] = useState<Bubble[]>(generateInitialBubbles());
  const [shooterPosition, setShooterPosition] = useState<Position>({
    x: SHOOTER_X_INITIAL,
    y: SHOOTER_Y,
  });
  const [currentBubble, setCurrentBubble] = useState<Bubble>(
    generateRandomBubble(SHOOTER_X_INITIAL)
  );
  const [isShooting, setIsShooting] = useState<boolean>(false);
  const [score, setScore] = useState<number>(0);
  const [movingRight, setMovingRight] = useState<boolean>(true);
  const navigate = useNavigate();
  const gameAreaRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    if (isShooting) {
      const interval = setInterval(() => {
        setCurrentBubble((bubble) => ({
          ...bubble,
          y: bubble.y - BUBBLE_SPEED,
        }));
      }, 16);

      return () => clearInterval(interval);
    }
  }, [isShooting]);

  useEffect(() => {
    if (currentBubble.y <= 0 || checkCollision()) {
      setIsShooting(false);
      handleBubbleCollision();
      setCurrentBubble(generateRandomBubble(shooterPosition.x));
    }
  }, [currentBubble]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === "Space") {
        event.preventDefault();
        if (!isShooting) shootBubble();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isShooting]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShooterPosition((prev) => {
        let newX = prev.x + (movingRight ? SHOOTER_SPEED : -SHOOTER_SPEED);
        if (newX >= GAME_WIDTH - BUBBLE_SIZE / 2) {
          newX = GAME_WIDTH - BUBBLE_SIZE / 2;
          setMovingRight(false);
        } else if (newX <= BUBBLE_SIZE / 2) {
          newX = BUBBLE_SIZE / 2;
          setMovingRight(true);
        }

        setCurrentBubble((bubble) => ({ ...bubble, x: newX }));

        return { ...prev, x: newX };
      });
    }, 16);

    return () => clearInterval(interval);
  }, [movingRight]);

  const handleBubbleCollision = () => {
    const matchingBubbles = bubbles.filter(
      (bubble) =>
        bubble.color === currentBubble.color && isNearby(bubble, currentBubble)
    );

    if (matchingBubbles.length > 0) {
      setBubbles((prevBubbles) =>
        prevBubbles.filter((bubble) => !matchingBubbles.includes(bubble))
      );
      setScore((prevScore) => prevScore + matchingBubbles.length);
    } else {
      setBubbles((prevBubbles) => [...prevBubbles, currentBubble]);
    }

    if (checkGameOver()) {
      alert("Game Over! Your score: " + score);
      resetGame();
    }
  };

  const checkCollision = (): boolean => {
    for (const bubble of bubbles) {
      if (isNearby(bubble, currentBubble)) {
        return true;
      }
    }
    return false;
  };

  const isNearby = (bubble1: Bubble, bubble2: Bubble): boolean => {
    const distance = Math.sqrt(
      Math.pow(bubble1.x - bubble2.x, 2) + Math.pow(bubble1.y - bubble2.y, 2)
    );
    return distance < BUBBLE_SIZE;
  };

  const shootBubble = () => {
    setIsShooting(true);
  };

  const checkGameOver = (): boolean => {
    return bubbles.some((bubble) => bubble.y >= SHOOTER_Y - BUBBLE_SIZE);
  };

  const resetGame = () => {
    setBubbles(generateInitialBubbles());
    setScore(0);
    setCurrentBubble(generateRandomBubble(shooterPosition.x));
  };
  const handleClose = () => {
    navigate("/gaming/games");
  };
  return (
    <div className="mx-auto w-full h-screen  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col items-center">
      <div className="mt-20 flex flex-row justify-between items-center w-[80vw] max-md:w-[95vw]">
        <span className="text-white text-3xl text-center max-md:text-2xl">
          Bubble Shooter
        </span>
        <button
          className=" w-[25px] h-[25px] right-10 max-md:w-[15px]"
          onClick={handleClose}
        >
          <img src={CloseIcon} alt="Close" />
        </button>
      </div>
      <h1 className="text-3xl font-bold mb-4 md:mt-20 text-white text-center">
        Bubble Shooter
      </h1>
      <p className="text-xl mb-2 text-center text-white">Score: {score}</p>
      <button
        className=" bg-blue-500 hover:bg-blue-700 text-white font-bold mb-10 py-2 px-4 rounded"
        onClick={resetGame}
        disabled={isShooting}
      >
        Reset
      </button>
      <div
        ref={gameAreaRef}
        className="relative bg-gray-400 overflow-hidden rounded-xl bg-opacity-20"
        style={{
          width: `${GAME_WIDTH}px`,
          height: `${SHOOTER_Y + BUTTON_HEIGHT}px`,
        }} // Increased height to fit button
      >
        {/* Shooter */}
        <div
          className="absolute"
          style={{
            left: shooterPosition.x - BUBBLE_SIZE / 2,
            top: shooterPosition.y - BUBBLE_SIZE - 30, // Adjusted to position above the button
          }}
        >
          <div
            className="w-10 h-10 rounded-full"
            style={{ backgroundColor: currentBubble.color }}
          />
        </div>

        {/* Shoot button */}
        <button
          className="absolute bottom-2 left-1/2 transform -translate-x-1/2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          onClick={shootBubble}
          disabled={isShooting}
        >
          Shoot
        </button>

        {/* Bubbles */}
        {bubbles.map((bubble, index) => (
          <div
            key={index}
            className="absolute"
            style={{
              left: bubble.x - BUBBLE_SIZE / 2,
              top: bubble.y - BUBBLE_SIZE / 2,
              width: BUBBLE_SIZE,
              height: BUBBLE_SIZE,
              backgroundColor: bubble.color,
              borderRadius: "50%",
            }}
          />
        ))}

        {/* Render the shooting bubble */}
        {isShooting && (
          <div
            className="absolute"
            style={{
              left: currentBubble.x - BUBBLE_SIZE / 2,
              top: currentBubble.y - BUBBLE_SIZE / 2,
              width: BUBBLE_SIZE,
              height: BUBBLE_SIZE,
              backgroundColor: currentBubble.color,
              borderRadius: "50%",
            }}
          />
        )}
      </div>
    </div>
  );
}
