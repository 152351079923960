import React, { useEffect } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import ImageHolder from "components/ImagePlaceholder";
import LoadingAnim from "components/Loader";
import { getLeaderboard, getOtherUser } from "store/user/action";
import miscsStyles from "styles/miscs/miscs.module.scss";

interface LeaderCardProps {
  profileImage: string;
  position: number;
  name: string;
  matches: number;
  wins: number;
  losses: number;
  last5?: string[];
  score: number;
  userId: string;
}

const LeaderCard: React.FC<LeaderCardProps> = ({
  profileImage,
  position,
  name,
  matches,
  wins,
  losses,
  last5,
  score,
  userId,
}) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);

  const crownEmojis = ["🥇", "🥈", "🥉"];
  const isTopThree = position < 3;
  const positionText = isTopThree
    ? `${crownEmojis[position]}`
    : `${position + 1}.`;

  const handleProfilePress = () => {
    if (userId === user._id) {
      navigate(`/profile/${userId}`);
    } else {
      dispatch(getOtherUser(userId)).then(() => navigate(`/profile/${userId}`));
    }
  };

  return (
    <button
      className={
        "flex justify-between bg-borderColor items-center p-2 px-5 rounded-xl text-white"
      }
      onClick={handleProfilePress}
    >
      <div className="flex items-center md:gap-3 col-span-1">
        <span className={`mr-2 ${isTopThree ? "text-2xl" : "px-1 "}`}>
          {positionText}
        </span>
        <div className="flex max-md:w-[40vw] items-center gap-3 col-span-2">
          <ImageHolder uri={profileImage} className="w-8 h-8 rounded-full" />
          <h2 className="text-sm font-semibold">{name}</h2>
        </div>
      </div>
      {/* <div className="col-span-1 text-center">{matches}</div>
      <div className="col-span-1 text-center">{wins}</div>
      <div className="col-span-1 text-center">{losses}</div>
      <div className="col-span-1 flex justify-center space-x-1">
        {last5?.map((result, index) => (
          <span
            key={index}
            className={`w-4 h-4 rounded-full ${
              result === "W" ? "bg-green-500" : "bg-red-500"
            }`}
          ></span>
        ))}
      </div>   //TODO*/}

      <div className="col-span-1 items-end text-right">{score}</div>
    </button>
  );
};

const LeaderboardScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  const leaderboard = user?.leaderboard || [];

  useEffect(() => {
    dispatch(getLeaderboard());
  }, [dispatch]);

  const renderItems = (item: any, index: number) => (
    <LeaderCard
      key={index}
      profileImage={item.profilePicture}
      position={index}
      name={item.username}
      matches={item.matches}
      wins={item.wins}
      losses={item.losses}
      last5={item.last5}
      score={item.score}
      userId={item._id}
    />
  );

  const handleClose = () => {
    navigate("/");
  };

  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen">
      <div className="container flex items-center flex-col gap-y-[20px] md:my-16 max-md:p-3">
        <div className="backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5 w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh]">
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white font-normal">Leaderboard</span>
            <button className="w-[25px] h-[25px]" onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="flex justify-between p-4 shadow-md">
            <span className="text-lg font-semibold text-subText">#Rank</span>
            <span className="text-lg font-semibold text-subText">
              DC Points
            </span>
          </div>
          <div
            className={`flex flex-col md:h-[calc(100vh-300px)] h-[calc(100vh-160px)] gap-3 overflow-y-auto ${miscsStyles.beautifulScrollbarHidden}`}
          >
            {leaderboard.map(renderItems)}
          </div>
        </div>
        <LoadingAnim loading={user.loader} />
      </div>
    </div>
  );
};

export default LeaderboardScreen;
