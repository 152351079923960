import React from "react";

interface ProgressLinesProps {
  count: number;
  selected: number;
  containerStyles?: string;
}

const ProgressLines: React.FC<ProgressLinesProps> = ({
  count,
  selected,
  containerStyles,
}) => {
  const lines = Array.from({ length: count }, (_, i) => i);
  return (
    <div className="w-full flex justify-center">
      <div
        className={`flex justify-center gap-2 items-center ${containerStyles}`}
      >
        {lines.map((_, i) => (
          <div className="flex-1" key={i}>
            <div
              className={
                i + 1 === selected
                  ? "h-3 bg-primary w-16 rounded-full"
                  : "h-3 bg-primary w-3 rounded-full"
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressLines;
