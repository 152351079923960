import React from "react";
import { ColorRing } from "react-loader-spinner";

interface LoaderProps {
  loading?: boolean;
}

const LoadingAnim: React.FC<LoaderProps> = ({ loading }) => {
  if (loading) {
    return (
      <div className="w-full h-screen bg-modalFade  fixed z-40 ">
        <div className=" p-[30px]  rounded-[34px] shadow-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-[50px] ">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#EEB033", "#fc3e3e", "#EEB033", "#fc3e3e", "#9a031e"]}
          />
        </div>
      </div>
    );
  }
  return <></>;
};

export default LoadingAnim;
