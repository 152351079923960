import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import miscsStyles from "styles/miscs/miscs.module.scss";

import { ReactComponent as Plus } from "assets/Add_Plus_Circle.svg";
import SearchBar from "components/Search";
import Sidebar from "components/SideBar";
import {
  getCommunityList,
  getPrivateChatList,
  joinCommunity,
} from "store/community/action";
import ImageHolder from "components/ImagePlaceholder";
import ChatModal from "components/ChatModal";
import Loader from "components/Loader";
import { Pagination, ThemeProvider, createTheme } from "@mui/material";
import { clearChats } from "store/messaging/messagingSlice";

interface Chat {
  _id: string;
  user: {
    _id: string;
    username: string;
    profilePicture: string;
  };
  latest: {
    user: string;
    message: string;
    _id: string;
    time: string;
  };
}

export default function CommunicationScreen({}) {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = useState("COMMUNITIES");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const [chatSearchResults, setChatSearchResults] = useState<any[]>([]);
  const [communitySearchResults, setCommunitySearchResults] = useState<any[]>(
    []
  );
  const { communityId } = useParams();
  const { chatList, loader } = useAppSelector(
    (state: RootState) => state.community
  );
  const communityList = useAppSelector(
    (state: RootState) => state.community.communities
  );
  const communityPage = useAppSelector(
    (state: RootState) => state.community.page
  );
  const chatPage = useAppSelector(
    (state: RootState) => state.community.chatPage
  );

  useEffect(() => {
    const communityData: any = communityList.filter(
      (data) => data._id === communityId
    );
    if (communityId) {
      setSelectedChat(communityData[0]);
      setModalOpen(true);
    }
  }, [communityId]);

  useEffect(() => {
    dispatch(getPrivateChatList(1));
    dispatch(getCommunityList(1));
  }, [dispatch]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(getPrivateChatList(value));
    dispatch(getCommunityList(value));
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const handleSearchChats = (matchItem: any) => {
    setSelectedChat(matchItem);
    setModalOpen(true);
  };

  const handleSearchCommunities = (matchItem: any) => {
    if (!matchItem.joined)
      dispatch(
        joinCommunity({
          payload: matchItem._id,
          callback: () => {
            setSelectedChat(matchItem);
            setModalOpen(true);
          },
        })
      );
    else {
      setSelectedChat(matchItem);
      setModalOpen(true);
    }
  };

  function formatTimeDifference(timestamp: string): string {
    const now = new Date();
    const messageTime = new Date(timestamp);
    const diffInMilliseconds = now.getTime() - messageTime.getTime();

    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays > 0) {
      return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
    } else if (diffInHours > 0) {
      return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
    } else {
      return `${diffInMinutes} ${
        diffInMinutes === 1 ? "minute" : "minutes"
      } ago`;
    }
  }

  const renderChats = (chat: Chat, index: number) => {
    return (
      <div
        className="flex flex-row w-full rounded-xl bg-borderColor mt-4 px-5 py-2 hover:ring-1 hover:ring-primary"
        key={chat._id}
        onClick={() => {
          setSelectedChat(chat);
          setModalOpen(true);
        }}
      >
        <div className="flex flex-row items-center justify-between gap-5 ">
          <ImageHolder
            className="flex w-[50px] h-[50px] rounded-full p-1"
            uri={chat.user?.profilePicture}
          />
          <div className="flex flex-col w-[50vw]">
            <div className="flex flex-row w-full justify-between">
              <span className="text-primary ">{chat.user?.username}</span>
              <span className="text-darkGray text-right">
                {formatTimeDifference(chat.latest?.time)}
              </span>
            </div>
            <span className="text-white text-sm">{chat.latest?.message}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommunities = (community: any, index: number) => {
    return (
      <div
        className="flex flex-row w-full rounded-xl bg-borderColor mt-4 px-4 py-2 hover:ring-1 hover:ring-primary"
        key={community._id}
        onClick={() => {
          setSelectedChat(community);
          setModalOpen(true);
        }}
      >
        <div className="flex flex-row items-center justify-between gap-5 ">
          <ImageHolder
            className="flex w-[70px] h-[50px] rounded-xl p-1"
            uri={community?.coverImage}
          />
          <div className="flex flex-col w-[50vw]">
            <div className="flex flex-row w-full justify-between">
              <span className="text-white ">{community.name}</span>
              <span className="text-darkGray text-right">
                {formatTimeDifference(community.updatedAt)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="mx-auto w-full  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
      <Sidebar />
      <div className=" w-full md:gap-[2em] flex flex-col items-center mt-10 max-md:gap-3 max-md:my-20 max-md:overflow-y-auto ">
        <div className="mt-[20px] flex flex-row ">
          <span className="text-white text-3xl text-center max-md:text-2xl">
            Communication
          </span>
        </div>
        <div className="flex max-md:flex-col md:p-4 gap-[2em] max-md:gap-8  md:gap-[5em] md:border-2 rounded-2xl bg-black bg-opacity-35 border-primary w-[80vw] h-[80vh]">
          <div className="flex md:flex-col md:mt-10  md:gap-5 max-md:gap-8 max-md:justify-center ">
            <button
              className={`max-md:w-[60vw] h-16 w-52 bg-borderColor rounded-xl border-0  flex flex-col items-center justify-center  hover:ring hover:ring-[#FFD482] ${
                selected === "COMMUNITIES" ? "bg-primary" : ""
              }`}
              onClick={() => setSelected("COMMUNITIES")}
            >
              <span
                className={`text-l font-medium max-md:text-lg ${
                  selected === "COMMUNITIES" ? " text-black" : " text-white"
                }`}
              >
                Communities
              </span>
            </button>
            <button
              className={`max-md:w-[60vw] h-16 w-52 bg-borderColor rounded-xl border-0  flex flex-col items-center justify-center  hover:ring hover:ring-[#FFD482] ${
                selected === "CHAT" ? " bg-primary" : ""
              }`}
              onClick={() => setSelected("CHAT")}
            >
              <span
                className={`text-l font-medium max-md:text-lg ${
                  selected === "CHAT" ? " text-black" : " text-white"
                }`}
              >
                Chats
              </span>
            </button>
          </div>
          {selected === "CHAT" && (
            <div className="flex md:w-4/5 items-center flex-col">
              <span className="text-white text-xl text-center max-md:text-xl md:my-5">
                Your Chats
              </span>
              <div className="absolute mt-6 md:mt-20 md:w-[58vw]">
                <SearchBar
                  searchIcon
                  placeholder="Search for a Chat"
                  type="chats"
                  setResults={(v) => setChatSearchResults(v)}
                  onClick={handleSearchChats}
                />
              </div>
              <div
                className={`p-1 mt-20 max-md:h-[40vh] ${miscsStyles.beautifulScrollbarHidden}`}
              >
                {chatList && chatList.length > 0 ? (
                  chatList.map((chat: Chat, index: number) =>
                    renderChats(chat, index)
                  )
                ) : (
                  <p className="text-white mt-10 text-center">
                    No chats available.
                  </p>
                )}
              </div>
              {chatPage > 1 && (
                <div className="flex justify-center mt-2 bottom-[30px]">
                  <ThemeProvider theme={darkTheme}>
                    <Pagination
                      count={chatPage}
                      variant="outlined"
                      shape="rounded"
                      color="standard"
                      onChange={handlePageChange}
                    />
                  </ThemeProvider>
                </div>
              )}
            </div>
          )}
          {selected === "COMMUNITIES" && (
            <div className="flex md:w-4/5 items-center flex-col">
              <span className="text-white text-xl text-center max-md:text-xl md:my-5">
                Your communities
              </span>

              <button
                className="max-md:w-full "
                onClick={() => {
                  navigate("/communication/community-rules");
                }}
              >
                <div className="flex gap-2 flex-row w-full rounded-lg bg-borderColor max-md:mt-4 md:mb-4 py-5 px-5 hover:ring-1 hover:ring-primary justify-center">
                  <Plus />
                  <span className="text-primary">Create a community</span>
                </div>
              </button>
              <div className="absolute mt-[100px] md:mt-40 md:w-[58vw]">
                <SearchBar
                  searchIcon
                  placeholder="Search for a community"
                  type="communities"
                  setResults={(v) => setCommunitySearchResults(v)}
                  onClick={handleSearchCommunities}
                />
              </div>
              <div
                className={`p-1 mt-20 max-md:h-[40vh] ${miscsStyles.beautifulScrollbarHidden}`}
              >
                {communityList && communityList.length > 0 ? (
                  communityList.map((community: Chat, index: number) =>
                    renderCommunities(community, index)
                  )
                ) : (
                  <p className="text-white mt-10 text-center">
                    No communities available.
                  </p>
                )}
              </div>
              {communityPage > 1 && (
                <div className="flex justify-center mt-2 bottom-[30px]">
                  <ThemeProvider theme={darkTheme}>
                    <Pagination
                      count={communityPage}
                      variant="outlined"
                      shape="rounded"
                      color="standard"
                      onChange={handlePageChange}
                    />
                  </ThemeProvider>
                </div>
              )}
            </div>
          )}
          {modalOpen && (
            <ChatModal
              chat={selectedChat}
              closeModal={() => {
                setModalOpen(false);
                dispatch(clearChats());
              }}
              type={selected}
            />
          )}
        </div>
      </div>
      <Loader loading={loader} />
    </div>
  );
}
