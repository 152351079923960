import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "store/index";
import { toast } from "react-toastify";
import { useState } from "react";
import { checkStream } from "store/matchMaking/actions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setStreamingMethod } from "store/streaming/streamingSlice";
import { streamTypes } from "types/constants";

import ButtonDc from "components/Button";
import StreamModal from "components/StreamModal";
import LoadingAnim from "components/Loader";
import CloseIcon from "assets/close-button.svg";
interface WarningModalProps {
  visible: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({
  visible,
  onConfirm,
  onClose,
}) => {
  if (!visible) return null;
  const onPressHowToRecord = () => {
    const url = process.env.REACT_APP_TWITCH_RECORD_URL;
    window.open(url, "_blank");
  };
  return (
    <div className="fixed inset-0 bg-modalFade z-20 flex items-center justify-center">
      <div className="bg-cardBackground p-8 rounded-lg shadow-lg w-4/5 md:w-1/2">
        <div className="flex justify-between items-center mb-4">
          <span className="text-xl text-white">Enable Recording</span>
        </div>
        <p className="text-placeholder mb-6">
          Please ensure that the recording function is enabled on Twitch to save
          your stream.
        </p>
        <div className="flex flex-col items-center justify-center gap-4">
          <button
            className="p-4 bg-borderColor w-1/4 rounded-[20px]"
            onClick={onPressHowToRecord}
          >
            <span className="text-xl text-primary items-center text-center ">
              How to enable?
            </span>
          </button>
          <ButtonDc text="Confirm" action={onConfirm} type="primary" />
        </div>
      </div>
    </div>
  );
};

export default function StreamingPlatformScreen({}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const linkedAccounts = useAppSelector(
    (state: RootState) => state.linkedAccounts
  );
  const { matchId } = useParams();
  const { loader } = useAppSelector((state) => state.matchMaking);

  const [isStreamModalVisible, setIsStreamModalVisible] = useState(false);
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
  const [retry, setRetry] = useState(false);

  const handleClose = () => {
    navigate(`/duels/lobby/${matchId}`);
  };

  const handleInApp = () => {
    navigate(`/streaming/${matchId}/tool`);
  };

  const handleCancel = () => {
    navigate(`/duels/lobby/${matchId}/${false}`);
    dispatch(setStreamingMethod(streamTypes.NOT_STREAM));
  };

  const handleStartMatch = () => {
    dispatch(setStreamingMethod(streamTypes.TWITCH));
    navigate(`/duels/lobby/${matchId}/${"twitch"}`);
  };

  const handleStreamModalNo = () => {
    setIsStreamModalVisible(false);
    handleCancel();
  };

  const handleConfirmWarning = () => {
    handleStartMatch();
  };

  const handleTwitch = () => {
    if (!linkedAccounts.accounts.twitch)
      return navigate(`/settings/manage-accounts/${matchId}`);
    const callback = () => {
      toast.success("Stream started.");
      setRetry(false);
      setIsWarningModalVisible(true);
    };
    const rejectHandler = () => {
      setRetry(true);
      setIsStreamModalVisible(true);
    };
    dispatch(
      checkStream({
        payload: { matchId: matchId },
        callback,
        rejectHandler,
      })
    );
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat gap-[7em] flex flex-col items-center  h-screen ">
      <div className="w-full h-screen bg-modalFade  fixed z-10 ">
        <div
          className="w-3/5 md:h-4/5 h-screen p-[30px] bg-cardBackground md:rounded-[34px] shadow-lg absolute top-1/2 
          left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-[50px] max-md:w-full overflow-y-auto"
        >
          <div className="flex flex-col gap-[1em] mt-5">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <span className="text-2xl text-white items-center font-normal">
                Start Streaming
              </span>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={handleClose}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-evenly items-center gap-20 mt-20">
            <span className=" text-placeholder text-center text-xl font-normal">
              Would you like to stream your gameplay? Choose an option below to
              start streaming or continue without streaming.
            </span>
            <div className="flex flex-col gap-8 max-md:w-4/5">
              <ButtonDc
                text={"In App Streaming"}
                action={handleInApp}
                type="primary"
              />
              <ButtonDc
                text={
                  linkedAccounts.accounts.twitch
                    ? "Twitch Streaming"
                    : "Connect Twitch"
                }
                action={() => {
                  if (!linkedAccounts.accounts.twitch)
                    return navigate(`/settings/manage-accounts/${matchId}`);
                  setIsStreamModalVisible(true);
                }}
                type="primary"
              />
              <ButtonDc
                text={"I don't want to stream"}
                action={handleCancel}
                type="error"
              />
            </div>
          </div>
        </div>
      </div>
      <StreamModal
        visible={isStreamModalVisible}
        onPressYes={handleTwitch}
        onPressNo={handleStreamModalNo}
        onClose={() => {
          setIsStreamModalVisible(false);
          setRetry(false);
        }}
        retry={retry}
      />
      <WarningModal
        visible={isWarningModalVisible}
        onConfirm={handleConfirmWarning}
        onClose={() => setIsWarningModalVisible(false)}
      />
      <LoadingAnim loading={loader} />
    </div>
  );
}
