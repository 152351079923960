export enum MatchStatus {
  SCHEDULED = "SCHEDULED",
  WAITING = "WAITING", // 'Waiting for Players',
  WAITING_TO_START = "WAITING_TO_START", // 'Waiting for host to start',
  CONNECTING = "CONNECTING", //'Connecting',
  ON_GOING = "ON_GOING", // 'On going',
  DISPUTE = "DISPUTE", // 'Waiting for dispute resolution',
  WAITING_OTHERS_TO_PAY = "WAITING_OTHERS_TO_PAY", // 'Waiting for others to pay',
  WAITING_YOU_TO_PAY = "WAITING_YOU_TO_PAY", // 'Waiting for you to pay',
  WAITING_OTHERS_TO_VOTE = "WAITING_OTHERS_TO_VOTE", // 'Waiting for others to vote',
  WAITING_YOU_TO_VOTE = "WAITING_YOU_TO_VOTE", // 'Waiting for you to vote',
  CONTINUE_TOURNAMENT = "CONTINUE_TOURNAMENT", // 'User won and unpaid',
}


export const states = {
  [MatchStatus.SCHEDULED]: "Connecting Players",
  [MatchStatus.WAITING]: "Waiting for Players",
  [MatchStatus.WAITING_TO_START]: "Waiting for host to start",
  [MatchStatus.CONNECTING]: "Connecting",
  [MatchStatus.ON_GOING]: "Match on going",
  [MatchStatus.DISPUTE]: "Dispute ongoing",
  [MatchStatus.WAITING_OTHERS_TO_PAY]: "Waiting for others to pay",
  [MatchStatus.WAITING_YOU_TO_PAY]: "Waiting for you to pay",
  [MatchStatus.WAITING_OTHERS_TO_VOTE]: "Waiting for others to vote",
  [MatchStatus.WAITING_YOU_TO_VOTE]: "Waiting for you to vote",
};

export const gameTypes = {
  ONE_VS_ONE: "ONE_VS_ONE",
  TWO_VS_TWO: "TWO_VS_TWO",
  THREE_VS_THREE: "THREE_VS_THREE",
  FOUR_VS_FOUR: "FOUR_VS_FOUR",
  FIVE_VS_FIVE: "FIVE_VS_FIVE",
  FFA: "FFA",
};

export const matchTypeNames = {
  [gameTypes.ONE_VS_ONE]: "1 vs 1",
  [gameTypes.TWO_VS_TWO]: "2 vs 2",
  [gameTypes.THREE_VS_THREE]: "3 vs 3",
  [gameTypes.FOUR_VS_FOUR]: "4 vs 4",
  [gameTypes.FIVE_VS_FIVE]: "5 vs 5",
  [gameTypes.FFA]: "Free for All",
};

export type gameTypeTypes = keyof typeof gameTypes;
