import { useEffect, useState } from "react";
import { ReactComponent as Lock } from "../../assets/lock_yellow.svg";

import LoadingAnim from "components/Loader";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../store";
import { saveResetEmail } from "store/auth/authSlice";
import { sendOtp } from "store/auth/actions";
import { emailRegex } from "constant/validations";
import { toast } from "react-toastify";

export default function ForgotPasswordScreen({}: any) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: any) => state.auth);
  const [email, setEmail] = useState("");

  const validateEmail = (email: string) => {
    return emailRegex.test(email);
  };

  const handleSendOTP = async () => {
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    dispatch(saveResetEmail(email));
    dispatch(
      sendOtp({
        payload: { userEmail: email },
        callback: () => navigate("/verify-otp/forgot-password"),
      })
    );
  };

  return (
    <div className="bg-background min-h-screen h-auto bg-[url('/src/assets/Backdrop-log.png')] bg-no-repeat bg-fit flex items-center justify-center tab:justify-end">
      <div className="h-auto w-auto  border-[1px] rounded-3xl border-primaryYellow tab:mr-28 ">
        <div className="flex flex-col items-center justify-center w-full gap-[15px] p-10">
          <h1 className="text-[32px] font-bold text-primaryYellow">
            Forgot your Password?
          </h1>
          <Lock />
          <div className="flex flex-row items-center justify-center gap-5 max-w-[505px] min-w-[280px] w-[35vw] my-5">
            <p className="text-[#d0d0d0] text-[12px] text-center">
              Please enter your e-mail address to reset your password
            </p>
          </div>
          <div className="flex flex-col max-w-[505px] min-w-[280px] w-[35vw] items-center">
            <div className="relative w-full mt-4">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="floating_filled"
                className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-white outline-none bg-inputGray border-0 border-b-2 border-inputGray appearance-none focus:outline-none focus:ring-0 focus:border-primaryYellow peer"
                placeholder=" "
              />
              <label
                htmlFor="floating_filled"
                className="absolute text-sm text-white duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primaryYellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
              >
                Email
              </label>
            </div>
          </div>

          <div className="flex flex-col items-center max-w-[505px] min-w-[280px] w-[35vw]  gap-5 mt-[20px]">
            <button
              className="items-center text-black justify-center text-center flex flex-row gap-5 font-bold bg-gradient-to-b from-primaryYellow to-secondaryYellow hover:from-secondaryYellow hover:to-primaryYellow ... w-full h-[51.6px] mx-6 rounded-xl"
              onClick={handleSendOTP}
            >
              Send OTP
            </button>
          </div>
        </div>
      </div>
      <LoadingAnim loading={user.loader} />
    </div>
  );
}
