import {
  createMainTournamentApi,
  createTournamentMatchApi,
  deleteTournamentApi,
  endTournamentApi,
  getEndedTournamentsApi,
  getTournamentMatchesApi,
  goToNextStageApi,
  goToYourMatchApi,
  inviteToTournamentApi,
  joinTournamentApi,
  startTournamentApi,
  updateTournamentDateApi,
} from "api/tournament.api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

interface WithCallback {
  payload: any;
  callback?: (data: any) => void;
  err?: () => void;
}

export const createMainTournament = createAsyncThunk<any, WithCallback>(
  "/tournament/createMainTournament",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await createMainTournamentApi(payload.tournamentData);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const inviteToTournament = createAsyncThunk<any, WithCallback>(
  "/tournament/inviteToTournament",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await inviteToTournamentApi(payload);
      callback?.(data);
    
      return data;
    } catch (error: any) {
      toast.error(error.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTournament = createAsyncThunk<any, WithCallback>(
  "/tournament/deleteTournament",
  async ({ payload, callback, err }, { rejectWithValue }) => {
    try {
      const data: any = await deleteTournamentApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      err?.();
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createTournamentMatch = createAsyncThunk<any, WithCallback>(
  "/tournament/createTournamentMatch",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await createTournamentMatchApi(payload.data);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateTournamentDate = createAsyncThunk<any, WithCallback>(
  "/tournament/updateTournamentDate",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await updateTournamentDateApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const startTournament = createAsyncThunk<any, WithCallback>(
  "/tournament/startTournament",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await startTournamentApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const goToTournamentMatch = createAsyncThunk<any, WithCallback>(
  "/tournament/goToTournamentMatch",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await goToYourMatchApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const goToNextStage = createAsyncThunk<any, WithCallback>(
  "/tournament/goToNextStage",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await goToNextStageApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const endTournament = createAsyncThunk<any, WithCallback>(
  "/tournament/endTournament",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await endTournamentApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getTournamentMatches = createAsyncThunk<any, WithCallback>(
  "/tournament/getTournamentMatches",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await getTournamentMatchesApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const joinMainTournament = createAsyncThunk<any, WithCallback>(
  "/tournament/joinMainTournament",
  async ({ payload, callback, err }, { rejectWithValue }) => {
    try {
      const data: any = await joinTournamentApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      err?.();
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getEndedTournaments = createAsyncThunk<any, WithCallback>(
  "/tournament/getEndedTournaments",
  async ({ payload, callback, err }, { rejectWithValue }) => {
    try {
      const data: any = await getEndedTournamentsApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      err?.();
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
