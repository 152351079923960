import { createSlice } from "@reduxjs/toolkit";
import { getDisputes, getWinners } from "./action";
import { signOut } from "store/auth/actions";
import { getUser } from "store/user/action";

interface WinnersSlice {
  users: any[];
  disputes: any[];
  loader: boolean;
  err: boolean;
}

const initialState: WinnersSlice = {
  users: [],
  disputes: [],
  loader: false,
  err: false,
};

export const winnersSlice = createSlice({
  name: "leaders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWinners.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getWinners.fulfilled, (state, action) => {
      state.users = action.payload.winners;
      state.loader = false;
    });
    builder.addCase(getWinners.rejected, (state) => {
      state.err = true;
      state.loader = false;
    });
     builder.addCase(getDisputes.pending, (state) => {
       state.loader = true;
     });
    builder.addCase(getDisputes.fulfilled, (state, action) => {
      state.disputes = action.payload.disputes;
      state.loader = false;
    });
      builder.addCase(getDisputes.rejected, (state) => {
        state.err = true;
        state.loader = false;
      });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
    builder.addCase(getUser.rejected, (state) => {
      return initialState;
    });
  },
});

export default winnersSlice.reducer;
