import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "assets/close-button.svg";
import { ReactComponent as Arrow } from "assets/arrow-right.svg";
import { ReactComponent as Bell } from "assets/community/settings/Bell.svg";
import { ReactComponent as Rules } from "assets/community/settings/Rules.svg";
import { ReactComponent as Members } from "assets/community/settings/Users_Group.svg";
import { ReactComponent as Invite } from "assets/community/settings/User_Add.svg";
import { ReactComponent as Leave } from "assets/community/settings/Leave.svg";

import ImageHolder from "./ImagePlaceholder";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";

import Loader from "./Loader";
import Switch from "./Switch";
import {
  deleteCommunity,
  getCommunityMembers,
  leaveCommunity,
} from "store/community/action";
import {
  removeDeletedOrLeftCommunity,
  setCommunitiesName,
  setCommunityId,
} from "store/community/communitySlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CommunityRulesModal from "screens/Communities/AddTitle/rulesModal";

interface Member {
  _id?: string;
  profilePicture: string;
  username: string;
}

interface MembersModalProps {
  visible: boolean;
  data: Member[];
  onClose: () => void;
}

const MembersModal: React.FC<MembersModalProps> = ({
  visible,
  onClose,
  data,
}) => {
  if (!visible) return null;

  const renderMembers = (member: Member, index: number) => {
    return (
      <div key={index} className="flex items-center gap-4 mb-4">
        <ImageHolder
          uri={member.profilePicture}
          className="w-12 h-12 rounded-full"
        />
        <span className="text-white">{member.username}</span>
      </div>
    );
  };

  return (
    <div className="fixed flex-col inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className=" md:w-1/3 h-1/3 w-[90vw]  bg-borderColor rounded-2xl justify-center items-center flex flex-col text-slate-100 shadow-xl ">
        <span className="text-xl font-bold text-white mb-4">
          Community Members
        </span>
        <div className="p-6  rounded-lg flex flex-col items-center overflow-y-auto">
          <div className="overflow-y-auto max-h-96">
            {data.map(renderMembers)}
          </div>
          <button
            className="mt-4 px-4 py-2 bg-primary text-black rounded hover:bg-primaryActive focus:outline-none"
            onClick={onClose}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

interface CommunitySettingsProps {
  type?: String;
  chat?: any;
  closeSettingsModal: () => void;
  closeBothModals: () => void;
  isMatch?: boolean;
}

const CommunitySettings: React.FC<CommunitySettingsProps> = ({
  closeSettingsModal,
  closeBothModals,
  chat,
  type,
  isMatch,
}) => {
  const dispatch: AppDispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  const currentUser = user?.user;
  const navigate = useNavigate();
  const chatId = chat._id;
  const [modalOpen, setModalOpen] = useState(false);
  const [visibleMembers, setVisibleMembers] = useState(false);
  const communityMembers = useAppSelector(
    (state: RootState) => state.community.communityParticipants
  );
  const loader = useAppSelector((state: RootState) => state.messaging.loader);

  const SettingOption = ({
    Icon,
    label,
    onClick,
    disabled,
  }: {
    Icon: React.FC;
    label: string;
    onClick?: () => void;
    disabled?: boolean;
  }) => {
    const isDisabled = disabled
      ? "bg-Darker_shade  text-primary"
      : "bg-cardBackground  hover:bg-darkerGray";
    return (
      <button
        className={`flex flex-row ${isDisabled} md:w-1/3 h-16 justify-between rounded-xl items-center p-4`}
        onClick={onClick}
        disabled={disabled}
      >
        <div className="flex flex-row gap-4">
          <Icon />
          <span className={`text-white ${isDisabled}`}>{label}</span>
        </div>
        <Arrow />
      </button>
    );
  };
  const deleteCommunityClick = async () => {
    if (chat?.owner === currentUser?._id) {
      dispatch(deleteCommunity({ query: `/${chatId}` }));
    } else {
      dispatch(leaveCommunity({ query: `/${chatId}` }));
    }

    dispatch(removeDeletedOrLeftCommunity(chatId));
    setTimeout(() => {
      toast(
        chat?.owner === user?.user?._id
          ? "Successfully deleted"
          : "Successfully left"
      );
      closeBothModals();
    }, 2000);
  };
  useEffect(() => {
    dispatch(getCommunityMembers({ query: `/${chatId}` }));
  }, [dispatch]);
  return (
    <div
      className="relative z-10"
      aria-labelledby="crop-image-dialog"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 transition-all backdrop-blur-sm">
        <div className="fixed inset-0 z-10 w-full overflow-y-auto">
          <div className="flex md:min-h-full justify-center px-2 py-12 text-center ">
            <div className="relative w-[95%] sm:w-[80%] min-h-[60vh] rounded-2xl bg-borderColor bg-opacity-95 text-slate-100 text-left shadow-xl transition-all">
              <div className="px-10 py-4">
                <button
                  type="button"
                  className="rounded-md p-1 inline-flex items-center justify-center focus:outline-none absolute top-8 right-8"
                  onClick={closeSettingsModal}
                >
                  <span className="sr-only">Close menu</span>
                  <CloseIcon />
                </button>

                <div className="flex flex-row items-center mt-4">
                  <div className="flex flex-col w-[50vw]">
                    <div className="flex flex-row w-full justify-between">
                      <span className="text-white text-3xl max-md:text-xl">
                        {chat.name} Community Settings
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col bg-Darker_shade rounded-xl p-4 items-center justify-center gap-4 md:my-10 my-2">
                  <span className="text-white text-xl font- max-md:text-lg">
                    {chat?.title}
                  </span>
                  <ImageHolder
                    className="flex md:w-[30vw] md:h-[20vw] max-md:w-[100%] max-md:h-[100%] rounded-xl p-1"
                    uri={chat.coverImage}
                  />
                  <span className="text-white text-lg max-md:text-sm">
                    {chat?.description}
                  </span>
                </div>
                <div className="flex flex-col gap-4 md:my-10 ">
                  <span className="text-white text-xl font-semibold max-md:text-xl">
                    Settings Options
                  </span>
                  <div className="flex flex-col gap-5 ">
                    <div className="flex flex-row max-md:flex-col w-full justify-center md:gap-20 gap-4">
                      {/* <div className="flex flex-row bg-cardBackground md:w-1/3 h-16 justify-between rounded-xl items-center p-4">
                        <div className="flex flex-row gap-4">
                          <Bell />
                          <span className="text-white">Notification</span>
                        </div>
                        <Switch />
                      </div> */}
                      <SettingOption
                        Icon={Rules}
                        label="Community Rules"
                        onClick={() => {
                          setModalOpen(true);
                        }}
                      />
                      <SettingOption
                        Icon={Members}
                        label="View Members"
                        onClick={() => setVisibleMembers(true)}
                      />
                    </div>
                    <div className="flex flex-row max-md:flex-col w-full justify-center md:gap-20 gap-4">
                      <SettingOption
                        Icon={Invite}
                        label="Invite Duelists"
                        onClick={() => {
                          dispatch(setCommunitiesName(chat.name));
                          dispatch(setCommunityId(chatId));
                          navigate(
                            "/communication/invite-to-community/invite-duelists"
                          );
                        }}
                      />
                      <SettingOption
                        Icon={Leave}
                        label={
                          chat?.owner === currentUser?._id
                            ? "Leave & Delete Community "
                            : "Leave Community"
                        }
                        onClick={deleteCommunityClick}
                        disabled={isMatch}
                      />
                    </div>
                  </div>
                  {modalOpen && (
                    <CommunityRulesModal
                      visible={modalOpen}
                      onClose={() => setModalOpen(false)}
                    />
                  )}
                  <MembersModal
                    visible={visibleMembers}
                    onClose={() => setVisibleMembers(false)}
                    data={communityMembers}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loader loading={loader} />
      </div>
    </div>
  );
};

export default CommunitySettings;
