import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getGames } from "store/matchMaking/actions";
import { Pagination, ThemeProvider, createTheme } from "@mui/material";
import {
  setConsole,
  setMatchFromHome,
  setSelectedGame,
} from "store/matchMaking/matchMakingSlice";
import ConsolModal from "components/Consoles";
import LoadingAnim from "components/Loader";
import SearchBar from "components/Search";
import CloseIcon from "assets/close-button.svg";
import Next from "assets/next-arrow.svg";
import NextDisabled from "assets/next-arrow-disabled.svg";
import MatchCard from "components/MatchCard";
import {
  setSelectedTournamentGame,
  setTournamentConsole,
} from "store/tournament/tournamentSlice";

const CardModal = () => {
  const [selected, setSelected] = useState<any | null>(null);
  const [visible, setVisible] = useState(false);
  const [results, setResults] = useState<any[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const matchMaking = useAppSelector((state) => state.matchMaking);
  const duelpath = useAppSelector(
    (state) => state.matchMaking
  ).selectedDuelPath;
  const games = matchMaking.gamesList;
  const isNextDisabled = !selected;
  const page = matchMaking.page;

  const handleClose = () => {
    navigate("/duels");
  };

  const handleClick = (matchItem: any) => {
    setSelected(matchItem);
  };

  const handleNext = () => {
    setVisible(true);
  };
  const handleSearch = (matchItem: any) => {
    handleClick(matchItem);
    setVisible(true);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(getGames(value));
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(() => {
    dispatch(getGames(1));
  }, [dispatch]);

  useEffect(() => {
    if (duelpath === "CREATE_TOURNAMENT") {
      dispatch(setSelectedTournamentGame(selected));
    } else {
      dispatch(setSelectedGame(selected));
    }
  }, [dispatch, selected]);

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex   h-screen  p-2">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:pt-5 items-center ">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]   max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white items-center font-normal">
              Select a game
            </span>
            <button className=" w-[25px] h-[25px]" onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>

          <SearchBar
            searchIcon
            placeholder="Search games"
            type="games"
            setResults={(v) => setResults(v)}
            onClick={handleSearch}
          />
          <div className="w-full mt-5 flex gap-x-[40px] gap-y-[20px] flex-wrap overflow-y-auto justify-center p-1 max-md:h-2/3">
            {games.map((item, index) => (
              <MatchCard
                key={index}
                id={`${index}`}
                thumbnail={item.image}
                item={item}
                name={item.name}
                selected={selected?.id === `${index}`}
                onClick={handleClick}
              />
            ))}
          </div>
          <div className="flex justify-center absolute bottom-[30px] left-0 right-0">
            <ThemeProvider theme={darkTheme}>
              <Pagination
                count={page}
                variant="outlined"
                shape="rounded"
                color="standard"
                onChange={handlePageChange}
              />
            </ThemeProvider>
          </div>
          <button
            className="absolute right-0 bottom-0 mr-[30px] mb-[30px] border-0  border-none outline-none max-md:bottom-12"
            onClick={handleNext}
            disabled={isNextDisabled}
          >
            <img
              src={isNextDisabled ? NextDisabled : Next}
              width={100}
              height={100}
              alt="logo"
            />
          </button>
        </div>
      </div>
      <ConsolModal
        visible={visible}
        onPress={(item: any) => {
          if (duelpath == "CREATE_TOURNAMENT") {
            dispatch(setTournamentConsole(item));
          } else {
            dispatch(setConsole(item));
          }
          dispatch(setMatchFromHome(false));
          setVisible(false);
          navigate("/duels/select-platform");
        }}
        data={selected?.consoles}
        onClose={() => setVisible(false)}
      />
      <LoadingAnim loading={matchMaking.loader} />
    </div>
  );
};

export default CardModal;
