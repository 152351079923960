import { createSlice } from "@reduxjs/toolkit";
import {
  addCommunity,
  editCommunity,
  getCommunityList,
  getCommunityMembers,
  getPrivateChatList,
  inviteCommunity,
} from "./action";
import { getCommunityDuelists, getCommunityFriends } from "store/user/action";
import { signOut } from "store/auth/actions";

interface Community {
  friends: any[];
  duelists: any[];
  communities: any[];
  chatList: any[];
  voiceChatList: any[];
  communityParticipants: any[];
  communityFriends: any[];
  newChats: any[];
  notification: any[];
  invitedUsers: any[];
  communityFriendsPage: number;
  page: number;
  chatPage: number;
  friendsPage: number;
  duelistPage: number;
  chatListPage: number;
  coverImage: string;
  communitiesName: string;
  communitiesType: string;
  adult: boolean;
  communitiesTitle: string;
  description: string;
  communityId: string;
  communityState: string;
  loader: boolean;
  error: {} | null;
  ongoingCall: string | null;
  loudSpeaker: boolean;
  editCommunityId: string;
  microphone: boolean;
  firstTime: boolean;
  invitedCommunityMembers: any[];
  selectedChatId: number;
}

const initialState: Community = {
  friends: [],
  duelists: [],
  communities: [],
  chatList: [],
  voiceChatList: [],
  communityParticipants: [],
  communityFriends: [],
  newChats: [],
  notification: [],
  invitedUsers: [],
  communityFriendsPage: 1,
  page: 1,
  chatPage: 1,
  friendsPage: 1,
  duelistPage: 1,
  chatListPage: 1,
  coverImage: "",
  communitiesName: "",
  communitiesType: "",
  adult: false,
  communitiesTitle: "",
  description: "",
  communityId: "",
  communityState: "",
  loader: false,
  error: null,
  ongoingCall: null,
  loudSpeaker: false,
  editCommunityId: "",
  microphone: true,
  firstTime: true,
  invitedCommunityMembers: [],
  selectedChatId: 0,
};

export const communitySlice = createSlice({
  name: "community",
  initialState,
  reducers: {
    setCoverImage: (state, action) => {
      state.coverImage = action?.payload;
    },
    setCommunitiesName: (state, action) => {
      state.communitiesName = action?.payload;
    },
    setCommunitiesType: (state, action) => {
      state.communitiesType = action?.payload;
    },
    setAdult: (state, action) => {
      state.adult = action?.payload;
    },
    setCommunitiesTitle: (state, action) => {
      state.communitiesTitle = action?.payload;
    },
    setCommunitiesDescription: (state, action) => {
      state.description = action?.payload;
    },
    resetPage: (state) => {
      state.page = 1;
      state.friendsPage = 1;
      state.duelistPage = 1;
      state.chatListPage = 1;
      state.friends = [];
      state.duelists = [];
      state.communities = [];
      state.communitiesTitle = "";
      state.description = "";
      state.communitiesName = "";
    },
    addNewChatToList: (state, action) => {
      const chatId = action?.payload._id;
      const chatExists = state.chatList.some((chat) => chat._id === chatId);
      if (!chatExists) {
        state.chatList.unshift(action?.payload);
      }
    },
    updateLastMessage: (state, action) => {
      const { chatId, newMessage } = action.payload;
      const chat = state.chatList.find((chat) => chat._id === chatId);
      if (chat) {
        chat.latest.time = newMessage.time;
        chat.latest.message = newMessage.message;
      }
    },
    removePersonalChat: (state, action) => {
      const chatId = action.payload;
      const index = state.chatList.findIndex((chat) => chat._id === chatId);
      if (index !== -1) {
        state.chatList.splice(index, 1);
      }
    },
    removeDeletedOrLeftCommunity: (state, action) => {
      for (let i = 0; i < state.communities.length; i++) {
        console.log(state.communities[i]._id === action?.payload);
        if (state.communities[i]._id === action?.payload) {
          state.communities.splice(i, 1);
          break;
        }
      }
    },
    setEditComID: (state, action) => {
      state.editCommunityId = action?.payload;
    },
    setCommunityState: (state, action) => {
      state.communityState = action?.payload;
    },
    setCommunityId: (state, action) => {
      state.communityId = action.payload;
    },
    setInvitedCommunityMembers: (state, action) => {
      state.invitedCommunityMembers = [
        ...state.invitedCommunityMembers,
        action.payload,
      ];
    },
    resetInvitedCommunityMembers: (state) => {
      state.invitedCommunityMembers = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPrivateChatList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getPrivateChatList.fulfilled, (state, action) => {
      state.chatList = action.payload?.chats;
      state.error = null;
      state.loader = false;
      if (action.payload?.totalPages > state.chatPage) {
        state.chatPage += 1;
      }
    });
    builder.addCase(getPrivateChatList.rejected, (state) => {
      state.error = "Failed to fetch value";
      state.loader = false;
    });

    builder.addCase(getCommunityList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCommunityList.fulfilled, (state, action) => {
      state.communities = action.payload?.modifiedCommunities;
      state.error = null;
      state.loader = false;

      const isElementExists = (element: { _id: any }, communities: any[]) => {
        return communities.some((e) => e._id === element._id);
      };
      action?.payload.modifiedCommunities.forEach(
        (modifiedCommunity: { _id: any }) => {
          if (!isElementExists(modifiedCommunity, state.communities)) {
            state.communities.unshift(modifiedCommunity);
          }
        }
      );
      if (action.payload?.totalPages > state.page) {
        state.page += 1;
      }
    });
    builder.addCase(getCommunityList.rejected, (state) => {
      state.error = "Failed to fetch value";
      state.loader = false;
    });

    builder.addCase(addCommunity.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(addCommunity.fulfilled, (state, action) => {
      state.communityId = action?.payload.communityId;
      state.error = null;
      state.loader = false;
    });
    builder.addCase(addCommunity.rejected, (state) => {
      state.error = "Failed to fetch value";
      state.loader = false;
    });

    builder.addCase(getCommunityMembers.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCommunityMembers.fulfilled, (state, action) => {
      state.communityParticipants = action?.payload.members;
      state.loader = false;
    });
    builder.addCase(getCommunityMembers.rejected, (state) => {
      state.error = "Failed to fetch value";
      state.loader = false;
    });
    builder.addCase(editCommunity.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(editCommunity.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(editCommunity.rejected, (state) => {
      state.error = "Failed to fetch value";
      state.loader = false;
    });

    builder.addCase(inviteCommunity.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(inviteCommunity.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(inviteCommunity.rejected, (state) => {
      state.error = "Failed to fetch value";
      state.loader = false;
    });

    builder.addCase(getCommunityFriends.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCommunityFriends.fulfilled, (state, action) => {
      state.friends = action.payload?.friendList;
      state.error = null;
      state.loader = false;
    });
    builder.addCase(getCommunityFriends.rejected, (state) => {
      state.error = "Failed to fetch value";
      state.loader = false;
    });
    builder.addCase(getCommunityDuelists.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCommunityDuelists.fulfilled, (state, action) => {
      state.duelists = action.payload?.userList;
      state.error = null;
      state.loader = false;
    });
    builder.addCase(getCommunityDuelists.rejected, (state) => {
      state.error = "Failed to fetch value";
      state.loader = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
  },
});

export default communitySlice.reducer;
export const {
  setCoverImage,
  setCommunitiesName,
  setCommunitiesType,
  setAdult,
  setCommunitiesTitle,
  setCommunitiesDescription,
  resetPage,
  addNewChatToList,
  updateLastMessage,
  removePersonalChat,
  removeDeletedOrLeftCommunity,
  setCommunityState,
  setEditComID,
  setCommunityId,
  setInvitedCommunityMembers,
  resetInvitedCommunityMembers,
} = communitySlice.actions;
