import React, { useState } from "react";
import Next from "../../../assets/next-arrow.svg";
import CloseIcon from "assets/close-button.svg";
import NextDisabled from "assets/next-arrow-disabled.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import {
  setSelectedGameTeam,
  setSelectedGameType,
} from "store/matchMaking/matchMakingSlice";
import {
  setSelectedTournamentGameTeam,
  setSelectedTournamentGameType,
} from "store/tournament/tournamentSlice";

export default function SelectGameTypeScreen({}) {
  const navigate = useNavigate();
  let { from } = useParams();
  const [selectedType, setSelectedType] = useState<any>();
  const [selectedTeam, setSelectedTeam] = useState("");
  const dispatch: AppDispatch = useAppDispatch();
  const isNextDisabled = !selectedTeam;
  const matchMaking = useAppSelector((state: RootState) => state.matchMaking);
  const tournaments = useAppSelector((state: RootState) => state.tournament);
  const duelPath = matchMaking.selectedDuelPath;
  const gameTypes =
    duelPath == "CREATE_TOURNAMENT"
      ? tournaments.selectedGame?.gameTypes
      : matchMaking.selectedGame?.gameTypes;
  const isMobile = window.innerWidth <= 768;
  const onCancel = () => {
    navigate("/duels");
  };
  
  const handleNext = () => {
    if (duelPath == "CREATE_TOURNAMENT") {
      dispatch(setSelectedTournamentGameTeam(selectedTeam));
      dispatch(setSelectedTournamentGameType(selectedType));
    } else {
      dispatch(setSelectedGameTeam(selectedTeam));
      dispatch(setSelectedGameType(selectedType));
    }
    if (from) return navigate("/duels/duel-overview");
    navigate("/duels/how-many-rounds");
  };
  const renderTypes = (item: any, index: number) => {
    const selected = item === selectedType;
    return (
      <button
        className={`flex h-16 w-52 rounded-lg items-center justify-center gap-[14.33px] bg-borderColor  ${
          selected ? "border-4 border-[#FFD482]" : ""
        }`}
        key={index}
        onClick={() => setSelectedType(item)}
      >
        <span className="text-center text-white text-xl max-md:text-sm max-md:w-[55px]">
          {item.text}
        </span>
      </button>
    );
  };

  const renderTeams = (item: any, index: number) => {
    const selected = item === selectedTeam;
    return (
      <button
        className={`flex w-[277px] h-[130px] p-[1.5em] items-center justify-center gap-[14.33px] rounded-[17.2px] bg-borderColor ${
          selected ? "border-4 border-[#FFD482]" : ""
        }`}
        key={index}
        onClick={() => setSelectedTeam(item)}
      >
        <span className="text-center text-white text-2xl max-md:text-xl">
          {item.name}
        </span>
      </button>
    );
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat gap-[7em] flex flex-col items-center   h-screen ">
      <div className="container flex flex-col gap-y-[20px] items-center md:my-16 max-md:p-2">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex md:items-center justify-between  max-md:flex-col ">
              <span className="text-3xl text-white items-center font-normal max-md:mt-10">
                Select a game type
              </span>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <span className=" text-subText text-xl font-normal">
              Here you choose all the games types your looking...
            </span>
          </div>
          <div className="flex flex-row items-center justify-center gap-[3em] my-8 max-md:overflow-x-auto max-md:gap-2">
            {gameTypes?.map(renderTypes)}
          </div>
          <div className="w-full flex gap-x-[40px] gap-y-[20px] flex-wrap overflow-y-auto justify-center p-1 max-md:h-[500px]">
            {selectedType?.teams.map(renderTeams)}
          </div>
          {!isMobile && (
            <button
              className="absolute right-0 bottom-0 mr-[30px] mb-[30px] border-0  border-none outline-none"
              onClick={handleNext}
              disabled={isNextDisabled}
            >
              <img
                src={isNextDisabled ? NextDisabled : Next}
                width={100}
                height={100}
                alt="logo"
              />
            </button>
          )}
        </div>
        {isMobile && (
          <button
            className="absolute right-0 bottom-0 mr-[30px] mb-[30px] border-0  border-none outline-none"
            onClick={handleNext}
            disabled={isNextDisabled}
          >
            <img
              src={isNextDisabled ? NextDisabled : Next}
              width={100}
              height={100}
              alt="logo"
            />
          </button>
        )}
      </div>
    </div>
  );
}
