import React, { useEffect, useState } from "react";
import { matchItemTypes } from "types/components";
import CloseIcon from "assets/close-button.svg";
import NextIcon from "assets/next-arrow.svg";
import MatchCard from "components/MatchCard";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getPresets } from "store/preset/actions";
import ImageHolder from "components/ImagePlaceholder";
import {
  setConsole,
  setDuelPath,
  setFromPresets,
  setJoiningMatchId,
  setMatchCubes,
  setMatchFromHome,
  setSelectedGame,
  setSelectedGameType,
  setSelectedRound,
} from "store/matchMaking/matchMakingSlice";
import LoadingAnim from "components/Loader";
import { setScreenStatus } from "store/currentMatch/currentMatchSlice";
import ConsolModal from "components/Consoles";
import {
  setSelectedTournamentGame,
  setSelectedTournamentGameType,
  setSelectedTournamentRound,
  setTournamentConsole,
  setTournamentCubes,
} from "store/tournament/tournamentSlice";

const PresetScreen = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [type, setType] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const presets = useAppSelector((state) => state.presets);

  useEffect(() => {
    dispatch(getPresets());
  }, [dispatch]);

  const handleClose = () => {
    navigate("/");
  };

  const handleTournamentClick = (item: any) => {
    dispatch(setScreenStatus("CREATE_TOURNAMENT"));
    dispatch(setDuelPath("CREATE_TOURNAMENT"));
    dispatch(setSelectedTournamentGame(item.game));
    dispatch(setSelectedTournamentGameType({ text: item.gameType }));
    dispatch(setSelectedTournamentRound({ name: item.rounds }));
    dispatch(setTournamentCubes(item.cubeWager));
    setData(item.game.consoles);
    setType("TOURNAMENT");
    setVisible(true);
  };

  const handleClick = (item: any) => {
    const isTournament = item.matchType === "TOURNAMENT";
    if (isTournament) return handleTournamentClick(item);
    dispatch(setJoiningMatchId(item._id));
    dispatch(setSelectedGame(item.game));
    setData(item.game.consoles);
    dispatch(
      setSelectedGameType({
        _id: "",
        type: item.gameType,
        text: item.gameType,
        teams: [],
        image: item.coverImage,
      })
    );
    dispatch(setSelectedRound({ _id: "", name: item.rounds }));
    dispatch(setMatchCubes(String(item.cubeWager)));
    dispatch(setFromPresets(true));
    dispatch(setMatchFromHome(true));
    dispatch(setScreenStatus("JOIN_PRESET"));
    dispatch(setDuelPath("JOIN_PRESET"));
    setVisible(true);
  };

  const renderEvents = (item: any, index: number) => {
    return (
      <button
        className="flex flex-col p-2 m1  items-center w-[250px] h-[210px] bg-modalFade rounded-lg m-5"
        key={index}
        onClick={() => handleClick(item)}
      >
        <span className="text  text-l ">{item.presetName} </span>
        <ImageHolder uri={item.coverImage} width={200} height={200} />
        <div className=" flex flex-row justify-start items-center">
          <span className="text text-[12px] ">{item.matchType}</span>
        </div>
        <div className=" flex flex-row justify-start items-center gap-5">
          <span className="text text-[12px] ">{item.rounds}</span>
          <span className="text  text-[12px] ">{item.gameType}</span>
        </div>
      </button>
    );
  };

  return (
    <div className="h-screen w-full  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex overflow-x-hidden">
      <div className=" flex flex-col gap-y-[10px]  md:my-8 w-full max-md:p-5">
        <div className="w-full  flex items-center md:ml-5">
          <span className="text-2xl text-white items-center font-normal ">
            Select from your presets
          </span>
          <button
            className=" w-[25px] h-[25px] absolute right-10"
            onClick={handleClose}
          >
            <img src={CloseIcon} alt="Close" />
          </button>
        </div>
        <div className="overflow-x-auto flex flex-wrap justify-center">
          {presets.presets.map(renderEvents)}
        </div>
      </div>
      <LoadingAnim loading={presets.loader} />
      <ConsolModal
        visible={visible}
        onPress={(item: any) => {
          if (type === "TOURNAMENT") {
            dispatch(setTournamentConsole(item));
          } else {
            dispatch(setConsole(item));
          }
          setVisible(false);
          navigate("/duels/duel-overview");
        }}
        data={data}
        onClose={() => setVisible(false)}
      />
    </div>
  );
};

export default PresetScreen;
