import { messaging } from "firebaseConfig/firebase";
import { onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setMessage } from "store/fcm/fcmSlice";

const FCMStore = () => {
  const dispatch = useDispatch();

  onMessage(messaging, (payload) => {
    dispatch(setMessage(payload));
    switch (payload?.data?.type) {
      case "DO NOT SHOW":
        break;

      case "PRIVATE_MESSAGE":
      case "COMMUNITY_MESSAGE":
        return toast(`You have a new message from ${payload?.data?.sender}`);

      case "MATCH_MESSAGE":
        return;

      default:
        toast(payload?.data?.message);
    }
  });
  return <></>;
};

export default FCMStore;
