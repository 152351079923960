import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "assets/close-button.svg";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";

import LoadingAnim from "components/Loader";
import { ReactComponent as Ea } from "assets/ea-circled.svg";
import { ReactComponent as Xbox } from "assets/xbox-circled.svg";
import { ReactComponent as Paypal } from "assets/paypal-circled.svg";
import { ReactComponent as Twitch } from "assets/twitch-circled.svg";
import { ReactComponent as Dots } from "assets/three_dots_vertical.svg";
import { ReactComponent as Activision } from "assets/activision-circled.svg";
import { ReactComponent as PlayStation } from "assets/playstation-circled.svg";
import { ReactComponent as Epic } from "assets/epic-circled.svg";
import { ReactComponent as Sf } from "assets/sf-logo.svg";
import { ReactComponent as Mlb } from "assets/platForms/mlb.svg";
import { ReactComponent as People } from "assets/people.svg";
import { getLinkedAccounts } from "store/linkedAccounts/action";
import PlatFormsModal from "components/PlatForms";
import {
  ACTIVITION,
  EA,
  EPIC,
  MLB,
  PLAYSTATION,
  STREETFIGHTER,
  XBOX,
} from "constant/platForms";
import {
  updateActivitionID,
  updateEAID,
  updateEpicID,
  updateMLBID,
  updatePsID,
  updateStreetFighterID,
  updateTwitchID,
  updateXboxID,
} from "store/user/action";
import { toast } from "react-toastify";
import LinkConsoleModal from "components/LinkConsoles";
import { getTwitchUserApi } from "api/settings.api";

const ManageAccountsScreen = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const { matchId } = useParams();

  const handleClose = () => {
    if (matchId) return navigate(`/streaming/${matchId}/platform`);
    navigate("/settings");
  };

  const [visible, setVisible] = useState(false);
  const [visibleConsoles, setVisibleConsoles] = useState(false);
  const [data, setData] = useState<any>([]);

  const twitchUrl = process.env.REACT_APP_TWITCH_URL;

  const linkedAccounts = useAppSelector(
    (state: RootState) => state.linkedAccounts
  );
  useEffect(() => {
    const token = window.location?.href
      ?.split("#access_token=")?.[1]
      ?.split("&scope=")?.[0];
    if (token) saveTwitchUser(token);
    if (!token) {
      dispatch(getLinkedAccounts());
    } else {
      saveTwitchUser(token);
    }
  }, [window.location?.href]);

  const onPress = (id?: any) => {
    if (!id) return toast.error("Please enter valid details");

    switch (data) {
      case ACTIVITION:
        return dispatch(
          updateActivitionID({
            payload: { activisionId: id },
            callback: () => {
              setVisible(false);
              dispatch(getLinkedAccounts());
            },
          })
        );

      case EA:
        return dispatch(
          updateEAID({
            payload: { eaId: id },
            callback: () => {
              setVisible(false);
              dispatch(getLinkedAccounts());
            },
          })
        );
      case XBOX:
        return dispatch(
          updateXboxID({
            payload: { gammerTag: id },
            callback: () => {
              setVisible(false);
              dispatch(getLinkedAccounts());
            },
          })
        );
      case PLAYSTATION:
        return dispatch(
          updatePsID({
            payload: { psnGamerTag: id },
            callback: () => {
              setVisible(false);
              dispatch(getLinkedAccounts());
            },
          })
        );
      case EPIC:
        return dispatch(
          updateEpicID({
            payload: { epicId: id },
            callback: () => {
              setVisible(false);
              dispatch(getLinkedAccounts());
            },
          })
        );
      case STREETFIGHTER:
        return dispatch(
          updateStreetFighterID({
            payload: { streetFighterId: id },
            callback: () => {
              setVisible(false);
              dispatch(getLinkedAccounts());
            },
          })
        );
      case MLB:
        return dispatch(
          updateMLBID({
            payload: { mlbId: id },
            callback: () => {
              setVisible(false);
              dispatch(getLinkedAccounts());
            },
          })
        );
    }
  };

  const handleTwitchLogin = () => {
    if (twitchUrl) {
      window.location.href = twitchUrl;
    } else {
      toast.error("Twitch URL is not configured properly.");
    }
  };

  const saveTwitchUser = async (accessToken: any) => {
    const twitchUser = await getTwitchUserApi(accessToken);
    await dispatch(
      updateTwitchID({
        payload: { twitchId: twitchUser.id, twitchLogin: twitchUser.login },
      })
    );
    if (!twitchUser) return;
    await dispatch(getLinkedAccounts());
  };

  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen">
      <div className="container flex items-center flex-col gap-y-[20px] md:my-16 max-md:p-3">
        <div className="backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5 w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh] overflow-y-auto">
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <div className="flex flex-row items-center gap-4">
              <People className="h-8 w-8 max-md:h-6 max-md:w-6" />
              <span className="text-3xl max-md:text-xl text-white font-normal">
                Manage Accounts
              </span>
            </div>
            <button
              className=" h-8 w-8 max-md:h-6 max-md:w-6"
              onClick={handleClose}
            >
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="flex flex-col max-md:w-full justify-center mt-12">
            <span className="text-white text-2xl">Linked Accounts</span>
            <div className="flex md:flex-row flex-col justify-center items-center md:gap-16 gap-2 p-2 mt-4">
              <button
                className="flex h-16 w-2/3 max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4"
                onClick={handleTwitchLogin}
              >
                <div className="flex flex-row items-center gap-4">
                  <Twitch />
                  <div className="flex flex-col justify-center items-start">
                    <span className="text-placeholder md:text-sm text-[10px]">
                      TWITCH
                    </span>
                    <span className="text-white md:text-lg text-[10px]">
                      {linkedAccounts.accounts.twitch
                        ? linkedAccounts.accounts.twitch
                        : "  Connect Twitch"}
                    </span>
                  </div>
                </div>
                <Dots />
              </button>
              <button
                className="flex h-16 w-2/3 max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4"
                onClick={() => {
                  setData(ACTIVITION);
                  setVisible(true);
                }}
              >
                <div className="flex flex-row items-center gap-4">
                  <Activision />
                  <div className="flex flex-col justify-center items-start">
                    <span className="text-placeholder md:text-sm text-[10px]">
                      Activision
                    </span>
                    <span className="text-white md:text-lg text-[10px]">
                      {linkedAccounts.accounts.activisionId
                        ? linkedAccounts.accounts.activisionId
                        : " Connect Activision"}
                    </span>
                  </div>
                </div>
                <Dots />
              </button>
            </div>
            <div className="flex md:flex-row flex-col justify-center items-center md:gap-16 gap-2 p-2 md:mt-4">
              <button
                className="flex h-16 w-2/3 max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4"
                onClick={() => {
                  setData(EA);
                  setVisible(true);
                }}
              >
                <div className="flex flex-row items-center gap-4">
                  <Ea width={30} />
                  <div className="flex flex-col justify-center items-start">
                    <span className="text-placeholder md:text-sm text-[10px]">
                      EA ID
                    </span>
                    <span className="text-white md:text-lg text-[10px]">
                      {linkedAccounts.accounts.ea
                        ? linkedAccounts.accounts.ea
                        : " Connect EA"}
                    </span>
                  </div>
                </div>
                <Dots />
              </button>
              <button
                className="flex h-16 w-2/3 max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4"
                onClick={() => {
                  setData(XBOX);
                  setVisible(true);
                }}
              >
                <div className="flex flex-row items-center gap-4">
                  <Xbox />
                  <div className="flex flex-col justify-center items-start">
                    <span className="text-placeholder md:text-sm text-[10px]">
                      XBOX GAMER TAG
                    </span>
                    <span className="text-white md:text-lg text-[10px]">
                      {linkedAccounts.accounts.xbox
                        ? linkedAccounts.accounts.xbox
                        : "Connect Xbox Gamer Tag"}
                    </span>
                  </div>
                </div>
                <Dots />
              </button>
            </div>
            <div className="flex md:flex-row flex-col justify-center items-center md:gap-16 gap-2 p-2 md:mt-4">
              <button
                className="flex h-16 w-2/3 max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4"
                onClick={() => {
                  setData(PLAYSTATION);
                  setVisible(true);
                }}
              >
                <div className="flex flex-row items-center gap-4">
                  <PlayStation />
                  <div className="flex flex-col justify-center items-start">
                    <span className="text-placeholder md:text-sm text-[10px]">
                      PLAYSTATION ONLINE ID
                    </span>
                    <span className="text-white md:text-lg text-[10px]">
                      {linkedAccounts.accounts.playstation
                        ? linkedAccounts.accounts.playstation
                        : "Connect PlayStation Online Id"}
                    </span>
                  </div>
                </div>
                <Dots />
              </button>
              <button
                className="flex h-16 w-2/3 max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4"
                onClick={() => navigate("/manage-accounts/paypal/accounts")}
              >
                <div className="flex flex-row items-center gap-4">
                  <Paypal />
                  <div className="flex flex-col justify-center items-start">
                    <span className="text-placeholder md:text-sm text-[10px]">
                      PAYPAL
                    </span>
                    <span className="text-white md:text-lg text-[10px]">
                      {linkedAccounts.accounts.paypal
                        ? linkedAccounts.accounts.paypal
                        : "Connect PayPal"}
                    </span>
                  </div>
                </div>
                <Dots />
              </button>
            </div>
            <div className="flex md:flex-row flex-col justify-center items-center md:gap-16 gap-2 p-2 md:mt-4">
              <button
                className="flex h-16 w-2/3 max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4"
                onClick={() => {
                  setData(EPIC);
                  setVisible(true);
                }}
              >
                <div className="flex flex-row items-center gap-4">
                  <Epic width={30} />
                  <div className="flex flex-col justify-center items-start">
                    <span className="text-placeholder md:text-sm text-[10px]">
                      EPIC ID
                    </span>
                    <span className="text-white md:text-lg text-[10px]">
                      {linkedAccounts.accounts.epic
                        ? linkedAccounts.accounts.epic
                        : " Connect Epic id"}
                    </span>
                  </div>
                </div>
                <Dots />
              </button>
              <button
                className="flex h-16 w-2/3 max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4"
                onClick={() => {
                  setData(STREETFIGHTER);
                  setVisible(true);
                }}
              >
                <div className="flex flex-row items-center gap-4">
                  <Sf width={30} />
                  <div className="flex flex-col justify-center items-start">
                    <span className="text-placeholder md:text-sm text-[10px]">
                      STREETFIGHTER ID
                    </span>
                    <span className="text-white md:text-lg text-[10px]">
                      {linkedAccounts.accounts.streetFighter
                        ? linkedAccounts.accounts.streetFighter
                        : "Connect Street-Fighter ID"}
                    </span>
                  </div>
                </div>
                <Dots />
              </button>
            </div>
            <div className="flex md:flex-row flex-col justify-center items-center md:gap-16 gap-2 p-2 md:mt-4">
              <button
                className="flex h-16 w-2/3 max-md:w-full rounded-lg items-center justify-between gap-16 bg-borderColor p-4"
                onClick={() => {
                  setData(MLB);
                  setVisible(true);
                }}
              >
                <div className="flex flex-row items-center gap-4">
                  <Mlb width={30} />
                  <div className="flex flex-col justify-center items-start">
                    <span className="text-placeholder md:text-sm text-[10px]">
                      MLB ID
                    </span>
                    <span className="text-white md:text-lg text-[10px]">
                      {linkedAccounts.accounts.mlbId
                        ? linkedAccounts.accounts.mlbId
                        : " Connect Mlb id"}
                    </span>
                  </div>
                </div>
                <Dots />
              </button>
            </div>
          </div>
        </div>

        <PlatFormsModal
          visible={visible}
          data={data}
          onClose={() => setVisible(false)}
          onPress={(e) => onPress(e)}
        />
        <LinkConsoleModal
          visible={visibleConsoles}
          data={data}
          onClose={() => setVisibleConsoles(false)}
          onClick={() => onPress()}
        />
      </div>
      <LoadingAnim loading={linkedAccounts.loading} />
    </div>
  );
};

export default ManageAccountsScreen;
