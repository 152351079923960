import { useNavigate } from "react-router-dom";
import CloseIcon from "assets/close-button.svg";
import CommunityIcon from "assets/community_rules.svg";
import ButtonDc from "components/Button";

export default function CommunityRulesScreen({}) {
  const navigate = useNavigate();

  const onCancel = () => {
    navigate("/communication/main");
  };

  const onContinue = () => {
    navigate("/communication/select-community-image");
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <span className="text-3xl text-white items-center font-normal max-md:text-xl">
                The Community Rules
              </span>

              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center md:h-[60vh] max-md:flex-col ">
            <div className="flex max-md:flex-col md:gap-10 items-center">
              <div className="flex flex-col items-center justify-center p-10 md:mt-4 ">
                <img
                  src={CommunityIcon}
                  alt="CommunityIcon"
                  className="md:w-[30vw] md:max-w-[350px]"
                />
              </div>
              <div className="text-white text-xl font-light max-md:text-sm flex flex-col gap-2">
                <span>1. Be friendly</span>
                <span>2. Abide by community rules</span>
                <span>3. Respect peoples privacy</span>
                <span>4. Do Not impersonate people.</span>
                <span>5. Keep all activities & talk legal</span>
                <span>6. No spamming</span>
                <span>7. Don’t premote other platforms</span>
              </div>
            </div>
            <div className="px-10 flex flex-col mt-8 gap-5">
              <span className="text-white text-lg font-light max-md:text-sm flex text-center flex-col">
                Break these rules & you risk being banned for good.
              </span>
              <ButtonDc
                text="I Agree & Understand"
                type={"primary"}
                action={() => onContinue()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
