import React, { useState } from "react";
import Next from "assets/next-arrow.svg";
import CloseIcon from "assets/close-button.svg";
import NextDisabled from "assets/next-arrow-disabled.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { setMatchPlatform } from "store/matchMaking/matchMakingSlice";
import { setTournamentPlatform } from "store/tournament/tournamentSlice";

export default function SelectPlatformScreen({}) {
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const isNextDisabled = !selected;
  const matchMaking = useAppSelector((state: RootState) => state.matchMaking);
  const duelpath=matchMaking.selectedDuelPath;
  const platforms = matchMaking.selectedGame?.platforms;
  const isMobile = window.innerWidth <= 768;
  const handleClose = () => {
    navigate("/duels");
  };

  const handleNext = () => {
    if (duelpath == "CREATE_TOURNAMENT"){
      dispatch(setTournamentPlatform(selected));
    }else{
      dispatch(setMatchPlatform(selected));
    }
    navigate("/duels/select-game-type");
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex gap-[7em] flex flex-col items-center  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]   max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white items-center font-normal">
              Select Platform
            </span>
            <button className=" w-[25px] h-[25px]" onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="flex flex-row items-center gap-[3em] justify-center mt-10 max-md:flex-col">
            {platforms &&
              Object.keys(platforms)?.map((item: any, index: any) => (
                <button
                  className="flex w-[277px] h-[130px] p-[1.5em] items-center justify-center gap-[14.33px] rounded-[17.2px] bg-borderColor  focus:ring focus:ring-[#FFD482]"
                  onClick={() => setSelected(item)}
                >
                  <span className="text-center text-white text-[22px]">
                    {item}
                  </span>
                </button>
              ))}
          </div>
          {!isMobile && (
            <button
              className="absolute right-0 bottom-0 mr-[30px] mb-[30px] border-0  border-none outline-none"
              onClick={handleNext}
              disabled={isNextDisabled}
            >
              <img
                src={isNextDisabled ? NextDisabled : Next}
                width={100}
                height={100}
                alt="logo"
              />
            </button>
          )}
        </div>
        {isMobile && (
          <button
            className="absolute right-0 bottom-0 mr-[30px] mb-[30px] border-0  border-none outline-none"
            onClick={handleNext}
            disabled={isNextDisabled}
          >
            <img
              src={isNextDisabled ? NextDisabled : Next}
              width={100}
              height={100}
              alt="logo"
            />
          </button>
        )}
      </div>
    </div>
  );
}
