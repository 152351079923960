import React, { useState } from "react";
import CloseIcon from "../assets/close-button.svg";

interface PresetModalProps {
  onPress?: (data: string) => void;
  onClose?: () => void;
  data: any[];
  visible?: boolean;
}

const ConsolModal: React.FC<PresetModalProps> = ({
  visible,
  onPress,
  onClose,
  data,
}) => {
  return (
    <>
      {visible && (
        <div className="w-full h-full bg-modalFade  fixed z-10 ">
          <div
            className="w-3/5 h-4/5 p-[30px] bg-cardBackground rounded-[34px] shadow-lg absolute top-1/2 
          left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-[50px] max-md:w-full"
          >
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <span className="text-2xl text-white items-center font-normal">
                Select a console
              </span>
              <button className=" w-[25px] h-[25px]" onClick={onClose}>
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <div className="flex flex-row items-center justify-center gap-[3em]  max-md:gap-3 p-2 flex-wrap max-md:overflow-y-auto">
              {data?.map((item: any, index: any) => (
                <button
                  key={index}
                  className="flex w-[200px] h-[120px] p-[1.5em] items-center justify-center gap-[14.33px] rounded-[17.2px] bg-borderColor  focus:ring focus:ring-[#FFD482]"
                  onClick={() => onPress?.(item)}
                >
                  <div className="flex flex-col gap-6 ">
                    <span className="text-center text-white text-xl">
                      {item.text}
                    </span>
                    <span className="text-center text-subText text-xs">
                      {item.subtext}
                    </span>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConsolModal;
