import { onMessage } from "firebase/messaging";
import { messaging } from "firebaseConfig/firebase";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export async function requestPermission() {
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    console.log("Notification permission granted.");
  } else if (permission === "denied") {
    alert("You denied for the notification");
  }
}

// onMessage(messaging, (payload) => {
//   console.log("Message received. ", payload);
//   toast(payload.notification?.body);
// });
