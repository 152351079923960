import React, { useState } from "react";
import Next from "assets/next-arrow.svg";
import CloseIcon from "assets/close-button.svg";
import NextDisabled from "assets/next-arrow-disabled.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { AppDispatch } from "store/index";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import {
  setMatchDate,
  setPrivacy,
  setTournamentType,
} from "store/tournament/tournamentSlice";

export default function TournamentTypeScreen({}) {
  const navigate = useNavigate();
  const { from } = useParams();
  const [selected, setSelected] = useState("");
  const dispatch: AppDispatch = useAppDispatch();
  const isNextDisabled = !selected;
  const [value, setValue] = React.useState("Public");
  const isMobile = window.innerWidth <= 768;

  const onCancel = () => {
    navigate("/duels");
  };
  const handleNext = () => {
    dispatch(setTournamentType(selected));
    dispatch(setPrivacy(value));
    dispatch(setMatchDate(new Date()));
    if (from) return navigate("/duels/duel-overview");
    navigate("/duels/select-game");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <span className="text-3xl text-white items-center font-normal">
                Tournament
              </span>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <span className="text-subText text-xl font-normal text-center">
              Choose Tournament Type
            </span>
          </div>

          <div className="flex flex-col items-center justify-center gap-[3em] my-8 max-md:flex-col ">
            <button
              className="bg-borderColor p-[1.5rem] w-[20em] max-md:w-[16em] h-[8vh] max-md:h-[10vh] rounded-lg border-0  flex flex-col items-center justify-center  focus:ring focus:ring-[#FFD482]"
              onClick={() => setSelected("Single Elimination")}
            >
              <span className="text-white text-2xl">Single Elimination</span>
            </button>
            <button
              disabled
              className="bg-Darker_shade p-[1.5rem] w-[20em] max-md:w-[16em] h-[8vh] max-md:h-[10vh] rounded-lg border-0  flex flex-col items-center justify-center"
              onClick={() => setSelected("Double Elimination")}
            >
              <span className="text-white text-2xl">Double Elimination</span>
            </button>
            <button
              disabled
              className="bg-Darker_shade p-[1.5rem] w-[20em] max-md:w-[16em] h-[8vh] max-md:h-[10vh] rounded-lg border-0  flex flex-col items-center justify-center "
              onClick={() => setSelected("Round Robin")}
            >
              <span className="text-white text-2xl">Round Robin</span>
            </button>
            <div className="flex items-center justify-center">
              <div className="text-white flex flex-col">
                <span className="text-xl text-white font-normal mt-10">
                  Will this be a public or private match
                </span>
                <RadioGroup
                  defaultValue="Public"
                  name="radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="Public"
                    control={
                      <Radio
                        sx={{
                          color: "#FFD482",
                          "&.Mui-checked": {
                            color: "#FFD482",
                          },
                        }}
                      />
                    }
                    label="Public"
                  />
                  <FormControlLabel
                    value="Private"
                    control={
                      <Radio
                        sx={{
                          color: "#FFD482",
                          "&.Mui-checked": {
                            color: "#FFD482",
                          },
                        }}
                      />
                    }
                    label="Private"
                  />
                </RadioGroup>
              </div>
            </div>
          </div>

          <button
            className="absolute right-0 bottom-0 mr-[30px] md:mb-[30px] border-0  border-none outline-none"
            onClick={handleNext}
            disabled={!selected}
          >
            {!isMobile ? (
              <img
                src={isNextDisabled ? NextDisabled : Next}
                width={100}
                height={100}
                alt="logo"
              />
            ) : (
              <img
                src={isNextDisabled ? NextDisabled : Next}
                width={60}
                height={60}
                alt="logo"
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
