import React, { useEffect, useState } from "react";
import ButtonDc from "components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import CloseIcon from "assets/close-button.svg";
import { ReactComponent as Scale } from "assets/dispute_scale.svg";
import { getDisputes } from "store/winners/action";
import { ReactComponent as Disputes } from "assets/settings/disputes.svg";
import { resetCurrentMatch } from "store/currentMatch/currentMatchSlice";
import LoadingAnim from "components/Loader";

const UserDisputesScreen = () => {
  const navigate = useNavigate();
  let { from } = useParams();
  const loader = useAppSelector((state: RootState) => state.winners).loader;
  const disputes = useAppSelector((state: RootState) => state.winners).disputes;
  const unresolvedDisputes = disputes.filter(
    (dispute: { isResolved: boolean }) => !dispute.isResolved
  );
  const resolvedDisputes = disputes.filter(
    (dispute: { isResolved: boolean }) => dispute.isResolved
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getDisputes());
  }, []);

  const handleClose = () => {
    switch (from) {
      case "settings":
        return navigate("/settings");
      default:
        return navigate("/duels");
    }
  };

  const renderDisputes = (item: any, index: number) => {
    return (
      <div
        key={index}
        className="flex flex-col gap-2 border-2 border-primary p-2 m-4 items-center justify-center rounded-xl"
      >
        <img src={item.matchId.game.image} alt={"image"} width={100} />
        <span className="text-white">{item.matchId.matchId}</span>
        <span className="text-white">{item.matchId.gameType}</span>
        {!item.isResolved && (
          <ButtonDc
            text="ReVote"
            short
            type="primary"
            action={() => {
              dispatch(resetCurrentMatch());
              navigate(`/duels/vote/${item.matchId._id}/DISPUTE`);
            }}
          />
        )}
      </div>
    );
  };

  const renderSettledDisputes = (item: any, index: number) => {
    return (
      <div
        key={index}
        className="flex flex-col gap-2 border-2 border-primary p-2 m-4 items-center justify-center rounded-xl"
      >
        <img src={item.matchId.game.image} alt={"image"} width={100} />
        <span className="text-white">{item.matchId.matchId}</span>
        <span className="text-white">{item.matchId.gameType}</span>
      </div>
    );
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex   h-screen  p-2">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:pt-5 items-center ">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]   max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white items-center font-normal flex flex-row gap-2">
              <Scale height={40} width={50} />
              Disputes
            </span>
            <button className=" w-[25px] h-[25px]" onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="flex flex-col text-center items-center text-subText text-xl mt-10">
            <Disputes />
            <span>
              Disputes are typically settled within 24 hours. Weekends may
              lengthen this estimate
            </span>
          </div>
          <div className="text-center mt-5">
            <span className=" text-white text-xl ">Pending Disputes</span>

            <div className="w-full flex gap-x-[40px] gap-y-[20px] flex-wrap overflow-y-auto justify-center p-1 max-md:h-2/3">
              {unresolvedDisputes.length < 1 ? (
                <div className="border-dashed">
                  <span className=" flex text-subText border-darkGray justify-center items-center border-2 border-dashed h-16 rounded-xl m-5 p-5">
                    You have no pending disputes
                  </span>
                </div>
              ) : (
                unresolvedDisputes?.map(renderDisputes)
              )}
            </div>
          </div>
          <div className="text-center mt-5">
            <span className=" text-white text-xl ">Settled Disputes</span>
            <div className="w-full flex gap-x-[40px] gap-y-[20px] flex-wrap overflow-y-auto justify-center p-1 max-md:h-2/3">
              {resolvedDisputes.length < 1 ? (
                <div className="flex border-darkGray justify-center items-center border-2 border-dashed h-16 rounded-xl m-5 p-5">
                  <span className=" text-subText text-l max-md:text-center">
                    You have no settled disputes
                  </span>
                </div>
              ) : (
                resolvedDisputes?.map(renderSettledDisputes)
              )}
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
};

export default UserDisputesScreen;
