import { useState } from "react";
import { Box } from "@mui/material";
import { AppDispatch, RootState } from "../../store";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "store/hooks";
import { saveResetOtp, setToken } from "store/auth/authSlice";
import {
  confirmEmail,
  resendEmail,
  validateOtp,
  verifyEmail,
} from "../../store/auth/actions";

import Logo from "../../assets/weblogo.svg";
import Heading from "../../assets/Heading.svg";
import ButtonDc from "../../components/Button";
import LoadingAnim from "components/Loader";
import OtpInput from "components/OtpInput";

export default function VerifyOtpScreen({}: any) {
  const { type } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const user = useAppSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const [disableResend, setDisableResend] = useState(false);
  const otpConfirm = useAppSelector(
    (state: RootState) => state.auth.otpConfirm
  );
  const resetEmail = useAppSelector(
    (state: RootState) => state.auth.resetEmail
  );

  const onPressVerifyButton = async () => {
    if (type === "verify") {
      const response = await dispatch(
        verifyEmail({
          email: otpConfirm.email,
          username: otpConfirm.username,
          code: otp,
        })
      );
      if (response?.payload?.message) {
        dispatch(setToken());
        navigate("/");
      }
    }
    if (type === "forgot-password") {
      dispatch(saveResetOtp(otp));
      dispatch(
        validateOtp({
          payload: { code: otp, email: resetEmail },
          callback: (res) => {
            if (res.valid) {
              navigate("/create-new-password");
            }
          },
        })
      );
    } else {
      dispatch(
        confirmEmail({
          code: otp,
        })
      ).then(() => {
        if (user.userToken) return navigate("/");
      });
    }
  };
  const onPressResendButton = () => {
    setDisableResend(true);
    dispatch(
      resendEmail({
        email: otpConfirm.email,
        username: otpConfirm.username,
      })
    ).then(() => {
      setTimeout(() => {
        setDisableResend(false);
      }, 60000);
    });
  };
  return (
    <div className="mx-auto w-full bg-background bg-[url('/src/assets/Backdrop-log.png')] bg-no-repeat flex justify-between h-screen ">
      <div className="h-full w-4/5 max-md:hidden"></div>
      <div className="h-full flex flex-col justify-center items-center w-3/5 max-md:w-full">
        <div className="flex flex-row items-center justify-center gap-4 mb-5">
          <img
            src={Logo}
            width={100}
            height={100}
            alt="logo"
            className="max-md:w-[20%]"
          />
          <img
            src={Heading}
            width={200}
            height={200}
            alt="header"
            className="max-md:w-[50%]"
          />
        </div>
        <div className="text-white flex flex-col gap-5">
          <span className="text-primary text-3xl mt-10 justify-center flex">
            Enter OTP
          </span>
          <span className="mb-10">
            {"You should receive an email with 6\ndigits code"}
          </span>
          <div className="flex items-center justify-center">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <OtpInput
                separator={<span>-</span>}
                value={otp}
                onChange={setOtp}
                length={6}
              />
            </Box>
          </div>
          <div className="mb-10 flex flex-col">
            <span>
              Didn't get any code? {"  "}
              <button disabled={disableResend} onClick={onPressResendButton}>
                <span
                  className={`${
                    !disableResend ? "text-primary underline" : " text-darkGray"
                  } `}
                >
                  Resend Code
                </span>
              </button>
            </span>

            {disableResend && (
              <span className=" text-brightRed text-center text-xs">
                New verification code sent!
              </span>
            )}
          </div>
          <div>
            <ButtonDc
              text="Verify"
              action={onPressVerifyButton}
              type="primary"
            />
          </div>
        </div>
      </div>
      <LoadingAnim loading={user.loader} />
    </div>
  );
}
