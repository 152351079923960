import {
  deletePrivateChatApi,
  getCommunityChatApi,
  getPersonalChatMessagesApi,
  searchChatsApi,
  sendCommunityMessageApi,
  sendPersonalMessageApi,
} from "../../api/common.api";
import { createAsyncThunk } from "@reduxjs/toolkit";
interface WithCallback {
  payload: any;
  callback?: (data: any) => void;
}

export const getPersonalChatMessages = createAsyncThunk<any, any>(
  "/messaging/personal-messages",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await getPersonalChatMessagesApi(payload);
      const messagesReversed = {
        ...data,
        messages: data.messages.reverse(),
      };
      return messagesReversed;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const sendPersonalMessage = createAsyncThunk<any, WithCallback>(
  "/messaging/send-personal-message",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await sendPersonalMessageApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deletePrivateChat = createAsyncThunk<any, any>(
  "/messaging/deletePrivateChat",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await deletePrivateChatApi(payload);

      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const searchChats = createAsyncThunk<any, WithCallback>(
  "messaging/searchChats",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await searchChatsApi(payload.query, payload.page);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getCommunityChatMessages = createAsyncThunk<any, any>(
  "/messaging/community-messages",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await getCommunityChatApi(payload);
      const messagesReversed = {
        ...data,
        messages: data.messages.reverse(),
      };
      return messagesReversed;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const sendCommunityMessage = createAsyncThunk<any, WithCallback>(
  "/messaging/send-community-message",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await sendCommunityMessageApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
