import React, { useState, useEffect } from "react";
import CloseIcon from "assets/close-button.svg";
import Profile from "assets/profile.svg";
import Subscribe from "assets/subscribe.svg";
import Request from "assets/request.svg";
import Manages from "assets/manage.svg";
import LeaderBoard from "assets/leader_board.svg";
import Transactions from "assets/transactions.svg";
import Settings from "assets/settings.svg";
import Login from "assets/lock.svg";
import Logout from "assets/log_out.svg";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "store/index";
import { useDispatch } from "react-redux";
import { signOut } from "store/auth/actions";
import { useAppSelector } from "store/hooks";
import ImageHolder from "./ImagePlaceholder";
import { clearData } from "store/linkedAccounts/linkedAccountsSlice";
import PasswordChecker from "./PasswordCheckBox";

interface MenuProps {
  visible?: boolean;
  onClose: () => void;
}

const menuItems = [
  {
    name: "My Profile",
    icon: Profile,
    screen: "profile",
  },
  {
    name: "Wallet",
    icon: Transactions,
    screen: "wallet/main",
  },
  {
    name: "Leaderboard",
    icon: LeaderBoard,
    screen: "leaderboard",
  },

  // {
  //   name: "Duel Request",
  //   icon: Request,
  //   screen: "",
  // },
  // {
  //   name: "Manage Duels",
  //   icon: Manages,
  //   screen: "",
  // },
  {
    name: "Settings",
    icon: Settings,
    screen: "settings",
  },
];

const Menu: React.FC<MenuProps> = ({ visible, onClose }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const user = useAppSelector((state: RootState) => state.auth);
  const [isMobile, setIsMobile] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);

  const handleCancel = () => {
    onClose?.();
    navigate("/");
  };
  const onClick = (screen: string) => {
    onClose?.();
    navigate(`/${screen}`);
    //TODO
  };

  const handleLogout = () => {
    const callback = () => {
      window.location.reload();
    };
    dispatch(signOut({ callback }));
    dispatch(clearData());
    onClose?.();
  };

  const handleSignIn = () => {
    onClose?.();
    navigate("/login");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (visible) {
    return (
      <div
        className="w-full h-screen z-50 fixed"
        onClick={!passwordModal ? () => onClose?.() : undefined}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="w-full md:w-1/4 md:min-w-[260px]  h-full md:h-[95vh] p-[30px] bg-cardBackground md:rounded-[34px] shadow-lg absolute md:top-1/2 md:left-[25vw] transform md:-translate-x-1/2 md:-translate-y-1/2 flex flex-col md:mt-0 "
        >
          <div>
            <button
              className=" w-[15px] h-[15px] absolute right-10"
              onClick={handleCancel}
            >
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          {user?.userToken && isMobile && (
            <div className="flex items-center gap-x-2">
              <ImageHolder
                uri={user?.user?.profilePicture}
                className="flex w-[24px] h-[24px] rounded-full "
              />
              <text className="text-lg font-semibold text cursor-pointer">
                {user?.user?.username}
              </text>
            </div>
          )}
          <div className="flex flex-col mt-5 gap-y-4 overflow-x-hidden">
            {menuItems.map((item, idx) => (
              <button
                key={idx}
                className="flex flex-row gap-x-4 items-center"
                onClick={() => onClick(item.screen)}
              >
                <img
                  src={item.icon}
                  alt={item.name}
                  className="w-[18px] h-[18px] md:w-[3vw] md:h-[3vh]"
                />
                <text className="text-white">{item.name}</text>
              </button>
            ))}
            <button
              className="flex flex-row gap-x-4 items-center mt-4"
              onClick={() => {
                setPasswordModal(true);
              }}
            >
              <img
                src={Login}
                alt={"Login"}
                className="w-[18px] h-[18px] md:w-[3vw] md:h-[3vh]"
              />
              <text className="text-white">Login & Security</text>
            </button>
            <button
              className="flex flex-row gap-x-4 items-center"
              onClick={handleLogout}
            >
              <img
                src={Logout}
                alt={"Logout"}
                className="w-[18px] h-[18px] md:w-[3vw] md:h-[3vh]"
              />
              <text className="text-white">Logout</text>
            </button>
            {!user?.userToken && isMobile && (
              <button
                className="p-2 rounded-lg bg-primaryActive w-[100px] h-[36px] flex items-center justify-center"
                onClick={handleSignIn}
              >
                <span className="text-sm ">Sign in</span>
              </button>
            )}
            <div className="flex flex-col items-center absolute bottom-0 right-0 left-0 mb-5 gap-1">
              <button className="text-primaryActive text-center text-[16px] underline">
                <a
                  href={process.env.REACT_APP_TERMS_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </button>
              <button className="text-primaryActive text-center text-[16px] underline">
                <a
                  href={process.env.REACT_APP_POLICY_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </button>
            </div>
          </div>
        </div>
        {passwordModal && (
          <PasswordChecker
            visible={passwordModal}
            onClose={() => setPasswordModal(false)}
          />
        )}
      </div>
    );
  }
  return <></>;
};

export default Menu;
