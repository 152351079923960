import React, { useEffect, useMemo, useState } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { ReactComponent as SendMoney } from "assets/sendMoney.svg";
import Icon from "assets/moneySend.png";
import SearchBar from "components/Search";
import miscsStyles from "styles/miscs/miscs.module.scss";
import ImageHolder from "components/ImagePlaceholder";
import {
  getQuickAccess,
  getReceivers,
  searchReceivers,
} from "store/wallet/action";
import ButtonDc from "components/Button";
import { getOtherUser } from "store/user/action";
import { setReceiver } from "store/wallet/walletSlice";
import { WalletStatus } from "types/constants";
import LoadingAnim from "components/Loader";

export default function SendMoneyScreen({}) {
  const { from } = useParams();
  const navigate = useNavigate();
  const user = useAppSelector((state: RootState) => state.auth);
  const dispatch: AppDispatch = useAppDispatch();
  const { quickAccessUsers, allReceivers, loader } = useAppSelector(
    (state) => state.wallet
  );
  const [selected, setSelected] = useState<any>();
  const [value, setValue] = useState<any>("");
  const [searchResults, setSearchResults] = useState<any[]>(allReceivers);
  const isMobile = window.innerWidth <= 768;
  const [userData, setUserData] = useState<any[]>();
  const [showAllRecipients, setShowAllRecipients] = useState(false);
  const userDetails = useAppSelector((state: RootState) => state.user).user;

  const onCancel = () => {
    navigate("/");
  };
  useEffect(() => {
    dispatch(
      searchReceivers({
        payload: { query: value },
        callback: (data) => {
          setUserData(data.userList);
          if (data.userList) return setShowAllRecipients(true);
        },
      })
    );
  }, [searchResults, value]);

  useEffect(() => {
    dispatch(getQuickAccess());
    dispatch(getReceivers());
  }, []);

  const handleQuickAccessUserPress = (user: any) => {
    setSelected(user);
  };

  const handleViewProfilePress = (item: any) => {
    if (item._id === user.user._id) {
      return navigate(`/profile/${item._id}`);
    }
    dispatch(getOtherUser(item._id)).then(() =>
      navigate(`/profile/${item._id}`)
    );
  };

  const renderAllRecipients = (item: any, index: number) => {
    const selectedItem = item._id === selected?._id;
    const checkIsHost = item._id === userDetails?._id;
    return (
      <>
        {!checkIsHost && (
          <button
            key={index}
            className={`bg-modalFade p-2 rounded-lg flex flex-row max-md:w-full md:items-center max-md:justify-between md:gap-4 ${
              selectedItem ? "border-4 border-[#FFD482]" : ""
            }`}
            onClick={() => setSelected(item)}
          >
            <div className="flex flex-row items-center md:gap-8 gap-2 md:w-[15em] w-full">
              <ImageHolder
                uri={item.profilePicture}
                className=" w-[50px] h-[45px] max-md:w-[40px]  md:rounded-2xl rounded-lg"
              />
              <span className="text-xl text-white max-md:text-sm">
                {item.username}
              </span>
            </div>
            <button
              className="text-black text-l bg-primary p-1 rounded-xl hover:bg-primaryActive"
              onClick={() => handleViewProfilePress(item)}
            >
              View Profile
            </button>
          </button>
        )}
      </>
    );
  };

  const onContinue = () => {
    dispatch(setReceiver(selected));
    navigate(`/wallet/confirm-amount/${from}`);
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <SendMoney />
                <span className="text-3xl text-white items-center font-normal">
                  {from === WalletStatus.SEND ? "Send Money" : "Request Money"}
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="flex flex-row w-full justify-center max-md:flex-col ">
            <div className="flex flex-col justify-center gap-2  max-md:flex-col md:w-[50%]">
              <div className="flex flex-col items-center justify-center max-md:flex-col ">
                {!isMobile && (
                  <img className="md:w-[10vw]" src={Icon} alt="Icon" />
                )}
                <span className="text-2xl text-white items-center font-normal max-md:text-sm max-md:mt-8">
                  Select a {from === WalletStatus.SEND ? "Recipient" : "Sender"}{" "}
                  to Continue
                </span>
                <div className="mx-5 w-full m-4">
                  <SearchBar
                    placeholder="Search receiver here"
                    searchIcon
                    value={value}
                    onPress={(data) => setValue(data)}
                  />
                </div>
              </div>
              <div className="flex justify-between">
                <span className="text-2xl text-white font-normal ">
                  Quick Access
                </span>
                <button
                  className="text-xl text-primary font-normal hover:text-primaryActive"
                  onClick={() => setShowAllRecipients(!showAllRecipients)}
                >
                  {!showAllRecipients ? "See all" : "Hide"}
                </button>
              </div>
              <div
                className={`flex flex-row h-[140px] rounded-[12px] overflow-hidden hover:[overflow:overlay] ${miscsStyles.beautifulScrollbarHidden}`}
              >
                {quickAccessUsers?.map((user, index) => (
                  <div className="flex ">
                    {user._id !== userDetails?._id && (
                      <button
                        key={index}
                        className={`w-[10em] flex flex-col p-2 items-center justify-center eve rounded-lg mx-1 ${
                          user._id === selected?._id
                            ? "border-4 border-[#FFD482]"
                            : ""
                        }`}
                        onClick={() => handleQuickAccessUserPress(user)}
                      >
                        <ImageHolder
                          uri={user.profilePicture}
                          className=" w-[6em] rounded-2xl"
                        />
                        <span className="text-white text-center font-inter font-semibold text-sm  leading-normal tracking-wide mt-1 ">
                          {user?.username}
                        </span>
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
            {showAllRecipients && (
              <div className="flex flex-col items-center justify-center gap-2  max-md:flex-col  md:w-full max-md:mt-10">
                <span className="text-2xl text-white font-normal">
                  All Recipients
                </span>
                <div
                  className={`flex flex-col mt-1 h-[45vh] gap-y-4 max-md:w-full overflow-hidden hover:[overflow:overlay] ${miscsStyles.beautifulScrollbar}`}
                >
                  {userData && userData.map(renderAllRecipients)}
                </div>
              </div>
            )}
          </div>
          <div className="md:absolute flex flex-row justify-center bottom-10 left-0 right-0 mt-10 gap-10 max-md:flex-col">
            <div>
              <ButtonDc
                text="Back"
                short
                action={() => navigate("/wallet/main")}
              />
            </div>
            <div>
              <ButtonDc
                text="Continue"
                short
                disabled={!selected}
                type={"primary"}
                action={() => onContinue()}
              />
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
}
