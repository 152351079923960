import React from "react";
import { ReactComponent as Arrow } from "assets/arrow.svg";
import DefaultUser from "assets/temp/default_profile.png";

interface BracketItemProps {
  team1JSON: any[];
  team2JSON: any[];
  winner?: number;
  onPress?: () => void;
}

const BracketItem: React.FC<BracketItemProps> = ({
  team1JSON = [],
  team2JSON = [],
  winner,
  onPress,
}) => {
  const isMobile = window.innerWidth <= 768;

  const renderTeam = (team: any[]) => {
    const columns = Math.ceil(team.length / 2);
    const splitTeam = [];
    for (let i = 0; i < team.length; i += columns) {
      splitTeam.push(team.slice(i, i + columns));
    }
    return splitTeam.map((column, columnIndex) => (
      <div key={columnIndex} className="flex flex-col items-center gap-1">
        {column.map((player, index) => (
          <img
            key={index}
            src={player?.profilePicture ? player.profilePicture : DefaultUser}
            alt="user"
            width={isMobile ? 40 : 60}
            className={"rounded-lg md:h-[60px] h-[40px]"}
          />
        ))}
      </div>
    ));
  };

  const renderWinner = () => {
    if (!winner) {
      return <span className="text-white text-xl">?</span>;
    }

    const winningTeam = winner === 1 ? team1JSON : team2JSON;
    return (
      <div className="flex flex-row gap-2 flex-wrap justify-center">
        {renderTeam(winningTeam)}
      </div>
    );
  };

  return (
    <button
      className="flex flex-col items-center justify-center"
      onClick={onPress}
    >
      <div className="flex flex-row p-2 items-center justify-center gap-8 border-white border-2 rounded-lg">
        <div className="flex flex-row gap-2 flex-wrap justify-center">
          {renderTeam(team1JSON)}
        </div>
        <span className="text-xxl text-white">VS</span>
        <div className="flex flex-row gap-2 flex-wrap justify-center">
          {renderTeam(team2JSON)}
        </div>
      </div>
      <Arrow />
      <div
        className={`flex flex-row p-2 items-center justify-center gap-8 border-white border-2 rounded-lg ${
          winner ? "flex-wrap justify-center" : "w-[80px] h-[80px]"
        }`}
      >
        {renderWinner()}
      </div>
    </button>
  );
};

export default BracketItem;
