import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as CloseIcon } from "assets/close-button.svg";
import SearchBar from "./Search";
import ImageHolder from "./ImagePlaceholder";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import {
  getFollowers,
  getFollowing,
  removeFollowers,
  unfollowUser,
} from "store/user/action";

interface FollowModalProps {
  closeModal: () => void;
  type: string;
}

const FollowModal: React.FC<FollowModalProps> = ({ type, closeModal }) => {
  const dispatch: AppDispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  const followers = user?.followers;
  const following = user.following;

  const [value, setValue] = useState<any>("");
  const [searchResults, setSearchResults] = useState<any[]>(
    type === "FOLLOWERS" ? followers : following
  );

  useEffect(() => {
    if (type === "FOLLOWERS") {
      dispatch(getFollowers()).then((data) => {
        setSearchResults(data.payload.followers);
      });
    } else if (type === "FOLLOWING") {
      dispatch(getFollowing()).then((data) => {
        setSearchResults(data.payload.following);
      });
    }
  }, [dispatch, type]);

  const unFollow = (userId: string) => {
    if (type !== "FOLLOWERS") {
      dispatch(unfollowUser({ payload: { user: userId } })).then(() => {
        dispatch(getFollowing()).then((data) => {
          setSearchResults(data.payload.following);
        });
      });
    } else {
      dispatch(
        removeFollowers({
          payload: { followerId: userId },
          callback: () => {
            setSearchResults(
              searchResults.filter((user) => user._id !== userId)
            );
          },
        })
      );
    }
  };

  const filteredItems = useMemo(() => {
    return searchResults.filter((item) =>
      item.username?.toLowerCase().includes(value?.toLowerCase())
    );
  }, [searchResults, value]);

  const renderFollowers = (searchResults: any, index: number) => {
    return (
      <div
        key={index}
        className="flex items-center justify-between p-4  rounded mb-4"
      >
        <div className="flex items-center gap-4">
          <ImageHolder
            uri={searchResults?.profilePicture}
            className="w-10 h-10 rounded-full"
          />
          <span className="text-white text-lg">{searchResults?.username}</span>
        </div>
        <button
          className=" bg-primary text-black px-4 py-2 rounded-xl hover:bg-primaryActive"
          onClick={() => unFollow(searchResults?._id)}
        >
          {type === "FOLLOWERS" ? "Remove" : "Unfollow"}
        </button>
      </div>
    );
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="crop-image-dialog"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 transition-all backdrop-blur-sm">
        <div className="fixed inset-0 z-10 w-full overflow-y-auto">
          <div className="flex min-h-full justify-center px-2 py-12 text-center ">
            <div className="relative w-[95%] sm:w-[80%] min-h-[60vh] rounded-2xl bg-componentBackground bg-opacity-95 text-slate-100 text-left shadow-xl transition-all">
              <div className="px-5 py-4">
                <button
                  type="button"
                  className="rounded-md p-1 inline-flex items-center justify-center focus:outline-none absolute top-3 right-2"
                  onClick={closeModal}
                >
                  <span className="sr-only">Close menu</span>
                  <CloseIcon />
                </button>
                <span className="text-2xl text-white items-center font-normal">
                  {type === "FOLLOWERS" ? "Followers" : "Following"}
                </span>
              </div>
              <div className="mx-5 ">
                <SearchBar
                  placeholder={
                    type === "FOLLOWERS"
                      ? "Search Followers..."
                      : "Search Following..."
                  }
                  searchIcon
                  onPress={(data: any) => setValue(data)}
                  value={value}
                />
              </div>
              <div className="flex-grow overflow-y-auto p-4">
                {filteredItems?.map(renderFollowers)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowModal;
