import { createSlice } from "@reduxjs/toolkit";
import { getMatchMakingEvents } from "./action";
import { signOut } from "store/auth/actions";

interface AvailableToPlaySlice {
  matchMakingDuels: any[];
  matchMakingDuelsPage: number;
  loader: boolean;
  err: boolean;
}

const initialState: AvailableToPlaySlice = {
  matchMakingDuels: [],
  matchMakingDuelsPage: 1,
  loader: false,
  err: false,
};

export const availableToPlaySlice = createSlice({
  name: "availableToPlay",
  initialState,
  reducers: {
    resetMatchmakingDuels: (state) => {
      state.matchMakingDuels = [];
      state.matchMakingDuelsPage = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMatchMakingEvents.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getMatchMakingEvents.fulfilled, (state, action) => {
      state.matchMakingDuels = [
        ...state.matchMakingDuels,
        ...action.payload?.matches,
      ];
      state.matchMakingDuelsPage = state.matchMakingDuelsPage + 1;
      state.loader = false;
    });
    builder.addCase(getMatchMakingEvents.rejected, (state) => {
      state.err = true;
      state.loader = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
     state.matchMakingDuels = [];
     state.matchMakingDuelsPage = 1;
    });
  },
});

export default availableToPlaySlice.reducer;
export const { resetMatchmakingDuels } = availableToPlaySlice.actions;
