import React, { useEffect, useState } from "react";
import ButtonDc from "components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import { getCurrentMatch } from "store/currentMatch/currentMatchActions";
import { voteMatch } from "store/matchMaking/actions";
import { ping } from "ldrs";
import { userInfo } from "os";
import LoadingAnim from "components/Loader";
import Refresh from "assets/refresh_icon.svg";
import MatchRulesModal from "../MatchRules/matchRulesModal";

const ConfirmVoteScreen = () => {
  const fcmData = useAppSelector((state) => state.fcm);
  const [modalOpen, setModalOpen] = useState(false);
  const [voteDone, setVoteDone] = useState(true);
  const [countdown, setCountdown] = useState(599);
  const { loading } = useAppSelector((state) => state.currentMatch);
  const navigate = useNavigate();
  const { matchId, from } = useParams();
  let fromScreen = from;
  const userId = useAppSelector((state: RootState) => state.auth.user?._id);
  const currentMatch = useAppSelector(
    (state: RootState) => state.currentMatch
  ).currentMatch;
  const dispatch = useAppDispatch();
  ping.register();

  const handleLost = () => {
    const callback = () => {
      setVoteDone(true);
      fromScreen = "REVOTE";
      dispatch(getCurrentMatch({ matchId }));
    };

    dispatch(
      voteMatch({ payload: { matchId: matchId, isWon: false }, callback })
    );
  };
  const handleWin = () => {
    const callback = () => {
      setVoteDone(true);
      fromScreen = "REVOTE";
      dispatch(getCurrentMatch({ matchId }));
    };

    dispatch(
      voteMatch({ payload: { matchId: matchId, isWon: true }, callback })
    );
  };

  const handleMatchRules = () => {
    setModalOpen(true);
  };

  const handleWinner = () => {
    switch (currentMatch?.winner) {
      case 0:
        //disputed
        if (fromScreen == "DISPUTE") return;
        navigate(`/duels/dispute/${matchId}`);
        break;
      case 2:
        //team 2 won
        const isUserInTeam2 = currentMatch.team2.find(
          (user) => user === userId
        );
        if (isUserInTeam2) return navigate(`/duels/won/${matchId}`);
        navigate(`/duels/lost/${matchId}`);
        break;
      case 1:
        //team 1 won
        const isUserInTeam1 = currentMatch.team1.find(
          (user) => user === userId
        );
        if (isUserInTeam1) return navigate(`/duels/won/${matchId}`);
        navigate(`/duels/lost/${matchId}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(getCurrentMatch({ matchId }));
  }, [matchId]);

  useEffect(() => {
    const isVoteTeamOne = currentMatch?.team1Votes.some(
      (vote: any) => vote.user === userId
    );
    const isVoteTeamTwo = currentMatch?.team2Votes.some(
      (vote: any) => vote.user === userId
    );
    setVoteDone(isVoteTeamOne || isVoteTeamTwo);
  }, [currentMatch]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
          navigate(`/duels/dispute/${matchId}`);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [navigate, matchId]);

  useEffect(() => {
    if (matchId) getCurrentMatch({ matchId });
  }, [matchId]);

  useEffect(() => {
    handleWinner();
  }, [currentMatch]);

  useEffect(() => {
    if (
      fcmData?.data?.type === "VOTE_POPUP" ||
      fcmData?.data?.type === "MATCH_WINNER"
    )
      dispatch(getCurrentMatch({ matchId }));
  }, [fcmData]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const onRefresh = () => {
    dispatch(getCurrentMatch({ matchId }));
  };
  useEffect(() => {
    window.onbeforeunload = () => {
      return "";
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);
  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex min-h-screen h-full">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="backdrop-blur-sm con rounded-[34px] p-10 flex flex-col justify-center
        w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] gap-3 max-md:h-[calc(100%)]"
        >
          <div className="flex flex-row max-md:justify-between  justify-center items-center gap-4">
            <button
              className=" w-[25px] h-[25px] absolute right-12"
              onClick={onRefresh}
            >
              <img src={Refresh} alt="Close" />
            </button>
          </div>
          <div className="flex flex-row items-center max-md:flex-col justify-center gap-5 md:mt-16 ">
            <div
              className="w-36 h-36 max-md:w-24 max-md:h-24 rounded-full"
              style={{
                background: `url('${currentMatch?.coverImage}') no-repeat center`,
              }}
            ></div>
            <span className="text-5xl text-white items-center font-normal max-md:text-[26px]">
              Did You Win?
            </span>
          </div>
          <div className="flex flex-col justify-center items-center gap-y-10">
            <ButtonDc
              text="Match Rules"
              action={handleMatchRules}
              type="primary"
            />
            <div className="justify-center items-center  text-center">
              <span className="text-2xl text-white items-center font-normal text-center">
                You have {formatTime(countdown)} to enter results on your match,
                <br />
                if not it will be disputed and money hold for 24 hours
              </span>
            </div>
            {voteDone && (
              <div className="flex flex-col justify-center items-center bg-borderColor py-5 px-28  rounded-[12px] gap-5 max-md:px-5">
                <span className="text-2xl text-white items-center font-normal">
                  Waiting for others to vote
                </span>
                <l-ping size="45" speed="2" color="white"></l-ping>
              </div>
            )}
            {!voteDone && (
              <div className="flex flex-col justify-center items-center bg-borderColor py-5 px-28  rounded-[12px] gap-5 max-md:px-5">
                <span className="text-2xl text-white items-center font-normal">
                  Confirm Your Vote
                </span>
                <span className="text-xl text-placeholder items-center font-normal text-center">
                  Deliberately entering incorrect results will result in <br />{" "}
                  a permanent ban and forfeiture of your current balance.
                </span>
                <div className="w-[287px] h-[73px] flex items-center justify-center gap-5">
                  <ButtonDc
                    text="Lost"
                    action={handleLost}
                    type="cancel"
                    short={true}
                  />
                  <ButtonDc
                    text="Win"
                    action={handleWin}
                    type="primary"
                    short={true}
                  />
                </div>
              </div>
            )}
          </div>
          {modalOpen && (
            <MatchRulesModal
              visible={modalOpen}
              onClose={() => setModalOpen(false)}
            />
          )}
        </div>
      </div>
      <LoadingAnim loading={loading} />
    </div>
  );
};

export default ConfirmVoteScreen;
