import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import miscsStyles from "styles/miscs/miscs.module.scss";

import LoadingAnim from "components/Loader";
import Sidebar from "components/SideBar";
import SearchBar from "components/Search";
import Game2048 from "assets/games/game2048.png";
import TapGame from "assets/games/tapGame.jpg";
import BubbleShooter from "assets/games/BubbleShooter.jpeg";
interface GameTileProps {
  title: string;
  image: string;
  onPress: () => void;
}

const GameTile: React.FC<GameTileProps> = ({ title, image, onPress }) => {
  return (
    <div
      className="bg-cardBackground rounded-lg overflow-hidden cursor-pointer hover:shadow-lg transition duration-300"
      onClick={onPress}
    >
      <img src={image} alt={title} className="w-full h-40 object-cover" />
      <div className="p-4">
        <h3 className="text-white text-lg font-semibold">{title}</h3>
      </div>
    </div>
  );
};

const games = [
  {
    id: "game2048",
    title: "2048",
    image: Game2048,
    route: "/gaming/game2048",
  },
  {
    id: "tapGame",
    title: "Tap",
    image: TapGame,
    route: "/gaming/tap-game",
  },
  {
    id: "bubbleShooter",
    title: "Bubble Shooter",
    image: BubbleShooter,
    route: "/gaming/bubble-shooter",
  },
];

export default function GameHubScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loader } = useAppSelector((state) => state.matchMaking);
  const [results, setResults] = useState<any[]>([]);

  const handleSearch = (item: any) => {
    //TODO
  };

  const handleGameSelect = (gameRoute: string) => {
    navigate(gameRoute);
  };

  return (
    <div className="mx-auto w-full bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
      <Sidebar />
      <div className="w-full md:gap-[2em] flex flex-col items-center mt-10 max-md:gap-3 max-md:my-20 max-md:overflow-y-auto">
        <div className="md:mt-[20px] flex flex-row max-md:flex-col justify-between items-center w-[80vw]">
          <span className="text-white text-3xl text-center max-md:text-2xl">
            Game Hub
          </span>
          <div className="flex flex-row max-md:flex-col gap-4">
            <SearchBar
              searchIcon
              placeholder="Search games"
              type="people"
              setResults={(v) => setResults(v)}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div
          className={`flex flex-col md:p-4 gap-[2em] max-md:gap-8 md:gap-[5em] md:border-2 rounded-2xl bg-black bg-opacity-35 border-primary w-[80vw] h-[80vh] max-md:h-[65vh] ${miscsStyles.beautifulScrollbarHidden}`}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 desktop:grid-cols-4 gap-4">
            {games.map((game) => (
              <GameTile
                key={game.id}
                title={game.title}
                image={game.image}
                onPress={() => handleGameSelect(game.route)}
              />
            ))}
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
}
