import React from "react";
import { ReactComponent as CloseIcon } from "assets/close-button.svg";
import ImageCropper from "./ImageCropper";

interface ProfilePicModalProps {
  closeModal: () => void;
  updateAvatar: (dataUrl: string) => void;
  title?: string;
  type?: "PROFILE" | "ID" | "COMMUNITY";
}

const ProfilePicModal: React.FC<ProfilePicModalProps> = ({
  updateAvatar,
  closeModal,
  title,
  type,
}) => {
  return (
    <div
      className="relative z-10"
      aria-labelledby="crop-image-dialog"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 transition-all backdrop-blur-sm">
        <div className="fixed inset-0 z-10 w-full overflow-y-auto">
          <div className="flex min-h-full justify-center px-2 py-12 text-center ">
            <div className="relative w-[95%] sm:w-[80%] min-h-[60vh] rounded-2xl bg-borderColor bg-opacity-95 text-slate-100 text-left shadow-xl transition-all">
              <div className="px-5 py-4">
                <button
                  type="button"
                  className="rounded-md p-1 inline-flex items-center justify-center focus:outline-none absolute top-2 right-2"
                  onClick={closeModal}
                >
                  <span className="sr-only">Close menu</span>
                  <CloseIcon />
                </button>
                <ImageCropper
                  updateAvatar={updateAvatar}
                  closeModal={closeModal}
                  title={title}
                  type={type}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePicModal;
