import { RootState } from "store/index";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { createDcStream, streamForExternal } from "store/streaming/actions";
import { streamModes, streamTypes } from "types/constants";
import {
  setDefaultStream,
  setStreamingMethod,
} from "store/streaming/streamingSlice";

import { ReactComponent as Plus } from "assets/plus.svg";
import { ReactComponent as Bin } from "assets/bin.svg";
import CloseIcon from "assets/close-button.svg";

import ButtonDc from "components/Button";
import LoadingAnim from "components/Loader";
interface InputField {
  key: string;
  url: string;
}

export default function StreamingMorePlacesScreen({}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loader } = useAppSelector((state) => state.user);
  const { matchId } = useParams();
  const user = useAppSelector((state: RootState) => state.user.user);
  const [inputs, setInputs] = useState<InputField[]>([{ key: "", url: "" }]);
  const [isValid, setIsValid] = useState(false);

  const handleClose = () => {
    navigate(`/streaming/${matchId}/tool`);
  };

  const handleSkip = () => {
    dispatch(
      createDcStream({
        payload: {
          streamType: "IN_APP",
          streamMode: streamModes.GAMEPLAY,
          matchId: matchId,
        },
        callback: (result) => {
          dispatch(setStreamingMethod(streamTypes.STREAM_WITH_DUELCUBE));
          dispatch(
            setDefaultStream({
              streamRoomId: result.streamRoomId,
              streamToken: result.streamToken,
              streamTitle: "",
              streamerId: "",
              thumbnail: "",
              upstreamKey: "",
              upstreamUrl: "",
              isStreaming: true,
              streamType: "IN_APP",
              _id: user?._id,
              username: user?.username,
              isHost: true,
              createdAt: result.createdAt ? result?.createdAt : new Date(),
            })
          );
          navigate(`/duels/lobby/${matchId}/${"streamDC"}`);
        },
      })
    );
  };

  const handleContinue = () => {
    const streamKeys = inputs.map((input) => input.key);
    const streamUrls = inputs.map((input) => input.url);

    const streamDataPayload = {
      otherStreamKey: streamKeys,
      otherStreamUrl: streamUrls,
    };
    dispatch(
      createDcStream({
        payload: {
          streamType: "CASTING",
          streamMode: streamModes.GAMEPLAY,
          matchId: matchId,
        },
        callback: (result) => {
          dispatch(
            setDefaultStream({
              streamRoomId: result.streamRoomId,
              streamToken: result.streamToken,
              streamTitle: "",
              streamerId: "",
              thumbnail: "",
              upstreamKey: "",
              upstreamUrl: "",
              isStreaming: true,
              streamType: "CASTING",
              _id: user?._id,
              username: user?.username,
              isHost: true,
              createdAt: result.createdAt ? result?.createdAt : new Date(),
            })
          );
          dispatch(
            streamForExternal({
              payload: { otherStreams: streamDataPayload },
              callback: () => {
                dispatch(setStreamingMethod(streamTypes.STREAM_WITH_DUELCUBE));
                navigate(`/duels/lobby/${matchId}/${"streamDC"}`);
              },
            })
          );
        },
      })
    );
  };

  const addMoreInputs = () => {
    setInputs([...inputs, { key: "", url: "" }]);
  };

  const handleInputChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const values = [...inputs];
    values[index][event.target.name as keyof InputField] = event.target.value;
    setInputs(values);
    validateInputs(values);
  };

  const deleteInput = (index: number) => {
    if (inputs.length > 1) {
      const values = [...inputs];
      values.splice(index, 1);
      setInputs(values);
    }
  };

  const validateInputs = (values: InputField[]) => {
    const allValid = values.every(
      (input) =>
        input.key.length > 0 &&
        (input.url.startsWith("http://") || input.url.startsWith("rtmp://"))
    );
    setIsValid(allValid);
  };

  useEffect(() => {
    validateInputs(inputs);
  }, [inputs]);

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat gap-[7em] flex flex-col items-center  h-screen ">
      <div className="w-full h-screen bg-modalFade  fixed z-10 ">
        <div
          className="w-3/5 md:h-4/5 h-screen p-[30px] bg-cardBackground md:rounded-[34px] shadow-lg absolute top-1/2 
          left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-[50px] max-md:w-full overflow-y-auto"
        >
          <div className="flex flex-col gap-[1em] mt-5 ">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <span className="text-2xl text-white items-center font-normal">
                Start Streaming
              </span>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={handleClose}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-evenly items-center gap-10 ">
            <span className=" text-placeholder text-xl font-normal">
              Stream to More Places
            </span>
            <span className="text-placeholder text-center text-l font-normal">
              Want to share your stream on other platforms like YouTube or
              Facebook? Enter the streaming URL and key for each platform you
              want to add.
            </span>
            <div className="max-h-[20vh] max-md:max-h-[40vh] max-md:w-full flex flex-col gap-4 overflow-y-auto">
              {inputs.map((input, index) => (
                <div key={index} className="flex max-md:w-full flex-row gap-4">
                  <input
                    className="h-16 bg-borderColor rounded-xl md:w-[10vw] max-md:w-[20vw] max-md:text-xs p-2 text-center text-white focus:outline-none focus:ring-0 border-inputGray border-b-2 focus:border-primaryYellow peer"
                    placeholder="Enter stream key"
                    name="key"
                    value={input.key}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                  <input
                    className="h-16 bg-borderColor rounded-xl md:w-[30vw] max-md:w-full p-2 max-md:text-sm text-left text-white focus:outline-none focus:ring-0 border-inputGray border-b-2 focus:border-primaryYellow peer"
                    placeholder="Enter stream URL"
                    name="url"
                    value={input.url}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                  {inputs.length > 1 && (
                    <button
                      className="text-placeholder hover:text-primary"
                      onClick={() => deleteInput(index)}
                    >
                      <div className="hover:bg-borderColor p-2 rounded-full transition-colors">
                        <Bin className="w-6 h-6 " />
                      </div>
                    </button>
                  )}
                </div>
              ))}
            </div>
            <button
              className="flex flex-row gap-2 text-placeholder items-center transition-colors hover:text-primary"
              onClick={addMoreInputs}
              disabled //TODO
            >
              <Plus />
              Add More
            </button>
            <div className="flex flex-col gap-8 max-md:w-4/5">
              <ButtonDc text={"Skip"} action={handleSkip} type="background" />
              <ButtonDc
                text={"Continue"}
                action={handleContinue}
                type="primary"
                disabled={!isValid}
              />
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
}
