import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import EmojiPicker, { EmojiClickData, Theme } from "emoji-picker-react";
import { ReactComponent as CloseIcon } from "assets/close-button.svg";
import { ReactComponent as Send } from "assets/send.svg";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { getMatchChat, sendMatchMessage } from "store/matchMaking/actions";
import { updateMatchChatMessages } from "store/matchMaking/matchMakingSlice";
import miscsStyles from "styles/miscs/miscs.module.scss";
import ImageHolder from "./ImagePlaceholder";
import Loader from "./Loader";
import ChatModal from "./ChatModal";

interface MatchChatModalProps {
  matchId?: any;
  isMatch?: boolean;
  closeModal: () => void;
}
interface Chat {
  _id: string;
  user: {
    _id: string;
    username: string;
    profilePicture: string;
  };
  latest: {
    user: string;
    message: string;
    _id: string;
    time: string;
  };
}
interface MessageProps {
  user: string;
  message: string;
  time: string;
  username?: string;
  profilePicture?: string;
}
const MatchChatModal: React.FC<MatchChatModalProps> = ({
  closeModal,
  matchId,
  isMatch,
}) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const [message, setMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const chats = useAppSelector(
    (state: RootState) => state.matchMaking?.matchChat
  ).messages;
  const currentMatch = useAppSelector(
    (state: RootState) => state.currentMatch
  ).currentMatch;

  const loader = useAppSelector((state: RootState) => state.messaging.loader);
  const user = useAppSelector((state: RootState) => state.user);
  const currentUser = user?.user;
  const matchCommunity = currentMatch.community;
  const game = currentMatch.game;
  const fcmMessage = useAppSelector((state) => state.fcm);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const latestMessageRef = useRef<HTMLDivElement>(null);

  const handleEmojiSelect = (emojiData: EmojiClickData) => {
    setMessage(message + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  const handleSendMessage = () => {
    const date = new Date().toISOString();

    if (message.trim() !== "") {
      if (chats && currentUser) {
        const newChats = {
          user: currentUser?._id,
          message: message,
          time: date,
          username: user.user?.username,
          profilePicture: user.user?.profilePicture,
        };
        dispatch(updateMatchChatMessages(newChats));
        dispatch(
          sendMatchMessage({
            payload: { matchId: matchId, message: message },
          })
        );
      }

      setMessage("");
    }
  };

  const pushToChats = (messageData: any) => {
    const date = new Date().toISOString();
    const data = messageData?.data;
    const newMsg = {
      user: messageData.senderId,
      username: data?.sender,
      profilePicture: data?.senderPicture,
      time: date,
      message: data?.message,
    };
    if (messageData.data.senderId === currentUser?._id) return;
    dispatch(updateMatchChatMessages(newMsg));
  };

  useEffect(() => {
    if (fcmMessage.data.type !== "MATCH_MESSAGE") return;
    if (fcmMessage?.data) {
      pushToChats(fcmMessage);
    }
  }, [fcmMessage]);

  useEffect(() => {
    if (!matchId) return;
    dispatch(getMatchChat(matchId));
  }, [matchId]);

  useEffect(() => {
    if (chatContainerRef.current && latestMessageRef.current) {
      latestMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chats]);

  const groupMessagesByDate = (messages: any[]) => {
    if (!Array.isArray(messages)) {
      console.error(
        "Expected messages to be an array, but received:",
        messages
      );
      return {};
    }

    return messages.reduce((groupedMessages: any, message: any) => {
      const date = new Date(message.time).toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "2-digit",
        year: "numeric",
      });

      if (!groupedMessages[date]) {
        groupedMessages[date] = [];
      }

      groupedMessages[date].push(message);
      return groupedMessages;
    }, {});
  };
  const groupedMessages = groupMessagesByDate(chats);

  const handleDrawerButtonPress = () => {
    setSelectedChat(matchCommunity);
    setModalOpen(true);
  };

  const Message: React.FC<MessageProps> = ({
    user,
    username,
    message,
    time,
    profilePicture,
  }) => {
    const formattedTime = new Date(time).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return (
      <div
        className={`flex ${
          user === currentUser?._id ? "justify-end" : "justify-start"
        } mt-4`}
        style={{
          maxWidth: "100%",
        }}
      >
        <div
          className={`flex ${
            user === currentUser?._id ? "items-end" : "items-start"
          } flex-col py-1 px-2 rounded-md `}
        >
          <div
            className={`flex gap-2 ${
              user === currentUser?._id ? "flex-row-reverse " : "flex-row "
            } `}
          >
            <div>
              <ImageHolder uri={profilePicture} className="w-12 rounded-full" />
            </div>
            <p
              className={`inline-block ${
                user === currentUser?._id ? " bg-darkGray" : "bg-cardBackground"
              } p-2 min-w-16 rounded-xl whitespace-pre-wrap break-words text-left text-white`}
            >
              {user !== currentUser?._id && (
                <div className="mt-1">
                  <p className="text-xs " style={{ color: "#ffffff80" }}>
                    {username}
                  </p>
                </div>
              )}
              {message}
            </p>
          </div>
          <div className="mt-1">
            <p className="text-xs italic" style={{ color: "#ffffff80" }}>
              {formattedTime}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="relative z-10 "
      aria-labelledby="crop-image-dialog"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 transition-all backdrop-blur-sm ">
        <div className="fixed inset-0 z-10 w-full overflow-y-auto ">
          <div className="flex md:min-h-full max-md:h-screen max-md:w-full justify-center px-2 py-12 max-md:p-0 text-center ">
            <div className="relative w-[95%] max-md:w-full sm:w-[80%] min-h-[40vh]  md:rounded-2xl bg-borderColor md:bg-opacity-95 text-slate-100 text-left shadow-xl transition-all">
              <div className="px-10 py-4">
                <button
                  type="button"
                  className="rounded-md p-1 inline-flex items-center justify-center focus:outline-none absolute top-5 right-8"
                  onClick={closeModal}
                >
                  <span className="sr-only">Close menu</span>
                  <CloseIcon />
                </button>
                <div className="flex flex-row items-center gap-10 max-md:gap-3">
                  <div className="flex flex-col w-[50vw]">
                    <div className="flex flex-row w-full justify-between">
                      <span className="text-white text-3xl max-md:text-xl">
                        {game?.name} chat
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bg-placeholder h-[1px] mt-2 w-full"></div>
              </div>
              <div
                className={`container h-[calc(83vh-145px)] max-md:h-[calc(85vh-145px)] md:w-[60vw] md:bg-borderColor md:border-2 border-primary rounded-3xl px-10 py-4  ${miscsStyles.beautifulScrollbarHidden}`}
              >
                <div
                  className={`chat-messages ${miscsStyles.beautifulScrollbarHidden}`}
                  ref={chatContainerRef}
                >
                  {Object.entries(groupedMessages)?.map(
                    ([date, messages]: any) => (
                      <div key={date}>
                        <div className="text-center my-4">
                          <span className="text-white font-bold">{date}</span>
                        </div>
                        {messages?.map((msg: any, index: number) => (
                          <div
                            key={msg._id}
                            ref={
                              index === messages.length - 1
                                ? latestMessageRef
                                : null
                            }
                          >
                            <Message
                              user={msg.user}
                              message={msg.message}
                              time={msg.time}
                              username={msg?.username}
                              profilePicture={msg?.profilePicture}
                            />
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
                <div className="absolute md:w-[55vw] w-4/5 justify-center bottom-10 mt-4">
                  <div className="flex justify-center">
                    <button
                      className="w-52 h-10 rounded-xl font-semibold text-black bg-primary hover:bg-primaryActive "
                      onClick={handleDrawerButtonPress}
                    >
                      Join Community
                    </button>
                  </div>
                  <div className="flex flex-row items-center mt-4">
                    <button
                      type="button"
                      className="bg-white hover:bg-primary rounded-xl text-2xl h-10 w-14"
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    >
                      😊
                    </button>
                    {showEmojiPicker && (
                      <div className="absolute bottom-16 left-10">
                        <EmojiPicker
                          theme={Theme.DARK}
                          onEmojiClick={handleEmojiSelect}
                        />
                      </div>
                    )}
                    <input
                      type="text"
                      className="flex-grow p-2 mx-2 rounded-xl bg-white text-black"
                      placeholder="Type a message..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <button
                      type="button"
                      className="bg-primary flex-col justify-center items-center h-10 w-14 rounded-xl text-black"
                      onClick={handleSendMessage}
                    >
                      <Send fill="#000000" className="h-10 w-10" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {modalOpen && (
              <ChatModal
                isMatch={isMatch}
                chat={selectedChat}
                closeModal={() => {
                  setModalOpen(false);
                }}
                type={"COMMUNITIES"}
              />
            )}
          </div>
        </div>
        <Loader loading={loader} />
      </div>
    </div>
  );
};

export default MatchChatModal;
