import React from "react";
import ImageHolder from "components/ImagePlaceholder";

interface InvitePlayerListProps {
  type?: string;
  data: any[];
  selected: any[];
  onButtonPress: (data: any) => void;
  onReachEnd: () => void;
  hideButton?: boolean;
}

const InvitePlayerList: React.FC<InvitePlayerListProps> = ({
  type,
  data,
  selected,
  onButtonPress,
  onReachEnd,
  hideButton,
}) => {
  const filteredData = data.filter((item, index) => {
    return (
      data.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(item)) ===
      index
    );
  });

  const renderItem = (item: any, index: number) => {
    const alreadySelected = selected.find(
      (selectedItem) => selectedItem === item._id
    );
    const handleButtonPress = () => {
      onButtonPress(item);
    };

    return (
      <div
        key={index}
        className="flex justify-between items-center p-3 rounded-md mb-3"
      >
        <div className="flex items-center gap-4">
          <ImageHolder
            uri={item.profilePicture}
            className="w-10 h-10 rounded-full"
          />
          <span className="text-white text-lg">{item.username}</span>
        </div>
        {!alreadySelected && !hideButton && (
          <button
            className={`flex max-w-[100px] h-[35px] rounded-full p-1 items-center justify-center w-2/5 max-md:w-1/3 ${
              alreadySelected ? "bg-darkGray" : "bg-primary"
            }`}
            onClick={handleButtonPress}
          >
            {type || "Invite"}
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="overflow-y-auto h-[25vh] w-full px-4">
      {filteredData.map((item, index) => renderItem(item, index))}
    </div>
  );
};

export default InvitePlayerList;
