import React, { useEffect, useState } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch } from "store/index";
import ButtonDc from "components/Button";
import ProfilePicModal from "components/ProfilePicModal";
import { setUploadingDocuments } from "store/wallet/walletSlice";
import { WalletVerification } from "store/wallet/action";
import LoadingAnim from "components/Loader";

export default function UploadSelfieScreen({}) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const [imageUrl, setImageUrl] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const { uploadingDocuments, loader } = useAppSelector(
    (state) => state.wallet
  );

  const onCancel = () => {
    navigate("/wallet/main");
  };

  const onContinue = () => {
    dispatch(
      WalletVerification({
        payload: { images: uploadingDocuments },
        callback: () => {
          navigate("/wallet/verification-processing");
        },
      })
    );
  };

  const updateAvatar = (imgSrc: any) => {
    setImageUrl(imgSrc);
    dispatch(setUploadingDocuments(imgSrc));
    setModalOpen(false);
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="text-3xl text-white items-center font-normal max-md:text-xl">
                  Upload Your Selfie
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center gap-y-8 mt-8">
            <span className="text-center text-white text-lg md:w-[20vw]">
              Ensure your face is well-lit and fully visible without any
              headwear or glasses. Position yourself in a brightly lit area with
              natural light if possible.
            </span>
            {!imageUrl ? (
              <div className="border-2 border-dotted border-placeholder rounded-full h-[300px] w-[300px] max-md:h-[30vh] max-md:w-full mt-8 flex justify-center items-center">
                <span className="text-white">No Image Selected.</span>
              </div>
            ) : (
              <img
                src={imageUrl}
                alt="image"
                className="h-[300px] w-[300px] rounded-full max-md:h-[30vh] max-md:w-[30vh] mt-8 flex justify-center items-center"
              />
            )}
            <div className="flex flex-row justify-center gap-10 mt-16 max-md:flex-col">
              <div>
                <ButtonDc
                  text="Select Image"
                  short
                  action={() => setModalOpen(true)}
                />
              </div>
              <div>
                <ButtonDc
                  text="Proceed"
                  short
                  disabled={!imageUrl}
                  type={"primary"}
                  action={() => onContinue()}
                />
              </div>
            </div>
          </div>
        </div>
        {modalOpen && (
          <ProfilePicModal
            updateAvatar={updateAvatar}
            closeModal={() => setModalOpen(false)}
            title="Upload your Selfie"
            type="PROFILE"
          />
        )}
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
}
