import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { deleteAccount } from "store/user/action";
import { ReactComponent as Warning } from "assets/settings/warning.svg";
import { ReactComponent as WarningSmall } from "assets/settings/Triangle_Warning.svg";
import { ReactComponent as Delete } from "assets/recycle_bin.svg";

import LoadingAnim from "components/Loader";
import CloseIcon from "assets/close-button.svg";
import ButtonDc from "components/Button";

const DeleteAccountScreen = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const handleClose = () => navigate("/settings");
  const user = useAppSelector((state: RootState) => state.user);

  const onDelete = () => {
    dispatch(
      deleteAccount({
        payload: {},
        callback: () => {
          navigate("/");
        },
      })
    );
  };
  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen">
      <div className="container flex items-center flex-col gap-y-[20px] md:my-16 max-md:p-3">
        <div className="backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5 w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh] overflow-y-auto">
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <div className="flex flex-row items-center gap-4">
              <Delete className="h-8 w-8 max-md:h-6 max-md:w-6" />
              <span className="text-3xl max-md:text-xl text-white font-normal">
                Delete Account
              </span>
            </div>
            <button
              className=" h-8 w-8 max-md:h-6 max-md:w-6"
              onClick={handleClose}
            >
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>

          <div className="flex flex-col max-md:w-full text-center items-center justify-center gap-5 mt-12 ">
            <Warning className="w-[16vh] h-[16vh]" />
            <span className="text-2xl text-white font-semibold">
              Confirm Permanent Account Deletion
            </span>
            <span className="text-darkGray text-center md:w-[50vw]">
              You’re about to permanently delete your account. If you’re ready
              to delete, click Delete Account. Once you submit your account for
              deletion, you have 30 days tp reactivate your account and cancel
              the deletion. After 30 days, the deletion process will begin and
              you won’t be able to retrieve any of the content or information
              you have added.
            </span>
            <div className="md:absolute bottom-10 md:w-[380px] w-full flex gap-4 max-md:flex-col-reverse justify-center p-1 max-md:mt-10">
              <ButtonDc
                text="Cancel"
                type={"background"}
                action={handleClose}
                short
              />
              <ButtonDc
                text="Delete Account"
                type={"primary"}
                action={onDelete}
                short
                icon={<WarningSmall className="mb-1" />}
              />
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={user.loader} />
    </div>
  );
};

export default DeleteAccountScreen;
