import { getDisputesApi, getWinnersApi } from "../../api/common.api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getWinners = createAsyncThunk<any, void>(
  "/winners/winners",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await getWinnersApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getDisputes = createAsyncThunk<any, void>(
  "/getDisputes",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await getDisputesApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
