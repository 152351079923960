import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMatchmakingDuelsApi } from "api/common.api";

export const getMatchMakingEvents = createAsyncThunk<any, any>(
  "match/matchMakingEvents",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await getMatchmakingDuelsApi(payload);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
