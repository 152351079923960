import React, { useEffect, useState } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { AppDispatch } from "store/index";
import ButtonDc from "components/Button";
import Icon from "assets/processing.png";

export default function VerificationProcessingScreen({}) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();


  const onCancel = () => {
    navigate("/wallet/main");
  };


  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="text-3xl text-white items-center font-normal max-md:text-xl">
                  Verifying Your Details
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center gap-y-8 mt-8">
            <img src={Icon} alt="image" />
            <span className="text-primary text-3xl max-md:text-xl">
              Processing Your Verification
            </span>
            <span className="text-center text-white text-lg md:w-[25vw]">
              Please wait while we verify your ID and selfie. This may take a
              few moments. Do not close or refresh the page until the process is
              complete.
            </span>
            <div className="flex flex-row justify-centermd:mt-16">
              <ButtonDc
                text="Back to Wallet"
                short
                type={"primary"}
                action={() => onCancel()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
