import { createSlice } from "@reduxjs/toolkit";
import { gameTypeTypes, matchTypeNames } from "constant/types";
import {
  deleteTournamentMatch,
  getStagedPlayers,
  getTournamentById,
} from "./actions";
import { createTournamentMatch } from "store/tournament/action";
import { signOut } from "store/auth/actions";

interface TournamentTypes {
  _id: string;
  tournamentId?: string;
  coverImage: string;
  cubeWager: number;
  rounds: string;
  currentStage: number;
  host: string;
  invitedUsers: any[];
  players: any[];
  designatedSpectators: any[];
  game: any;
  gameType: gameTypeTypes;
  tournamentType: string;
  tournamentVisibility: string;
  tournamentStatus: string;
  tournamentDate: string;
  isStarted: boolean;
  entryFee: number;
  totalStakeAmount: number;
  isPaused: boolean;
  matchList: any[];
  teams: any[];
  stakes: any[];
  community: string;
}

interface LiveBracketsSlice {
  tournamentInfo: TournamentTypes;
  loading: boolean;
  err: boolean;
}

const defaultTournamentInfo: TournamentTypes = {
  _id: "",
  tournamentId: "",
  coverImage: "",
  cubeWager: 0,
  rounds: "",
  currentStage: 0,
  host: "",
  invitedUsers: [],
  players: [],
  designatedSpectators: [],
  game: {},
  gameType: matchTypeNames.ONE_VS_ONE as gameTypeTypes,
  tournamentType: "",
  tournamentVisibility: "",
  tournamentStatus: "",
  tournamentDate: "",
  isStarted: false,
  entryFee: 0,
  totalStakeAmount: 0,
  isPaused: false,
  matchList: [],
  teams: [],
  stakes: [],
  community: "",
};

const initialState: LiveBracketsSlice = {
  tournamentInfo: {
    _id: "",
    tournamentId: "",
    coverImage: "",
    cubeWager: 0,
    rounds: "",
    currentStage: 0,
    host: "",
    invitedUsers: [],
    players: [],
    designatedSpectators: [],
    game: {},
    gameType: matchTypeNames.ONE_VS_ONE as gameTypeTypes,
    tournamentType: "",
    tournamentVisibility: "",
    tournamentStatus: "",
    tournamentDate: "",
    isStarted: false,
    entryFee: 0,
    totalStakeAmount: 0,
    isPaused: false,
    matchList: [],
    teams: [],
    stakes: [],
    community: "",
  },
  loading: false,
  err: false,
};

export const liveBracketSlice = createSlice({
  name: "liveBrackets",
  initialState,
  reducers: {
    setCurrentTournamentStatus: (state, action) => {
      state.tournamentInfo.tournamentStatus = action.payload;
    },
    resetLiveBrackets: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTournamentById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTournamentById.fulfilled, (state, action) => {
      state.tournamentInfo = action.payload;
      state.loading = false;
    });
    builder.addCase(getTournamentById.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getStagedPlayers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStagedPlayers.fulfilled, (state, action) => {
      state.tournamentInfo.players = action.payload.stagedPlayers;
      state.loading = false;
    });
    builder.addCase(getStagedPlayers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createTournamentMatch.fulfilled, (state, action) => {
      state.tournamentInfo.tournamentId = action.payload.tournamentId;
      state.loading = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
    builder.addCase(deleteTournamentMatch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTournamentMatch.fulfilled, (state,action) => {
      state.loading = false;
    });
    builder.addCase(deleteTournamentMatch.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default liveBracketSlice.reducer;
export const { setCurrentTournamentStatus, resetLiveBrackets } =
  liveBracketSlice.actions;
