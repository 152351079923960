import React, { useEffect, useState } from "react";
import Next from "assets/next-arrow.svg";
import CloseIcon from "assets/close-button.svg";
import NextDisabled from "assets/next-arrow-disabled.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch } from "store/index";
import {
  setMatchSchedule,
  setPrivacy,
} from "store/matchMaking/matchMakingSlice";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

export default function ScheduleMatchScreen({}) {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState("Public");
  const duelPath = useAppSelector(
    (state) => state.matchMaking
  ).selectedDuelPath;
  const [selected, setSelected] = useState(
    duelPath === "CREATE_TOURNAMENT" ? "NOW" : ""
  );
  const [selectedTournament, setSelectedTournament] = useState(
    duelPath === "CREATE_TOURNAMENT" ? "SINGLE" : ""
  ); //TODO

  const isNextDisabled = !selected;

  const onCancel = () => {
    navigate("/duels");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleNext = () => {
    dispatch(setMatchSchedule(selected));
    dispatch(setPrivacy({ privacy: value }));
    if (selected === "NOW") {
      navigate("/duels/select-game");
    } else if (selected === "LATER") {
      navigate("/duels/time-select");
    }
  };

  return (
    <div className="mx-auto w-full flex-col bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex items-center  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center justify-between">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10 w-[calc(100%-176px)] 
        h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white items-center font-normal ">
              Schedule your match
            </span>
            <button className=" w-[25px] h-[25px]" onClick={onCancel}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          {duelPath !== "CREATE_TOURNAMENT" && (
            <div className="flex flex-row items-center gap-[5em] mt-[8em] justify-center max-md:flex-col max-md:gap-10 max-md:mt-10">
              <button
                className="bg-borderColor p-[1.5rem] w-[20em] h-[10vh] rounded-lg border-0  flex flex-col items-center justify-center  focus:ring focus:ring-[#FFD482]"
                onClick={() => setSelected("NOW")}
              >
                <span className="text-white text-2xl">Now</span>
              </button>
              <button
                className="bg-borderColor p-[1.5rem] w-[20em] h-[10vh] rounded-lg border-0  flex flex-col items-center justify-center  focus:ring focus:ring-[#FFD482]"
                onClick={() => setSelected("LATER")}
              >
                <span className="text-white  text-2xl">Later</span>
              </button>
            </div>
          )}
          {duelPath === "CREATE_TOURNAMENT" && (
            <div className="flex flex-row items-center gap-5 mt-[8em] justify-center max-md:flex-col max-md:gap-10 max-md:mt-10">
              <button
                className={`bg-borderColor p-[1.5rem] w-[20em] h-[10vh] rounded-lg border-0 flex flex-col items-center justify-center focus:ring focus:ring-[#FFD482] ${
                  selectedTournament === "SINGLE"
                    ? "focus:outline-none ring-2 ring-[#FFD482]"
                    : ""
                }`}
                onClick={() => setSelectedTournament("SINGLE")}
              >
                <span className="text-white text-2xl">Single Elimination</span>
              </button>
              <button
                className="bg-borderColor p-[1.5rem] w-[20em] h-[10vh] rounded-lg border-0  flex flex-col items-center justify-center  focus:ring focus:ring-[#FFD482]"
                onClick={() => setSelectedTournament("DOUBLE")}
                disabled
              >
                <span className="text-darkGray  text-2xl">
                  Double Elimination
                </span>
              </button>
              <button
                className="bg-borderColor p-[1.5rem] w-[20em] h-[10vh] rounded-lg border-0  flex flex-col items-center justify-center  focus:ring focus:ring-[#FFD482]"
                onClick={() => setSelectedTournament("ROUND")}
                disabled
              >
                <span className="text-darkGray  text-2xl">Round Robin</span>
              </button>
            </div>
          )}
          <div className="flex items-center justify-center md:mt-20">
            <div className="text-white flex flex-col">
              <span className="text-xl text-white font-normal mt-10">
                Will this be a public or private match
              </span>
              <RadioGroup
                defaultValue="Public"
                name="radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Public"
                  control={
                    <Radio
                      sx={{
                        color: "#FFD482",
                        "&.Mui-checked": {
                          color: "#FFD482",
                        },
                      }}
                    />
                  }
                  label="Public"
                />
                <FormControlLabel
                  value="Private"
                  control={
                    <Radio
                      sx={{
                        color: "#FFD482",
                        "&.Mui-checked": {
                          color: "#FFD482",
                        },
                      }}
                    />
                  }
                  label="Private"
                />
              </RadioGroup>
            </div>
          </div>
          <button
            className="absolute right-0 bottom-0 mr-[30px] mb-[30px] border-0  border-none outline-none"
            onClick={handleNext}
            disabled={isNextDisabled}
          >
            <img
              src={isNextDisabled ? NextDisabled : Next}
              width={100}
              height={100}
              alt="logo"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
