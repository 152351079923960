import { useNavigate } from "react-router-dom";
import { RootState } from "store/index";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ReactComponent as SteamingIcon } from "assets/sideBar/streaming.svg";
import { getLiveStreams } from "store/streaming/actions";
import { streamModes } from "types/constants";
import {
  resetStream,
  setDefaultStream,
  setStreamDescription,
  setStreamTags,
} from "store/streaming/streamingSlice";

import miscsStyles from "styles/miscs/miscs.module.scss";
import ButtonDc from "components/Button";
import LoadingAnim from "components/Loader";
import Sidebar from "components/SideBar";
import SearchBar from "components/Search";
import StreamingLiveViewer from "components/StreamingLiveViewer";

export default function StreamHubScreen({}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const liveStreams = useAppSelector(
    (state: RootState) => state.streaming
  ).liveStreams;
  const user = useAppSelector((state: RootState) => state.user?.user);
  const { loader } = useAppSelector((state) => state.matchMaking);
  const [results, setResults] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getLiveStreams(streamModes.HUB));
  }, [dispatch]);

  const handleGoLive = () => {
    dispatch(resetStream());
    navigate(`/stream/live-setup`);
  };

  const handleOnPressStream = (item: any) => {
    const parseTags = (tags: string[]): string[] => {
      if (tags && tags.length > 0) {
        try {
          const parsedTags: string[] = JSON.parse(tags[0]);

          const uniqueTags = parsedTags.filter(
            (tag, index, self) => self.indexOf(tag) === index
          );
          return uniqueTags;
        } catch (error) {
          console.error("Error parsing tags:", error);
          return [];
        }
      }
      return [];
    };

    dispatch(setStreamTags(parseTags(item?.tags)));
    dispatch(setStreamDescription(item?.description));
    dispatch(
      setDefaultStream({
        streamRoomId: item.inApp?.streamRoomId,
        streamToken: item.inApp?.streamToken,
        streamTitle: item?.streamTitle,
        streamCategory: item?.category,
        streamerId: "",
        thumbnail: "",
        upstreamKey: "",
        upstreamUrl: "",
        isStreaming: true,
        streamType: "IN_APP",
        _id: item.userId._id,
        username: item?.userId?.username,
        profilePicture: item.userId?.profilePicture,
        tags: item?.tags,
        isHost: item.userId?._id === user?._id,
        createdAt: item.createdAt,
      })
    );
    navigate(`/stream/preview-live-stream/${"hub"}/${item._id}`);
  };
  return (
    <div className="mx-auto w-full  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
      <Sidebar />
      <div className=" w-full md:gap-[2em] flex flex-col items-center mt-10 max-md:gap-3 max-md:my-20 max-md:overflow-y-auto ">
        <div className="md:mt-[20px] flex flex-row max-md:flex-col justify-between items-center w-[80vw]">
          <span className="text-white text-3xl text-center  max-md:text-2xl">
            Stream Hub
          </span>
          <div className="flex flex-row max-md:flex-col gap-4 z-20">
            <SearchBar
              searchIcon
              placeholder="Search lives"
              type="lives"
              setResults={(v) => setResults(v)}
            />
            <div className="justify-center items-center flex animate-pulse">
              <ButtonDc
                text="Go Live"
                action={handleGoLive}
                type="primary"
                icon={<SteamingIcon className="mr-2" />}
                short
              />
            </div>
          </div>
        </div>
        <div
          className={`flex flex-col md:p-4 gap-[2em] max-md:gap-8 md:gap-[5em] md:border-2 bg-black bg-opacity-35 rounded-2xl border-primary w-[80vw] h-[80vh] max-md:h-[58vh]  ${miscsStyles.beautifulScrollbarHidden}`}
        >
          {liveStreams.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {liveStreams?.map((stream: any, index: number) => (
                <StreamingLiveViewer
                  key={index}
                  paused
                  controls
                  userName={stream?.userId?.username}
                  profilePicture={stream?.userId?.profilePicture}
                  uri=""
                  watchingCount={0} //TODO
                  streamTitle={stream?.streamTitle}
                  streamRoomId={stream?.inApp?.streamRoomId}
                  streamToken={stream?.inApp?.streamToken}
                  thumbnail={stream?.thumbnail}
                  onPressStream={() => handleOnPressStream(stream)}
                />
              ))}
            </div>
          ) : (
            <div className="h-full w-full flex justify-center items-center">
              <span className="text-lg text-center text">- No Lives -</span>
            </div>
          )}
        </div>
      </div>

      <LoadingAnim loading={loader} />
    </div>
  );
}
