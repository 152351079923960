import React, { useEffect, useState } from "react";
import ButtonDc from "components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import { getCurrentMatch } from "store/currentMatch/currentMatchActions";
import WinnerFrame from "assets/won.png";
import LostFrame from "assets/lost.png";
import WinnerBg from "assets/winner-bg.svg";
import ImageHolder from "components/ImagePlaceholder";
import matchMakingSlice from "store/matchMaking/matchMakingSlice";
import PaymentMethod from "components/PaymentMethod";
import LoadingAnim from "components/Loader";
import { toast } from "react-toastify";
import { capturePayment } from "store/wallet/action";

const DuelLostScreen = () => {
  const navigate = useNavigate();
  const { matchId } = useParams();
  const userId = useAppSelector((state: RootState) => state.auth.user._id);
  const userProfile = useAppSelector(
    (state: RootState) => state.auth.user.profilePicture
  );
  const currentMatch = useAppSelector(
    (state: RootState) => state.currentMatch
  ).currentMatch;

  const cubeWager = currentMatch?.cubeWager;
  const isLoading = useAppSelector((state) => state.wallet)?.loader;

  const [isWinner, setIsWinner] = useState(false);
  const [readyToPay, setReadyToPay] = useState(false);
  const dispatch = useAppDispatch();

  const handlePaymentSuccess = () => {
    setReadyToPay(false);
    toast.success("Payment successful.");
    navigate(`/duels/payment-status/${matchId}?payment=success`);
  };

  const handlePay = () => {
    setReadyToPay(true);
  };

  const onPayPaypal = (token: any) => {
    dispatch(
      capturePayment({
        payload: {
          orderId: token,
          payId: token,
        },
        callback: handlePaymentSuccess,
      })
    );
  };

  useEffect(() => {
    const token = window.location?.search
      ?.split("&token=")?.[1]
      ?.split("&")?.[0];
    if (!token) return;
    onPayPaypal(token);
  }, []);

  useEffect(() => {
    window.onbeforeunload = () => {
      return "";
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);
  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex min-h-screen h-full">
      <LoadingAnim loading={isLoading} />
      {readyToPay && (
        <PaymentMethod
          amount={cubeWager}
          payId={matchId}
          onSuccess={handlePaymentSuccess}
          from="DUEL_LOST"
        />
      )}
      <div className="container flex  flex-col gap-y-[20px] md:my-16 items-center justify-center max-md:p-5">
        <div className=" flex flex-col  gap-y-[20px] md:mb-16 items-center justify-center max-md:mt-24">
          {isWinner && <img src={WinnerBg} alt="" className="absolute " />}
          <div className="flex flex-col  items-center justify-center gap-5 mt-16 ">
            <div className="items-center justify-center translate-y-[-10px]">
              <img src={isWinner ? WinnerFrame : LostFrame} alt="" />
              <ImageHolder
                uri={userProfile}
                className="absolute w-[170px] h-[170px] rounded-full top-[22px] left-[87px]"
              />
            </div>
            <span className="text-5xl text-white items-center font-normal max-md:text-3xl">
              {isWinner ? "CONGRATULATIONS!" : "LOST!"}
            </span>
          </div>
          <div className="flex flex-col justify-center items-center gap-y-6">
            <div className="justify-center items-center  text-center">
              <span className="text-2xl text-white items-center font-normal text-center max-md:text-xl">
                {isWinner ? "YOU ARE THE CHAMPION!" : "BETTER LUCK NEXT TIME!"}
              </span>
            </div>
          </div>
        </div>
        <ButtonDc text="Next" action={handlePay} type="primary" />
      </div>
    </div>
  );
};

export default DuelLostScreen;
