import React from "react";
import { createPortal } from "react-dom";
import { useState } from "react";

interface MatchRulesModalProps {
  visible: boolean;
  onClose: () => void;
}

const MatchRulesModal: React.FC<MatchRulesModalProps> = ({
  visible,
  onClose,
}) => {
  if (!visible) return null;

  return createPortal(
    <div
      className="relative z-10"
      aria-labelledby="crop-image-dialog"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 transition-all backdrop-blur-sm">
        <div className="fixed inset-0 z-10 w-full overflow-y-auto">
          <div className="flex md:min-h-full justify-center px-2 py-12 text-center ">
            <div className="relative w-[95%] sm:w-[80%] min-h-[60vh] rounded-2xl bg-borderColor bg-opacity-95 text-slate-100 text-left shadow-xl transition-all">
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-gray-800 rounded-lg p-6 w-11/12 md:w-3/4 lg:w-1/2 h-3/4 ">
                  <h2 className="text-2xl font-bold text-white mb-4">
                    Match Rules
                  </h2>
                  <div className="space-y-4 text-white h-[60vh] overflow-y-auto">
                    <p>
                      <strong>1. Voting on Match Outcome:</strong>
                      <br />
                      At the end of the match, both participants must vote for
                      either a win or loss.
                    </p>
                    <p>
                      <strong>2. Award Distribution:</strong>
                      <br />
                      80% of the total wagered amount will be awarded to the
                      participant voted as the winner.
                    </p>
                    <p>
                      <strong>3. Usage Fee:</strong>
                      <br />
                      The app will deduct 20% of the total wagered amount as a
                      usage fee.
                    </p>
                    <p>
                      <strong>4. Dispute Section:</strong>
                      <br />
                      If both participants vote for the same outcome, the match
                      will be moved to the dispute section.
                    </p>
                    <p>
                      <strong>5. Admin's Decision:</strong>
                      <br />
                      The admin's decision on the match outcome is final and
                      binding for both participants.
                    </p>
                    <p>
                      <strong>6. Confirmation Time:</strong>
                      <br />
                      Participants must confirm the match results (Voting)
                      within 10 minutes. Otherwise, it will be redirected to
                      disputes.
                    </p>
                    <p>
                      <strong>7. Wager Payment:</strong>
                      <br />
                      Both players must pay the agreed wager amount at the end
                      of the match.
                    </p>
                    <p>
                      <strong>8. Dispute Rounds:</strong>
                      <br />
                      Maximum dispute count will be 3 rounds per match.
                    </p>
                    <p>
                      <strong>9. Dispute Resolution Time:</strong>
                      <br />
                      Player disputes will be resolved within 24 hours.
                    </p>
                    <p>
                      <strong>10. Misconduct and Cheating:</strong>
                      <br />
                      Participants found guilty of misconduct or cheating will
                      be banned from the platform.
                    </p>
                    <p>
                      <strong>11. Confidentiality:</strong>
                      <br />
                      All match-related communications and dispute details are
                      confidential and should not be disclosed to third parties.
                    </p>
                  </div>
                  <div className="flex justify-end mt-4">
                    <button
                      onClick={onClose}
                      className="px-4 py-2 bg-primary text-black rounded hover:bg-primaryActive transition"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default MatchRulesModal;
