import React, { useEffect, useState } from "react";
import { ReactComponent as Delete } from "assets/delete.svg";

import "tailwindcss/tailwind.css";
import { Button } from "@mui/material";

interface KeypadInputProps {
  onChange?: (value: string) => void;
  buttonLabel?: string;
  buttonPressed?: () => void;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
}

const KeypadInput: React.FC<KeypadInputProps> = ({
  onChange,
  buttonLabel,
  buttonPressed,
  style,
  buttonStyle,
}) => {
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    setDisabled(!parseInt(value) || value.length === 0);
  }, [value]);

  const keypadLayout = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    [null, 0, "delete"],
  ];

  const handlePress = (button: string) => {
    let newValue;
    if (button === "delete") {
      newValue = value.slice(0, -1);
    } else {
      if (Number(value + button) < 1) {
        newValue = "0";
      } else {
        newValue = value + button;
      }
    }
    if (Number(newValue) <= 10000) {
      setValue(newValue);
      onChange?.(newValue);
    } else {
      if (newValue === "10000") return onChange?.(newValue);
      alert("Value cannot exceed $10,000");
    }
  };

  return (
    <div className="flex flex-col justify-center" style={{ ...style }}>
      {buttonLabel && (
        <Button
          variant="contained"
          style={{
            alignSelf: "center",
            height: "35px",
            borderRadius: "20px",
            backgroundColor: disabled ? "#757373" : "#EBBA6A",
            color: "#1E1E1E",
            font: "inter",
            textTransform: "none",
            fontSize: "20px",
            fontWeight: "600",
            ...buttonStyle,
          }}
          disabled={disabled}
          onClick={buttonPressed}
          className="w-[360px]  max-md:w-3/4"
        >
          {buttonLabel}
        </Button>
      )}

      <div className="keypad flex-col  mt-8 ">
        {keypadLayout.map((row, rowIndex) => (
          <div className="keypadRow flex flex-row mb-3" key={`row-${rowIndex}`}>
            {row.map((button, buttonIndex) => (
              <button
                key={`button-${buttonIndex}`}
                className={`numberButton mx-8 my-2 w-20 h-12 max-md:mx-3 rounded-2xl justify-center text-number text-2xl ${
                  button === "delete" || button === null
                    ? "mt-[10px] relative left-4 align-bottom justify-items-center hover:stroke-darkGray"
                    : "bg-darkerGray hover:bg-darkGray"
                }`}
                style={{
                  font: "inter",
                  fontWeight: "500",
                }}
                onClick={() => handlePress(String(button))}
              >
                {button === "delete" ? (
                  <div className="flex justify-center w-16">
                    <Delete />
                  </div>
                ) : (
                  button
                )}
              </button>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeypadInput;
