import React, { useState } from "react";
import CloseIcon from "../assets/close-button.svg";
import ButtonDc from "../components/Button";

interface PresetModalProps {
  onPressYes?: (data: string) => void;
  onPressNo?: () => void;
  onClose?: () => void;
  visible?: boolean;
}

const PresetModal: React.FC<PresetModalProps> = ({
  visible,
  onPressYes,
  onPressNo,
  onClose,
}) => {
  const [presetName, setPresetName] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (inputValue.length === 1 && inputValue[0] === " ") {
      return;
    }
    setPresetName(inputValue);
  };

  return (
    <>
      {visible && (
        <div className="w-full h-screen bg-modalFade  fixed z-10 ">
          <div
            className="w-3/5 h-4/5 p-[30px] bg-cardBackground rounded-[34px] shadow-lg absolute top-1/2 
          left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-y-[50px] max-md:w-full"
          >
            <div className="w-full rounded-t-[34px] flex items-center justify-between mt-5">
              <span className="text-2xl text-white items-center font-normal">
                Save these settings as a preset template?
              </span>
              <button className=" w-[25px] h-[25px]" onClick={onClose}>
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <div className="flex flex-row items-center justify-center mt-10">
              <div className="w-[35em] min-h-[64px] bg-borderColor rounded-xl flex items-center">
                <input
                  type="text"
                  className="w-full h-full bg-transparent outline-none ml-[20px] placeholder:text-placeholder font-normal size text-[14px] text-white"
                  placeholder="Preset Name"
                  value={presetName}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="flex flex-col gap-8">
              <ButtonDc
                text="Yes"
                type="primary"
                action={() => onPressYes?.(presetName)}
                disabled={!presetName}
              />
              <ButtonDc text="No" action={() => onPressNo?.()} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PresetModal;
