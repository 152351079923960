import React from "react";

export enum AlertType {
  SIGN_OUT = "signOut",
  NONE = "none",
}

interface AlertMessage {
  message?: string;
  waitButton?: string;
  positive?: string;
  negative?: string;
  user?: boolean;
  stars?: boolean;
  text?: boolean;
  visible: boolean;
  icon?: React.ReactElement;
}

type AlertMessages = {
  [key in AlertType]: AlertMessage;
};

export const alertMessages: AlertMessages = {
  [AlertType.SIGN_OUT]: {
    message: "do_you_want_to_sign_out",
    positive: "yes",
    negative: "no",
    visible: true,
  },
  [AlertType.NONE]: {
    visible: false,
  },
};

export const matchStatuses = {
  ONGOING: "ON_GOING",
  SCHEDULED: "SCHEDULED",
  WAITING_TO_START: "WAITING_TO_START",
  WAITING_YOU_TO_VOTE: "WAITING_YOU_TO_VOTE",
};

export type GameTypes =
  | "ONE_VS_ONE"
  | "TWO_VS_TWO"
  | "THREE_VS_THREE"
  | "FOUR_VS_FOUR"
  | "FIVE_VS_FIVE";

export enum gameTypes {
  ONE_VS_ONE = "ONE_VS_ONE",
  TWO_VS_TWO = "TWO_VS_TWO",
  THREE_VS_THREE = "THREE_VS_THREE",
  FOUR_VS_FOUR = "FOUR_VS_FOUR",
  FIVE_VS_FIVE = "FIVE_VS_FIVE",
}

export const gameTypesPlayerCount: Record<GameTypes, number> = {
  ONE_VS_ONE: 2,
  TWO_VS_TWO: 4,
  THREE_VS_THREE: 6,
  FOUR_VS_FOUR: 8,
  FIVE_VS_FIVE: 10,
};

export const gameTypeValues = {
  ONE_VS_ONE: "1 v 1",
  TWO_VS_TWO: "2 v 2",
  THREE_VS_THREE: "3 v 3",
  FOUR_VS_FOUR: "4 v 4",
  FIVE_VS_FIVE: "5 v 5",
};

export enum WalletStatus {
  SEND = "send",
  REQUEST = "request",
  WITHDRAW = "withdraw",
}

export const streamTypes = {
  TWITCH: "TWITCH",
  STREAM_WITH_DUELCUBE: "STREAM_WITH_DUELCUBE",
  STREAM_WITH_EXTERNAL: "STREAM_WITH_EXTERNAL",
  NOT_STREAM: "NOT_STREAM",
};

export const streamModes = {
  GAMEPLAY: "GAMEPLAY",
  HUB: "HUB",
};
