import React from "react";
import { createPortal } from "react-dom";
import { useState } from "react";

interface CommunityRulesModalProps {
  visible: boolean;
  onClose: () => void;
}

const CommunityRulesModal: React.FC<CommunityRulesModalProps> = ({
  visible,
  onClose,
}) => {
  if (!visible) return null;

  return createPortal(
    <div
      className="relative z-10"
      aria-labelledby="crop-image-dialog"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 transition-all backdrop-blur-sm">
        <div className="fixed inset-0 z-10 w-full overflow-y-auto">
          <div className="flex md:min-h-full justify-center px-2 py-12 text-center ">
            <div className="relative w-[95%] sm:w-[80%] min-h-[60vh] rounded-2xl bg-borderColor bg-opacity-95 text-slate-100 text-left shadow-xl transition-all">
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-gray-800 rounded-lg p-6 w-11/12 md:w-3/4 lg:w-1/2 h-3/4 ">
                  <h2 className="text-2xl font-bold text-white mb-4">
                    Community Rules
                  </h2>
                  <div className="space-y-4 text-white h-[60vh] overflow-y-auto">
                    <p>
                      <strong>1.</strong> Be respectful to others:
                      <br />
                      Treat others the way you would like to be treated. Be
                      kind, civil, and refrain from any rude, offensive, or
                      derogatory language.
                    </p>
                    <p>
                      <strong>2.</strong> No hate speech or discrimination:
                      <br />
                      Any speech or actions that promote hate, racism, sexism,
                      homophobia, or any other form of discrimination is
                      strictly prohibited.
                    </p>
                    <p>
                      <strong>3.</strong> No spamming or advertising:
                      <br />
                      Avoid using the community solely for promoting your own
                      interests. This includes spamming or advertising unrelated
                      products or services.
                    </p>
                    <p>
                      <strong>4.</strong> Keep it legal:
                      <br />
                      No discussions or activities that are illegal or violate
                      any local, national, or international laws.
                    </p>
                    <p>
                      <strong>5.</strong> Protect privacy:
                      <br />
                      Keep confidential information private and respect other
                      users' privacy. Don't share personal information without
                      their consent.
                    </p>
                    <p>
                      <strong>6.</strong> Be mindful of language and tone:
                      <br />
                      Remember that written communication can sometimes be
                      misinterpreted, so be aware of the tone you use when
                      communicating with others.
                    </p>
                    <p>
                      <strong>7.</strong> Stay on topic:
                      <br />
                      Try to keep discussions on topic and avoid derailing
                      conversations with irrelevant or off-topic comments.
                    </p>
                    <p>
                      <strong>8.</strong> No trolling or bullying:
                      <br />
                      Any behavior that is intended to harass, intimidate, or
                      bully others will not be tolerated.
                    </p>
                  </div>
                  <div className="flex justify-end mt-4">
                    <button
                      onClick={onClose}
                      className="px-4 py-2 bg-primary text-black rounded hover:bg-primaryActive transition"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default CommunityRulesModal;
