import React, { useState } from "react";
import TextInput from "components/TextInput";
import ButtonDc from "components/Button";

export default function Support() {
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    if (!name || !subject || !email || !message) {
      alert("Please fill all the fields");
      return;
    }

    const emailBody = `
    Name: ${name}%0D%0A
    Email: ${email}%0D%0A%0D%0A
    
    Message: ${message}`;

    window.open(
      `mailto:support@duelcube.com` +
        `?subject=${subject}` +
        `&body=${emailBody}`
    );
  };

  const handleNameChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setEmail(e.target.value);
  };

  const handleSubjectChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setMessage(e.target.value);
  };
  return (
    <div className="bg-background min-h-screen h-auto bg-[url('/src/assets/Backdrop-log.png')] bg-no-repeat bg-fit">
      <div className="container mx-auto p-[40px]">
        <h1 className="text-white text-center text-[36px] font-bold">
          Contact Support
        </h1>
        <div className="flex  gap-y-[20px] mt-10 justify-center">
          <div className="max-w-[60%] flex-col flex gap-y-2">
            <TextInput label="Name" onChange={handleNameChange} />
            <TextInput label="Subject" onChange={handleSubjectChange} />
            <TextInput label="Email" onChange={handleEmailChange} />
            <textarea
              placeholder="Write your message here"
              className="bg-borderColor resize-none h-[200px] outline-none text-white p-4 rounded-[16px] w-full"
              onChange={handleMessageChange}
            />
            <ButtonDc
              text={"Submit"}
              action={handleSubmit}
              type="primary"
              className="mt-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
