import { createSlice } from "@reduxjs/toolkit";
import {
  confirmEmail,
  getPrizePoolValue,
  resendEmail,
  resetPassword,
  sendOtp,
  signIn,
  signOut,
  signUp,
  validateOtp,
  verifyEmail,
} from "./actions";
import { getUser } from "store/user/action";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userToken: "",
    resetEmail: "",
    otp: "",
    tempUserToken: "",
    user: {
      _id: "",
      email: "",
      deviceToken: "",
      isPremium: false,
      phoneNumber: "",
      subscriberId: "",
      dob: "",
      username: "",
      profilePicture: "",
    },
    prizePool: "0",
    errorMessage: "",
    language: null,
    country: null,
    loader: false,

    otpConfirm: {
      email: "",
      username: "",
    },
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setDob: (state, action) => {
      state.user.dob = action.payload;
    },
    setToken: (state) => {
      state.userToken = state.tempUserToken;
      state.tempUserToken = "";
    },
    setOtpConfirm: (state, action) => {
      state.otpConfirm.email = action.payload.email;
      state.otpConfirm.username = action.payload.username;
    },
    saveResetEmail: (state, action) => {
      state.resetEmail = action?.payload;
    },
    saveResetOtp: (state, action) => {
      state.otp = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOut.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.userToken = "";
      state.user._id = "";
      state.user.email = "";
      state.user.deviceToken = "";
      state.user.isPremium = false;
      state.user.phoneNumber = "";
      state.user.subscriberId = "";
      state.user.dob = "";
      state.user.username = "";
      state.user.profilePicture = "";
      state.prizePool = "0";
      state.loader = false;
    });
    builder.addCase(signOut.rejected, (state, action) => {
      state.userToken = "";
      state.user._id = "";
      state.user.email = "";
      state.user.deviceToken = "";
      state.user.isPremium = false;
      state.user.phoneNumber = "";
      state.user.subscriberId = "";
      state.user.dob = "";
      state.user.username = "";
      state.user.profilePicture = "";
      state.prizePool = "0";
      state.loader = false;
    });
    builder.addCase(signUp.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.tempUserToken = action.payload.Authorization;
      state.loader = false;
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.errorMessage = String(action.payload);
      state.loader = false;
    });
    builder.addCase(signIn.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.userToken = action.payload.Authorization;
      state.loader = false;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.errorMessage = String(action.payload);
      state.loader = false;
    });
    builder.addCase(getPrizePoolValue.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getPrizePoolValue.fulfilled, (state, action) => {
      state.prizePool = action.payload.pricePoolBalance;
      state.loader = false;
    });
    builder.addCase(getPrizePoolValue.rejected, (state, action) => {
      state.errorMessage = String(action.payload);
      state.loader = false;
    });
    builder.addCase(verifyEmail.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.errorMessage = String(action.payload);
      state.loader = false;
    });
    builder.addCase(resendEmail.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(resendEmail.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(resendEmail.rejected, (state, action) => {
      state.errorMessage = String(action.payload);
      state.loader = false;
    });
    builder.addCase(confirmEmail.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(confirmEmail.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(confirmEmail.rejected, (state, action) => {
      state.errorMessage = String(action.payload);
      state.loader = false;
    });
    builder.addCase(sendOtp.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(sendOtp.rejected, (state, action) => {
      state.errorMessage = String(action.payload);
      state.loader = false;
    });
    builder.addCase(validateOtp.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(validateOtp.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(validateOtp.rejected, (state, action) => {
      state.errorMessage = String(action.payload);
      state.loader = false;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.errorMessage = String(action.payload);
      state.loader = false;
    });
    builder.addCase(getUser.rejected, (state) => {
    state.userToken = "";
    state.user._id = "";
    state.user.email = "";
    state.user.deviceToken = "";
    state.user.isPremium = false;
    state.user.phoneNumber = "";
    state.user.subscriberId = "";
    state.user.dob = "";
    state.user.username = "";
    state.user.profilePicture = "";
    state.prizePool = "0";
    state.loader = false;
    });
  },
});

export default authSlice.reducer;
export const {
  setLanguage,
  setCountry,
  setDob,
  setToken,
  setOtpConfirm,
  saveResetEmail,
  saveResetOtp,
} = authSlice.actions;
