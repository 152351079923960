import { subscribeToTopicApi } from "api/common.api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const subscribeToTopic = createAsyncThunk<any, any>(
    "user/subscribeToTopic",
    async (payload, { rejectWithValue }) => {
      try {
        const data: any = await subscribeToTopicApi(payload);
        return data;
      } catch (error: any) {
        if (!error.message) {
          throw error;
        }
        return rejectWithValue(error.message);
      }
    }
  );
  