import React, { ChangeEvent, CSSProperties } from "react";
import styles from "../styles/components/textInput.module.scss";
import { inputLabelClasses, TextField } from "@mui/material";

interface TextInputProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  label?: string;
  customStyles?: CSSProperties;
  maxLength?: number;
}

const TextInput: React.FC<TextInputProps> = ({
  onChange,
  customStyles,
  label,
  value,
  maxLength,
}) => {
  const isMobile = window.innerWidth <= 768;

  return (
    <div
      className={
        !isMobile ? styles.textInputContainer : styles.mobileTextInputContainer
      }
      style={customStyles || {}}
    >
      <TextField
        InputProps={{ disableUnderline: true }}
        id="reddit-input"
        label={label}
        variant="filled"
        onChange={onChange}
        value={value}
        inputProps={{ maxLength }}
        InputLabelProps={{
          sx: {
            color: "#C0C0C0",
            [`&.${inputLabelClasses.shrink}`]: {
              color: "#C0C0C0",
            },
          },
        }}
      />
    </div>
  );
};

export default TextInput;
