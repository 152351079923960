import axios from "axios";
import Api from "../services/api";
import {
  mlbUrl,
  saveActivitionUrl,
  saveEaUrl,
  saveEpicUrl,
  savePsUrl,
  saveStreetFighterUrl,
  updateTwitchUserUrl,
  updateXboxUserUrl,
} from "./urls";

export const updateTwitchUserApi = (body: object) =>
  Api.request({
    method: "put",
    url: updateTwitchUserUrl,
    body,
  });
export const updateXboxUserApi = (body: object) =>
  Api.request({
    method: "put",
    url: updateXboxUserUrl,
    body,
  });
export const updatePlayStationUserApi = (body: object) =>
  Api.request({
    method: "put",
    url: savePsUrl,
    body,
  });
export const updateEaUserApi = (body: object) =>
  Api.request({
    method: "put",
    url: saveEaUrl,
    body,
  });
export const updateEpicUserApi = (body: object) =>
  Api.request({
    method: "put",
    url: saveEpicUrl,
    body,
  });
export const updateStreetFighterUserApi = (body: object) =>
  Api.request({
    method: "put",
    url: saveStreetFighterUrl,
    body,
  });
export const updateMlbUserApi = (body: object) =>
  Api.request({
    method: "put",
    url: mlbUrl,
    body,
  });
export const updateActivitionUserApi = (body: object) =>
  Api.request({
    method: "put",
    url: saveActivitionUrl,
    body,
  });
export const getTwitchUserApi = (acccessToken: string) => {
  return new Promise((resolve: (data: any) => void) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        process.env.REACT_APP_TWITCH_USERS_URL ||
        "https://api.twitch.tv/helix/users",
      headers: {
        "Client-ID":
          process.env.REACT_APP_TWITCH_CLIENT_ID_URL ||
          "vv4wran4dje8h1mshbboby5xin2y34",
        Authorization: `Bearer ${acccessToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const data = response.data?.data;
        if (!data[0]?.id) return resolve(null);
        return resolve(data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  });
};
