import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { ReactComponent as Link } from "assets/settings/link.svg";
import { ReactComponent as Search } from "assets/settings/search.svg";
import { ReactComponent as Refresh } from "assets/settings/refresh.svg";
import { ReactComponent as Arrow } from "assets/arrow-right.svg";
import { ReactComponent as Share } from "assets/share_icon.svg";
import LoadingAnim from "components/Loader";
import CloseIcon from "assets/close-button.svg";
import Clipboard from "clipboard";
const ReferFriendScreen = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const handleClose = () => navigate("/settings");
  const user = useAppSelector((state: RootState) => state.user);
  const referralId = user.user?.referralId;

  const onShareCode = async () => {
    if (referralId) {
      Clipboard.copy(`${process.env.REACT_APP_REFERRAL_ID_URL}${referralId}`);
      showCopiedMessage();
    }
    const shareData = {
      title: "DuelCube",
      text: `Hey! I'm using DuelCube to play games and earn money. Join me and get 1 DC! Follow this URL: ${process.env.REACT_APP_REFERRAL_ID_URL}${referralId}`,
    };
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.error("Error sharing", err);
    }
  };

  const copyReferralCodeToClipboard = () => {
    if (referralId) {
      Clipboard.copy(referralId);
      showCopiedMessage();
    }
  };

  const [isCopiedMessageVisible, setIsCopiedMessageVisible] = useState(false);

  const showCopiedMessage = () => {
    setIsCopiedMessageVisible(true);

    setTimeout(() => {
      setIsCopiedMessageVisible(false);
    }, 2000);
  };

  const onLearnMore = () => {
    window.open(process.env.REACT_APP_REFERRALS_URL, "_blank");
  };

  const handleInviteContactsPress = () => {
    //TODO
  };

  const Option: React.FC<{
    icon: React.ReactNode;
    title: string;
    onPress?: () => void;
  }> = ({ icon, title, onPress }) => {
    return (
      <button
        onClick={onPress}
        className="flex justify-between items-center w-5/6 h-16 bg-borderColor text-white rounded-lg mb-2 px-4 hover:bg-darkGray max-md:w-full"
      >
        <div className="flex flex-row gap-3">
          {icon}
          <span className="flex-grow ml-3">{title}</span>
        </div>
        <Arrow className="text-lg" />
      </button>
    );
  };
  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen">
      <div className="container flex items-center flex-col gap-y-[20px] md:my-16 max-md:p-3">
        <div className="backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5 w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh] overflow-y-auto">
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <div className="flex flex-row items-center gap-4">
              <Share className="h-8 w-8 max-md:h-6 max-md:w-6" />
              <span className="text-3xl max-md:text-xl text-white font-normal">
                Refer A Friend
              </span>
            </div>
            <button
              className=" h-8 w-8 max-md:h-6 max-md:w-6"
              onClick={handleClose}
            >
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>

          <div className="flex max-md:flex-col max-md:w-full items-center justify-center gap-10 mt-12 ">
            <div className="bg-primary flex md:min-h-[280px] h-[30vh] flex-col items-center justify-center p-4 rounded-3xl mx-auto w-[30vw] text-center max-md:w-full max-md:h-[25vh] ">
              {isCopiedMessageVisible && (
                <span className="text-darkGreen mb-4">
                  Referral Code copied!
                </span>
              )}
              <span className="text-3xl max-md:text-xl font-semibold mb-2">
                Your Referral Code
              </span>
              <span className=" text-8xl max-md:text-6xl font-bold text-borderColor">
                {referralId}
              </span>
              <button
                onClick={copyReferralCodeToClipboard}
                className="bg-white font-semibold text-black w-2/3 py-2 md:px-4 mt-4 rounded-md hover:bg-disabled"
              >
                Copy Referral Code
              </button>
            </div>
            <div className="bg-placeholder h-[60vh] mt-2 w-[1px] max-md:h-[1px] max-md:w-full"></div>
            <div className="flex flex-col ">
              <div className="md:mt-8 md:px-2">
                <h2 className="text-2xl text-white font-semibold">
                  Invite Friends, Earn Rewards!
                </h2>
                <p className="text-white mt-2">
                  Invite friends to 1vs1 Game. For Each friend that joins and
                  plays a match, you both get 1DC!
                </p>
                <p
                  onClick={onLearnMore}
                  className="text-primary mt-2 cursor-pointer"
                >
                  Learn More
                </p>
              </div>

              <div className="mt-6 max-md:w-full">
                <Option
                  icon={<Link />}
                  title="Share Referral Link"
                  onPress={onShareCode}
                />
                {/* <Option
                  icon={<Search />}
                  title="Invite From Contacts"
                  onPress={handleInviteContactsPress}
                /> */}
                <Option
                  icon={<Refresh />}
                  title="View Past Invites"
                  onPress={() => navigate("/settings/past-invites")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={user.loader} />
    </div>
  );
};

export default ReferFriendScreen;
