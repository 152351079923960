import MatchMaking from "assets/matchMaking.png";
import Duel from "assets/duel.png";
import Tournament from "assets/tournament.png";
import Arrow from "assets/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import Sidebar from "components/SideBar";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import {
  setDuelPath,
  setFromPresets,
  setMatchFromHome,
} from "store/matchMaking/matchMakingSlice";
import { setScreenStatus } from "store/currentMatch/currentMatchSlice";
import { resetMatchmakingDuels } from "store/availableToPlay/availableToPlaySlice";
import { toast } from "react-toastify";

export default function SelectDuelPathScreen({}) {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { backgroundMatch, backgroundTournament } = useAppSelector(
    (state: RootState) => state.matchMaking
  );

  const handleClick = (path: string) => {
    if (
      (backgroundMatch && backgroundMatch?.matchStatus !== "SCHEDULED") 
    )
      return toast.error(
        "Cannot create a match until u finish previously created matches."
      );
    if (backgroundMatch?.matchStatus === "SCHEDULED")
       toast.warning("Note!. You have already scheduled match.");
    dispatch(setDuelPath(path));
    dispatch(setMatchFromHome(false));
    dispatch(setFromPresets(false));
    dispatch(resetMatchmakingDuels());
    dispatch(setScreenStatus(path));
    if (path === "CREATE_TOURNAMENT") return navigate("/tournament/type");
    if (path === "CREATE_MATCHMAKING") {
      navigate("/duels/select-game");
    } else {
      navigate("/duels/schedule-match");
    }
  };

  return (
    <div className="mx-auto w-full  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
      <Sidebar />
      <div className=" w-full md:gap-[2em] flex flex-col items-center mt-10 max-md:gap-3 max-md:my-20 max-md:overflow-y-auto">
        <div className="mt-[20px] flex flex-row ">
          <text className="text-white text-3xl text-center max-md:text-2xl">
            Select a duel path
          </text>
        </div>
        <div className="flex flex-col  gap-[2em] max-md:gap-8 items-center mt-10 desktop:mt-[20vh] md:gap-[5em]">
          <div className="flex max-md:flex-col sm:flex-col desktop:flex-row md:gap-[5em] max-md:gap-8 justify-center ">
            <button
              className="max-md:w-[60vw] w-[20em] bg-selectedPrimary rounded-2xl border-0  flex flex-col items-center justify-center  hover:ring hover:ring-[#FFD482]"
              onClick={() => handleClick("CREATE_MATCHMAKING")}
            >
              <img
                src={MatchMaking}
                alt="Matchmaking"
                className="rounded-2xl max-md:h-[15vh] w-[80vw]"
              />
              <span className="text-xl font-medium max-md:text-lg text-black">
                Matchmaking
              </span>
            </button>
            <button
              className="max-md:w-[60vw] w-[20em] bg-selectedPrimary rounded-2xl border-0  flex flex-col items-center justify-center  hover:ring hover:ring-[#FFD482]"
              onClick={() => handleClick("DUEL")}
            >
              <img
                src={Duel}
                alt="Duel"
                className="rounded-2xl max-md:h-[15vh] w-[80vw]"
              />
              <span className="text-xl font-medium max-md:text-lg text-black">
                Create a duel
              </span>
            </button>
            <button
              className="max-md:w-[60vw] w-[20em] bg-selectedPrimary rounded-2xl border-0  flex flex-col items-center justify-center  hover:ring hover:ring-[#FFD482]"
              onClick={() => handleClick("CREATE_TOURNAMENT")}
            >
              <img
                src={Tournament}
                alt="Tournament"
                className="rounded-2xl max-md:h-[15vh] w-[80vw]"
              />
              <span className="text-xl font-medium max-md:text-lg text-black">
                Create a tournament
              </span>
            </button>
          </div>
          <div>
            <button
              className="border-2 border-selectedPrimary rounded-xl py-3 px-5 flex flex-row gap-2 items-center md:w-[20em] 
              justify-between hover:ring hover:ring-[#FFD482]"
              onClick={() => navigate("/duels/presets")}
            >
              <span className="text-white ">
                Select from your preset settings
              </span>
              <img src={Arrow} alt="arrow" className="w-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
