import React, { useEffect, useState } from "react";
import DefaultUser from "assets/temp/default_profile.png";

interface ImageHolderProps {
  uri: string | undefined;
  className?: string;
  width?: number;
  height?: number;
}

const ImageHolder: React.FC<ImageHolderProps> = ({
  uri,
  className,
  width,
  height,
}) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <img
          src={DefaultUser}
          alt="Default User"
          className={className}
          onLoad={handleLoad}
          width={width}
          height={height}
        />
      ) : (
        <img
          src={uri || DefaultUser}
          alt="User"
          className={className}
          width={width}
          height={height}
        />
      )}
    </div>
  );
};

export default ImageHolder;
