import { useAppDispatch, useAppSelector } from "store/hooks";
import React, { useCallback, useEffect, useState } from "react";
import { Puff } from "react-loading-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { MatchStatus, states } from "constant/types";
import { getCurrentMatchWithCallback } from "store/currentMatch/currentMatchActions";
import { RootState } from "store/index";
import { setDuelPath } from "store/matchMaking/matchMakingSlice";
import { setScreenStatus } from "store/currentMatch/currentMatchSlice";
import { setTournamentId } from "store/tournament/tournamentSlice";

const InMatchView = () => {
  const dispatch = useAppDispatch();
  const matchmaking = useAppSelector((state) => state.matchMaking);
  const backgroundMatch = matchmaking.backgroundMatch;
  const matchId = matchmaking.matchId;
  const backgroundTournament = matchmaking.backgroundTournament;
  const userId = useAppSelector((state) => state.auth.user._id);
  const linkedAccounts = useAppSelector(
    (state: RootState) => state.linkedAccounts
  );
  const route = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const isShowable =
      backgroundMatch &&
      route?.pathname !== "/duels/lobby" &&
      route?.pathname !== "/duels/won" &&
      route?.pathname !== "/duels/engaged" &&
      route?.pathname !== "/duels/invite-others" &&
      route?.pathname !== "/duels/payment-status" &&
      route?.pathname !== "/duels/lost" &&
      route?.pathname !== "/duels/dispute" &&
      route?.pathname !== "/tournament/live-bracket" &&
      route?.pathname !== "/duels/vote";

    setShow(!!isShowable);
  }, [backgroundMatch, route]);

  useEffect(() => {
    const isShowable =
      backgroundTournament &&
      backgroundTournament?.tournamentStatus !== "ENDED" &&
      route?.pathname.split("/tournament/live-bracket")[0] &&
      route?.pathname.split("/duels/lobby")[0] &&
      route?.pathname.split("/duels/won")[0] &&
      route?.pathname.split("/duels/lost")[0] &&
      route?.pathname.split("/duels/dispute")[0] &&
      route?.pathname.split("/duels/engaged")[0] &&
      route?.pathname.split("/duels/vote")[0] &&
      route?.pathname !== "/tournament/create-match" &&
      route?.pathname !== "/duels/payment-status";
    setShow(!!isShowable);
  }, [backgroundTournament, route]);

  const checkScreen = (currentMatch: any) => {
    switch (currentMatch?.winner) {
      case 2:
        //team 2 won
        const isUserInTeam2 = currentMatch.team2.find(
          (user: string) => user === userId
        );
        if (isUserInTeam2) return navigate(`/duels/won/${matchId}`);
        navigate(`/duels/lost/${matchId}`);
        break;
      case 1:
        //team 1 won
        const isUserInTeam1 = currentMatch.team1.find(
          (user: string) => user === userId
        );
        if (isUserInTeam1) return navigate(`/duels/won/${matchId}`);
        navigate(`/duels/lost/${matchId}`);
        break;
      default:
        break;
    }
  };

  const handleOpenTournament = () => {
    const isHost = backgroundTournament?.host === userId;
    if (isHost) {
      dispatch(setDuelPath("CREATE_TOURNAMENT"));
      dispatch(setScreenStatus("CREATE_TOURNAMENT"));
    } else {
      dispatch(setDuelPath("OPPONENT_JOIN"));
      dispatch(setScreenStatus("OPPONENT_JOIN"));
    }
    dispatch(setTournamentId(backgroundTournament?._id));
    navigate(`/tournament/live-bracket/${backgroundTournament?._id}`);
  };

  const onClick = useCallback(() => {
    setShow(false);
    if (!backgroundMatch && backgroundTournament) return handleOpenTournament();
    switch (backgroundMatch?.matchStatus) {
      case MatchStatus.WAITING_TO_START:
      case MatchStatus.CONNECTING:
      case MatchStatus.SCHEDULED:
      case MatchStatus.WAITING:
        if (!linkedAccounts.accounts.paypal)
          return navigate(`/manage-accounts/paypal/${matchId}`);
        navigate(`/duels/lobby/${matchId}`);
        break;
      case MatchStatus.DISPUTE:
        navigate("/disputes");
        break;
      case MatchStatus.ON_GOING:
        navigate(`/duels/engaged/${matchId}`);
        break;
      case MatchStatus.WAITING_OTHERS_TO_PAY:
      case MatchStatus.WAITING_YOU_TO_VOTE:
      case MatchStatus.WAITING_OTHERS_TO_VOTE:
        navigate(`/duels/vote/${matchId}/LOBBY`);
        break;
      case MatchStatus.WAITING_YOU_TO_PAY:
        dispatch(
          getCurrentMatchWithCallback({
            matchId: matchId,
            callback: (data) => {
              checkScreen(data);
            },
          })
        );
        break;
      default:
        break;
    }
  }, [backgroundMatch, matchId, show]);

  return (
    <>
      {show && (
        <div className="">
          <div className="w-[35%] absolute top-0 right-0 z-30 rounded-xl items-center justify-center gap-4 border-4 bg-opacityButton border-primary m-2">
            <button
              className="flex flex-row items-center justify-between m-4 md:gap-16"
              onClick={onClick}
            >
              <Puff stroke="#EEB034" />
              <div className="flex flex-col">
                <span className="text-[20px] text-white">
                  {backgroundMatch?.matchStatus &&
                    states[backgroundMatch?.matchStatus as keyof typeof states]}
                  {!backgroundMatch &&
                    backgroundTournament &&
                    "Tournament OnGoing"}
                </span>
                <span className="text-[14px] text-subText">
                  Tap here to open
                </span>
              </div>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default InMatchView;
