import { createSlice } from "@reduxjs/toolkit";
import { getPresets } from "./actions";
import { signOut } from "store/auth/actions";

interface PresetSlice {
  presets: any[];
  loader: boolean;
  err: boolean;
}

const initialState: PresetSlice = {
  presets: [],
  loader: false,
  err: false,
};

export const presetSlice = createSlice({
  name: "presets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPresets.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getPresets.fulfilled, (state, action) => {
      state.presets = action.payload.presets;
      state.loader = false;
    });
    builder.addCase(getPresets.rejected, (state) => {
      state.err = true;
      state.loader = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
  },
});

export default presetSlice.reducer;
