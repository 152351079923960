import { getToken } from "firebase/app-check";
import app from "./firebase";

export const getAppCheckToken = async () => {
  try {
    const appCheckToken = await getToken({ app: app }, true);
    return appCheckToken.token;
  } catch (error) {
    console.error("Error getting App Check token:", error);
    return null;
  }
};
