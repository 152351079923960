import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "assets/close-button.svg";
import KeypadInput from "components/KeypadInput";
import { AppDispatch } from "store/index";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setMatchCubes } from "store/matchMaking/matchMakingSlice";
import { setTournamentCubes } from "store/tournament/tournamentSlice";

const HowManyCubesScreen = () => {
  const [value, setValue] = useState("0");
  let { from } = useParams();
  const dispatch: AppDispatch = useAppDispatch();
  const { selectedDuelPath } = useAppSelector((state) => state.matchMaking);
  const navigate = useNavigate();

  const onCancel = () => {
    navigate("/duels");
  };

  const handleNext = () => {
    if (selectedDuelPath === "CREATE_TOURNAMENT") {
      dispatch(setTournamentCubes(value));
    } else {
      dispatch(setMatchCubes(value));
    }
    if (from) return navigate("/duels/duel-overview");
    if (selectedDuelPath === "CREATE_MATCHMAKING")
      return navigate("/duels/matchMaking");
    navigate("/duels/duel-overview");
    // TODO -->> check stream
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen ">
      <div className="container flex flex-col gap-y-[20px] items-center md:my-16 max-md:p-2">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-120px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between max-md:flex-col">
              <span className="text-3xl text-white items-center font-normal max-md:mt-10">
                How Many Cubes?
              </span>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <span className="text-subText text-xl font-normal">
              Max Cubes per player is $10,000
            </span>
          </div>
          <div className="flex justify-evenly items-center desktop:flex-row flex-col desktop:mt-0 mt-5">
            <div className="flex flex-col gap-6 ">
              <div className="flex flex-row items-center justify-center eve rounded-2xl mr-4 w-[300px]  desktop:h-[120px] h-16 ">
                <span className="text-primary text-center font-inter font-semibold desktop:text-3xl  leading-normal tracking-tight text-2xl">
                  Total Price ${value}
                </span>
              </div>
              <div className="flex flex-row items-center justify-center eve rounded-2xl mr-4 w-[300px]  desktop:h-[120px] h-20">
                <span className="text-white text-center font-inter font-semibold desktop:text-5xl tracking-wide leading-normal text-5xl">
                  ${value}
                </span>
              </div>
            </div>
            <div className="flex desktop:mt-0 mt-5 ">
              <KeypadInput
                onChange={(value) => setValue(value)}
                buttonLabel={"Next"}
                buttonPressed={handleNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowManyCubesScreen;
