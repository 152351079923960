import { GetGamesApi, getPresetsApi } from "../../api/common.api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getPresets = createAsyncThunk<any, void>(
  "match/getPresets",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await getPresetsApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
