import { toast } from "react-toastify";
import {
  createCommunityApi,
  deleteCommunityApi,
  editCommunityApi,
  getCommunityChatApi,
  getCommunityListApi,
  getCommunityMembersApi,
  getPrivateChatListApi,
  inviteCommunityApi,
  joinCommunityApi,
  leaveCommunityApi,
  searchCommunitiesApi,
} from "../../api/common.api";
import { createAsyncThunk } from "@reduxjs/toolkit";
interface WithCallback {
  payload: any;
  callback?: (data: any) => void;
}

export const getPrivateChatList = createAsyncThunk<any, number>(
  "/community/private-chat-list",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await getPrivateChatListApi(payload);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getCommunityList = createAsyncThunk<any, number>(
  "/community/private-community-list",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await getCommunityListApi(payload);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addCommunity = createAsyncThunk<any, any>(
  "/community/add-community",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await createCommunityApi(payload);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const searchCommunities = createAsyncThunk<any, WithCallback>(
  "/community/searchCommunities",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await searchCommunitiesApi(payload.query, payload.page);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const joinCommunity = createAsyncThunk<any, WithCallback>(
  "/community/joinCommunity",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await joinCommunityApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const leaveCommunity = createAsyncThunk<any, any>(
  "/community/leaveCommunity",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await leaveCommunityApi(payload.query);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCommunity = createAsyncThunk<any, any>(
  "/community/deleteCommunity",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await deleteCommunityApi(payload.query);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getCommunityMembers = createAsyncThunk<any, any>(
  "/community/community-members",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await getCommunityMembersApi(payload.query);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const editCommunity = createAsyncThunk<any, WithCallback>(
  "/community/edit-community",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await editCommunityApi(payload.query, payload.data);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const inviteCommunity = createAsyncThunk<any, WithCallback>(
  "/community/inviteCommunity",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await inviteCommunityApi(payload);
      callback?.(data);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
