import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { ReactComponent as Mail } from "assets/settings/mail.svg";
import { ReactComponent as Refresh } from "assets/settings/refresh.svg";

import LoadingAnim from "components/Loader";
import CloseIcon from "assets/close-button.svg";

const PastInvitesScreen = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const handleClose = () => navigate("/settings/refer-friend");
  const user = useAppSelector((state: RootState) => state.user);

  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen">
      <div className="container flex items-center flex-col gap-y-[20px] md:my-16 max-md:p-3">
        <div className="backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5 w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh] overflow-y-auto">
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <div className="flex flex-row items-center gap-4">
              <Refresh className="h-8 w-8 max-md:h-6 max-md:w-6" />
              <span className="text-3xl max-md:text-xl text-white font-normal">
                Past Invites
              </span>
            </div>
            <button
              className=" h-8 w-8 max-md:h-6 max-md:w-6"
              onClick={handleClose}
            >
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>

          <div className="flex flex-col max-md:w-full items-center justify-center gap-5 mt-12 ">
            <Mail />
            <span className="text-darkGray text-center">
              Here, you can track all the people you've invited who have
              successfully signed up.
            </span>
            <span className="text-2xl text-white font-semibold">
              Past Invites
            </span>
            <div className="w-full flex gap-x-[40px] gap-y-[20px] flex-wrap overflow-y-auto justify-center p-1 max-md:h-2/3">
              <div className="border-dashed">
                <span className=" flex border-darkGray text-darkGray justify-center items-center border-2 border-dashed h-16 rounded-xl m-5 p-5">
                  No referrals have signed up yet
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={user.loader} />
    </div>
  );
};

export default PastInvitesScreen;
