import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { AppDispatch } from "store/index";
import Coin from "assets/coin.png";
import ButtonDc from "components/Button";
import CloseIcon from "assets/close-button.svg";

export default function AboutStakingMainScreen({}) {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();

  const onCancel = () => {
    navigate("/more-events/Staking-Events");
  };

  const handleWhatsStaking = () => {
    navigate("/stakes/intro");
  };
  return (
    <div className="mx-auto w-full flex-col bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex items-center h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center justify-between">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10 w-[calc(100%-176px)] 
        h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]   max-md:h-[95vh] overflow-y-auto"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white items-center font-normal ">
              What’s Staking
            </span>
            <button className=" w-[25px] h-[25px]" onClick={onCancel}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="flex flex-col items-center gap-[6vh] mt-[10vh] justify-center max-md:flex-col">
            <img src={Coin} alt="coin" />
            <span className="text-primary text-center text-2xl">Stakes</span>
            <span className="text-center text-subText">
              Your stakes on tournaments will show up here once you’ve confirmed
              them
            </span>
            <ButtonDc
              type="primary"
              text="What's Staking"
              action={handleWhatsStaking}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
