import React, { ChangeEvent } from "react";

interface PasswordInputProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  label?: string;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  onChange,
  onFocus,
  onBlur,
  placeholder,
  value,
  label,
}) => {
  const isMobile = window.innerWidth <= 768;
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative w-full mt-[20px]">
      <input
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        id="password"
        className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-white outline-none bg-inputGray border-0 border-b-2 border-inputGray appearance-none focus:outline-none focus:ring-0 focus:border-primaryYellow peer"
        placeholder={placeholder ? placeholder : ""}
      />
      <label
        htmlFor="password"
        className="absolute text-sm text-white duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primaryYellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
      >
        {label}
      </label>
      <button
        onClick={togglePasswordVisibility}
        type="button"
        className="absolute right-4 top-4 text-white"
      >
        <i className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"}`}></i>
      </button>
    </div>
  );
};

export default PasswordInput;
