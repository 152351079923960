import { createSlice } from "@reduxjs/toolkit";
import { getCommunityChatMessages, getPersonalChatMessages } from "./action";
import { signOut } from "store/auth/actions";

interface MessagingSlice {
  _id: string;
  chats: any;
  communityChats: any;
  loader: boolean;
}

interface Chat {
  user: string;
  message: string;
  _id: string;
  time: string;
}

interface CommunityChat {
  user: string;
  message: string;
  _id: string;
  time: string;
  username: string;
  profilePicture: string;
}

interface Result {
  date: string;
  chats: Chat[];
  communityChats: CommunityChat[];
}

type ResultsArray = Result[] | [];

const initialState: MessagingSlice = {
  _id: "",
  chats: [],
  communityChats: [],
  loader: false,
};

export const messagingSlice = createSlice({
  name: "messaging",
  initialState,
  reducers: {
    updatePersonalChatMessages: (state, action) => {
      state.chats = {
        ...state?.chats,
        messages: [...(state?.chats?.messages || []), action?.payload],
      };
    },
    updateCommunityChatMessages: (state, action) => {
      state.communityChats = {
        ...state?.communityChats,
        messages: [...(state?.communityChats?.messages || []), action?.payload],
      };
    },
    updateChatId: (state, action) => {
      state._id = action.payload;
    },
    clearChats: (state) => {
      state.chats = [];
      state.communityChats = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPersonalChatMessages.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getPersonalChatMessages.fulfilled, (state, action) => {
      state.chats = action.payload;
      state.loader = false;
    });
    builder.addCase(getPersonalChatMessages.rejected, (state) => {
      state.loader = false;
    });

    builder.addCase(getCommunityChatMessages.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCommunityChatMessages.fulfilled, (state, action) => {
      state.communityChats = action.payload;
      state.loader = false;
    });
    builder.addCase(getCommunityChatMessages.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
  },
});

export default messagingSlice.reducer;
export const {
  updatePersonalChatMessages,
  clearChats,
  updateCommunityChatMessages,
} = messagingSlice.actions;
