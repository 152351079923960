import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { gameTypeValues, gameTypes } from "types/constants";
import { resetCurrentMatch } from "store/currentMatch/currentMatchSlice";
import { resetBackgroundMatch } from "store/matchMaking/matchMakingSlice";
import { getTournamentById } from "store/liveBrackets/actions";
import { resetLiveBrackets } from "store/liveBrackets/liveBracketSlice";
import { ReactComponent as Arrow } from "assets/arrow-right-gaming.svg";
import {
  resetTournamentId,
  resetTournamentMaking,
} from "store/tournament/tournamentSlice";

import ImageHolder from "components/ImagePlaceholder";
import CloseIcon from "assets/close-button.svg";
import LoadingAnim from "components/Loader";
import { RootState } from "store/index";

type MatchTeam = {
  _id: string;
  username: string;
  profilePicture: string;
};

type Match = {
  match: string;
  stage: number;
  matchNumber: number;
  _id: string;
  team1: MatchTeam[];
  team2: MatchTeam[];
  winner: number;
  isUserIn: boolean;
};

const TournamentSummaryScreen = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tournamentID } = useParams();
  const [gameType, setGameType] = useState("");
  const [rounds, setRounds] = useState("");
  const [amount, setAmount] = useState("");
  const [matchList, setMatchList] = useState<Match[]>([]);
  const [tournamentWinner, setTournamentWinner] = useState(0);
  const { loading } = useAppSelector((state: RootState) => state.liveBrackets);
  const maxStage = Math.max(...matchList.map((match) => match?.stage));
  const emptyArray = new Array(maxStage | 0).fill(0);

  useEffect(() => {
    handleGetTournamentInfo();
    if (!tournamentID) return;
  }, []);

  const handleGetTournamentInfo = () => {
    dispatch(
      getTournamentById({
        payload: tournamentID,
        callback: (res) => {
          setGameType(res.gameType);
          setRounds(res.rounds);
          setAmount(res.cubeWager);
          const finalMatch = res.matchList[res.matchList.length - 1];
          setTournamentWinner(finalMatch.winner);
          setMatchList(res.matchList);
        },
      })
    );
  };

  const handleClose = () => {
    dispatch(resetTournamentMaking());
    dispatch(resetCurrentMatch());
    dispatch(resetBackgroundMatch());
    dispatch(resetLiveBrackets());
    dispatch(resetTournamentId());
    setTimeout(() => {
      navigate("/");
    }, 300);
  };

  const matchesArrayByStage = emptyArray.map((_, index) => {
    return {
      stage: index + 1,
      matches: matchList.filter((match) => match.stage === index + 1),
    };
  });

  const renderMatches = () => {
    return matchesArrayByStage.map((stage) => {
      return (
        <div
          key={stage.stage}
          className="my-5 w-full flex flex-col items-center"
        >
          <div className="text-center bg-modalFade md:w-2/3 w-full p-2 rounded-lg">
            <span className="text-white text-lg">Stage {stage.stage}</span>
          </div>
          <div className="flex flex-col items-center mt-4">
            {stage.matches.map((match) => {
              const playerCount = match?.team1?.length || 0;
              const isColumn = playerCount > 2;
              return (
                <div
                  key={match._id}
                  className={`flex ${
                    isColumn ? "flex-col" : "flex-row"
                  } items-center my-2`}
                >
                  <div className="flex items-center border border-white p-2 rounded-lg gap-2">
                    <div className="flex flex-wrap justify-evenly gap-1">
                      {match?.team1?.map((player, index) => (
                        <ImageHolder
                          uri={player?.profilePicture}
                          className="w-12 h-12 rounded-md"
                          key={index}
                        />
                      ))}
                    </div>
                    <span className="text-white text-lg">VS</span>
                    <div className="flex flex-wrap justify-evenly gap-1">
                      {match?.team2?.map((player, index) => (
                        <ImageHolder
                          uri={player?.profilePicture}
                          className="w-12 h-12 rounded-md"
                          key={index}
                        />
                      ))}
                    </div>
                  </div>
                  <div
                    className={`flex ${
                      isColumn ? "flex-col" : "flex-row"
                    } items-center mx-4`}
                  >
                    <Arrow className="w-6 h-5" />
                  </div>
                  {match.winner !== 0 && (
                    <div className="flex items-center border border-primary p-2 rounded-lg gap-2">
                      {match?.[match.winner === 1 ? "team1" : "team2"]?.map(
                        (player, index) => (
                          <ImageHolder
                            uri={player?.profilePicture}
                            className="w-12 h-12 rounded-md"
                            key={index}
                          />
                        )
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex gap-[7em] flex flex-col items-center  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]   max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl max-md:text-xl text-white items-center font-medium">
              Tournament Summary
            </span>
            <button className=" w-[25px] h-[25px]" onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>

          <div className="flex flex-col justify-center w-full items-center my-6">
            <div className="flex flex-row gap-5 justify-between max-md:justify-evenly max-md:w-full">
              {[
                { label: "Best", value: rounds.replace("best ", "") },
                {
                  label: "Game Type",
                  value: gameTypeValues[gameType as gameTypes],
                },
                { label: "Cube Wager", value: `$${amount}` },
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col bg-borderColor py-1 px-4 md:w-[10vw] max-md:h-20 justify-center w-1/3 text-center items-center rounded-[12px]"
                >
                  <span className="max-md:text-sm text-xl text-subText font-normal">
                    {item.label}
                  </span>
                  <span className="max-md:text-sm text-xl font-medium text-white">
                    {item.value}
                  </span>
                </div>
              ))}
            </div>

            <div className="flex flex-col items-center w-full gap-4">
              {renderMatches()}
              <div className="text-center bg-modalFade md:w-2/3 w-full p-2 rounded-lg">
                <span className="text-white text-lg">Tournament Winner</span>
              </div>
              <div className="flex justify-center mt-4">
                <div className="flex items-center border border-primary p-2 rounded-lg gap-2">
                  {matchList[matchList.length - 1]?.[
                    tournamentWinner === 1 ? "team1" : "team2"
                  ]?.map((player, index) => (
                    <ImageHolder
                      uri={player?.profilePicture}
                      className="w-12 h-12 rounded-md"
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={loading} />
    </div>
  );
};
export default TournamentSummaryScreen;
