import { createSlice } from "@reduxjs/toolkit";
import { getLinkedAccounts } from "./action";
import { signOut } from "store/auth/actions";

interface linkedAccountsSlice {
  accounts: {
    playstation: string;
    twitch: string;
    activisionId: string;
    ea: string;
    xbox: string;
    psn: string;
    paypal: string;
    epic: string;
    streetFighter: string;
    venmo: string;
    mlbId: string;
  };
  loading: boolean;
  err: boolean;
}

const initialState: linkedAccountsSlice = {
  accounts: {
    playstation: "",
    twitch: "",
    activisionId: "",
    ea: "",
    xbox: "",
    psn: "",
    paypal: "",
    epic: "",
    streetFighter: "",
    venmo: "",
    mlbId: "",
  },
  loading: false,
  err: false,
};

const linkedAccountsSlice = createSlice({
  name: "linkedAccounts",
  initialState,
  reducers: {
    clearData: (state) => {
      state.accounts = {
        playstation: "",
        twitch: "",
        activisionId: "",
        ea: "",
        xbox: "",
        psn: "",
        paypal: "",
        epic: "",
        streetFighter: "",
        venmo: "",
        mlbId: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLinkedAccounts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLinkedAccounts.fulfilled, (state, action) => {
      state.accounts = { ...state.accounts, ...action.payload?.accounts };
      state.loading = false;
    });
    builder.addCase(getLinkedAccounts.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
  },
});

export default linkedAccountsSlice.reducer;
export const { clearData } = linkedAccountsSlice.actions;
