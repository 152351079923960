import ButtonDc from "components/Button";
import Sidebar from "components/SideBar";

import { useNavigate } from "react-router-dom";
import { ReactComponent as StakeIcon } from "assets/sideBar/stake.svg";
import { ReactComponent as MatchIcon } from "assets/sideBar/match.svg";

export default function WageringOptionsScreen({}) {
  const navigate = useNavigate();

  return (
    <div className="mx-auto w-full  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
      <Sidebar />
      <div className=" w-full md:gap-[2em] flex flex-col items-center mt-10 max-md:gap-3 max-md:my-20 max-md:overflow-y-auto">
        <div
          className="backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]"
        >
          <div className="flex flex-col gap-10">
            <span className="text-3xl text-white font-bold text-center max-md:mt- max-md:text-2xl">
              Wagering Options
            </span>

            <span className=" text-white text-center text-xl font-normal max-md:text-lg">
              Choose how you want to wager: Play against others or place your
              bets on ongoing games.
            </span>
          </div>
          <div className="flex desktop:flex-row flex-col items-center justify-center desktop:mt-20 gap-5 my-8 max-md:gap-2">
            <div className="flex flex-col md:min-w-[400px] w-[25vw] h-[25vh] max-md:w-full rounded-xl p-5 items-center justify-center bg-borderColor gap-2">
              <div className="flex flex-col justify-center gap-2 items-center">
                <StakeIcon className="text-white flex-col  w-10 h-10" />
                <span className="text-white font-bold">Stake and Win</span>
              </div>
              <span className="text-disabled text-center w-2/3 text-xs">
                Place bets on ongoing matches. Predict the winner and earn big!
              </span>
              <ButtonDc
                type="primary"
                text="Continue"
                action={() => {
                  navigate("/more-events/Staking-Events");
                }}
                short
              />
            </div>
            <div className="flex flex-col md:min-w-[400px] w-[25vw] h-[25vh] max-md:w-full  rounded-xl p-5 items-center justify-center bg-borderColor gap-2">
              <div className="flex flex-col justify-center gap-2 items-center">
                <MatchIcon className="text-white flex-col  w-10 h-10" />
                <span className="text-white font-bold">Play Match</span>
              </div>
              <span className="text-disabled text-center w-2/3 text-xs">
                Challenge other players and bet on your skills. Winner takes the
                pot!
              </span>
              <ButtonDc
                type="primary"
                text="Continue"
                action={() => {
                  navigate("/duels");
                }}
                short
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
