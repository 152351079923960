import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ConfirmEmailApi,
  ResendEmailApi,
  SignOutApi,
  resetPasswordApi,
  sendOTPApi,
  signInApi,
  signUpApi,
  validUserApi,
  validateOtpApi,
  verifyEmailApi,
} from "../../api/auth.api";
import { getPrizePoolValueApi } from "api/common.api";
import { toast } from "react-toastify";

interface WithCallback {
  callback?: (data?: any) => void;
  failed?: () => void;
  payload?: any;
}

export const signOut = createAsyncThunk<any, WithCallback>(
  "auth/signOut",
  async ({ payload }: any, { rejectWithValue }) => {
    try {
      const data: any = await SignOutApi();
      payload?.callback?.();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const signUp = createAsyncThunk<any, any>(
  "auth/signUp",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await signUpApi(payload);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const signIn = createAsyncThunk<any, any>(
  "auth/signIn",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await signInApi(payload);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getPrizePoolValue = createAsyncThunk<any, void>(
  "/user/price-pool",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await getPrizePoolValueApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const verifyEmail = createAsyncThunk<any, any>(
  "auth/verifyEmail",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await verifyEmailApi(payload);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const resendEmail = createAsyncThunk<any, any>(
  "auth/resendEmail",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await ResendEmailApi(payload);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const confirmEmail = createAsyncThunk<any, any>(
  "auth/confirmEmail",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await ConfirmEmailApi(payload);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const sendOtp = createAsyncThunk<any, WithCallback>(
  "auth/sendOtp",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await sendOTPApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);

      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const validateOtp = createAsyncThunk<any, WithCallback>(
  "auth/validateOtp",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await validateOtpApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const resetPassword = createAsyncThunk<any, WithCallback>(
  "auth/resetPassword",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await resetPasswordApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const verifyUser = createAsyncThunk<void, WithCallback>(
  "auth/resetPassword",
  async ({ failed, payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await validUserApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      failed?.();
      toast.error(error.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
