import React, { useState } from "react";
import Next from "assets/next-arrow.svg";
import CloseIcon from "assets/close-button.svg";
import NextDisabled from "assets/next-arrow-disabled.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { setSelectedRound } from "store/matchMaking/matchMakingSlice";
import { setSelectedTournamentRound } from "store/tournament/tournamentSlice";

export default function HowManyRoundsScreen({}) {
  let { from } = useParams();
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const dispatch: AppDispatch = useAppDispatch();
  const matchMaking = useAppSelector((state: RootState) => state.matchMaking);
  const isNextDisabled = !selected;
  const duelPath = matchMaking.selectedDuelPath;
  const tournaments = useAppSelector((state: RootState) => state.tournament);
  const rounds =
    duelPath == "CREATE_TOURNAMENT"
      ? tournaments.selectedGame?.rounds
      : matchMaking.selectedGame?.rounds;

  const onCancel = () => {
    navigate("/duels");
  };
  const handleNext = () => {
    if (duelPath === "CREATE_TOURNAMENT") {
      dispatch(setSelectedTournamentRound(selected));
    } else {
      dispatch(setSelectedRound(selected));
    }
    if (from) return navigate("/duels/duel-overview");
    navigate("/duels/how-many-cubes");
  };

  const renderRounds = (item: any, index: number) => {
    const selectedItem = item === selected;
    return (
      <button
        className={`bg-borderColor h-16 w-52 rounded-lg p-1.5 ${
          selectedItem ? "border-4 border-[#FFD482]" : ""
        }`}
        key={index}
        onClick={() => setSelected(item)}
      >
        <text className="text-white text-xl">{item.name}</text>
      </button>
    );
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <span className="text-3xl text-white items-center font-normal">
                How many rounds?
              </span>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <span className="text-subText text-xl font-normal">
              How many rounds you would like to play...
            </span>
          </div>

          <div className="flex flex-row items-center justify-center gap-[3em] my-8 max-md:flex-col">
            {rounds?.map(renderRounds)}
          </div>

          <button
            className="absolute right-0 bottom-0 mr-[30px] mb-[30px] border-0  border-none outline-none"
            onClick={handleNext}
            disabled={isNextDisabled}
          >
            <img
              src={isNextDisabled ? NextDisabled : Next}
              width={100}
              height={100}
              alt="logo"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
