import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "./store"; // Adjust the import based on your store configuration
import { useAppDispatch, useAppSelector } from "store/hooks";
import { verifyUser } from "store/auth/actions";

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const user = useAppSelector((state: RootState) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();

  const token = window.location?.href?.split("authorize=")?.[1];

  useEffect(() => {
    if (token) {
      <Navigate
        to={`/${
          window.location?.href?.split("?authorize=")?.[0]?.split("gaming")[1]
        }`}
        state={{ from: location }}
      />;
      //TODO temperaly removed---> need to reset after BE changes
      // dispatch(
      //   verifyUser({
      //     payload: token,
      //     callback: () => {
      //       return (
      //         <Navigate
      //           to={`/${
      //             window.location?.href
      //               ?.split("?authorize=")?.[0]
      //               ?.split("gaming")[1]
      //           }`}
      //           state={{ from: location }}
      //         />
      //       );
      //     },
      //     failed: () => {
      //       if (!user || !user?.userToken) {
      //         return (
      //           <Navigate
      //             to={`/${
      //               window.location?.href
      //                 ?.split("?authorize=")?.[0]
      //                 ?.split("gaming")[1]
      //             }`}
      //             state={{ from: location }}
      //           />
      //         );
      //         // return <Navigate to="/login" state={{ from: location }} />;
      //       }
      //     },
      //   })
      // );
    }
  }, [token]);

  if (!user || !user?.userToken) {
    if (!token) return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;
