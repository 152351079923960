import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "store/index";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getStakeAmount } from "store/stake/action";
import { toast } from "react-toastify";
import CloseIcon from "assets/close-button.svg";
import KeypadInput from "components/KeypadInput";
import LoadingAnim from "components/Loader";
import PaymentMethod from "components/PaymentMethod";

const StakingAmountScreen = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.wallet)?.loader;
  const { matchId } = useAppSelector((state) => state.stake);
  const [value, setValue] = useState("0");
  const [readyToPay, setReadyToPay] = useState(false);

  const onCancel = () => {
    navigate("/more-events/Staking-Events");
  };

  const handlePaymentSuccess = () => {
    setReadyToPay(false);
    toast.success("Successfully staked.");
    navigate("/");
  };

  const handleNext = () => {
    setReadyToPay(true);
  };

  useEffect(() => {
    dispatch(getStakeAmount(matchId));
  }, [dispatch]);

  useEffect(() => {
    const token = window.location?.search
      ?.split("&token=")?.[1]
      ?.split("&")?.[0];
    if (!token) return;
    handleNext();
  }, []);

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen ">
      <LoadingAnim loading={isLoading} />
      {readyToPay && (
        <PaymentMethod
          staking
          amount={parseInt(value)}
          payId={matchId}
          onSuccess={handlePaymentSuccess}
          back
          onClose={() => setReadyToPay(false)}
        />
      )}
      <div className="container flex flex-col gap-y-[20px] items-center md:my-16 max-md:p-2">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(80vh)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh] relative md:top-8"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between max-md:flex-col">
              <span className="text-3xl text-white items-center font-normal max-md:mt-10">
                Stake & Win
              </span>
              <button className=" w-6 h-6 absolute right-10" onClick={onCancel}>
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="flex flex-row justify-evenly items-center  max-md:flex-col mt-16">
            <div className="flex flex-col gap-6 ">
              <div className="flex flex-row items-center justify-center eve rounded-2xl mr-4 w-[300px]  h-[120px] max-md:h-20">
                <span className="text-white text-center font-inter font-semibold text-5xl tracking-wide leading-normal max-md:text-5xl">
                  ${value}
                </span>
              </div>
            </div>
            <div className="flex mt-16">
              <KeypadInput
                onChange={(value) => setValue(value)}
                buttonLabel={`Stakes  $${value}`}
                buttonPressed={handleNext}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingAmountScreen;
