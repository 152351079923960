import React, { useEffect, useState } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch } from "store/index";
import { ReactComponent as Transactions } from "assets/transactions.svg";
import WalletIcon from "assets/walletIcon.png";
import { ReactComponent as SendMoney } from "assets/sendMoney.svg";
import { ReactComponent as RightArrow } from "assets/rightArrow.svg";
import { ReactComponent as Withdraw } from "assets/withdraw.svg";
import { ReactComponent as Request } from "assets/requestMoney.svg";
import { ReactComponent as History } from "assets/history.svg";
import { getUser } from "store/user/action";
import { getBalance } from "store/wallet/action";
import { getLinkedAccounts } from "store/linkedAccounts/action";
import { WalletStatus } from "types/constants";
import LoadingAnim from "components/Loader";

export default function WalletScreen({}) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const { balance, dcBalance, loader } = useAppSelector(
    (state) => state.wallet
  );
  const { user } = useAppSelector((state) => state.user);
  const { accounts } = useAppSelector((state) => state.linkedAccounts);

  useEffect(() => {
    dispatch(getBalance());
    dispatch(getLinkedAccounts());
  }, []);

  const isVerificationPending =
    user?.documentsSubmitted && !user?.documentsApproved;

  const onCancel = () => {
    navigate("/");
  };

  const OnClickWithdraw = () => {
    if (isVerificationPending)
      return navigate("/wallet/verification-processing"); // check user's documents are pending
    if (!user?.documentsSubmitted) return navigate("/wallet/withdraw-intro"); //check is that first time
    if (!accounts.paypal) return navigate("/manage-accounts/paypal/wallet"); //connect paypal
    navigate("/wallet/withdraw-funds");
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <Transactions />
                <span className="text-3xl text-white items-center font-normal">
                  Wallet
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center gap-1  max-md:flex-col ">
            <div className="flex flex-col items-center w-full desktop:w-1/3 justify-center p-10 my-4 gap-1 border-2 border-primary rounded-2xl">
              <img src={WalletIcon} alt="wallet" />
              <span className=" text-subText mt-2 text-l">Your Balance</span>
              <span className="text-white font-bold text-3xl">
                {balance ? `$ ${balance.toFixed(2)}` : `$ 0`}
              </span>
              <span className=" text-subText mt-1 text-l">Your DC Balance</span>
              <span className="text-white text-2xl">
                {dcBalance ? `${dcBalance} DC` : "0 DC"}
              </span>
            </div>
          </div>
          <div className="flex desktop:flex-row flex-col desktop:gap-0 gap-4 justify-between desktop:items-center my-4">
            <button
              className="bg-borderColor text-white h-16 rounded-lg flex flex-row items-center justify-between p-4 desktop:w-[40%] w-full"
              onClick={() =>
                navigate(`/wallet/send-money/${WalletStatus.SEND}`)
              }
            >
              <div className="flex flex-row desktop:gap-10 gap-4 justify-center items-center">
                <SendMoney />
                <span className=" text-white"> Send Money</span>
              </div>
              <RightArrow />
            </button>
            <button
              className="bg-borderColor text-white h-16  rounded-lg flex flex-row items-center justify-between p-4  desktop:w-[40%] w-full"
              onClick={() =>
                navigate(`/wallet/send-money/${WalletStatus.REQUEST}`)
              }
            >
              <div className="flex flex-row desktop:gap-10 gap-4 justify-center items-center">
                <Request />
                <span className=" text-white"> Request Money</span>
              </div>
              <RightArrow />
            </button>
          </div>
          <div className="flex desktop:flex-row flex-col desktop:gap-0 gap-4 justify-between md:items-center">
            <button
              className="bg-borderColor h-16 rounded-lg flex flex-row items-center justify-between p-4  desktop:w-[40%] w-full"
              onClick={OnClickWithdraw}
            >
              <div className="flex flex-row desktop:gap-10 gap-4 justify-center items-center">
                <Withdraw />
                <span className=" text-white">Withdraw</span>
              </div>
              <RightArrow />
            </button>
            <button
              className="bg-borderColor text-white h-16 rounded-lg flex flex-row items-center justify-between p-4  desktop:w-[40%] w-full"
              onClick={() => navigate("/wallet/transaction-history")}
            >
              <div className="flex flex-row desktop:gap-10 gap-4 justify-center items-center">
                <History />
                <span className=" text-white">Transaction History</span>
              </div>
              <RightArrow />
            </button>
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
}
