import {
  createDcSteamApi,
  createSteamApi,
  endStreamApi,
  getLiveStreamsApi,
  searchLiveStreamsApi,
  startRecordingApi,
  stopRecordingApi,
  streamForExternalApi,
} from "../../api/common.api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
interface WithCallback {
  payload?: any;
  callback?: (data: any) => void;
}
export const createStream = createAsyncThunk<any, WithCallback>(
  "stream/createStream",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await createSteamApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      // toast.error(error.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const streamForExternal = createAsyncThunk<any, WithCallback>(
  "stream/streamForExternal",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await streamForExternalApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getLiveStreams = createAsyncThunk<any, any>(
  "/stream/lives",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await getLiveStreamsApi(payload);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const endStream = createAsyncThunk<any, WithCallback>(
  "/stream/endStream",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await endStreamApi();
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const startRecording = createAsyncThunk<any, any>(
  "/stream/startRecording",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await startRecordingApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const stopRecording = createAsyncThunk<any, any>(
  "/stream/stopRecording",
  async (_, { rejectWithValue }) => {
    try {
      const data: any = await stopRecordingApi();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createDcStream = createAsyncThunk<any, WithCallback>(
  "stream/createDcStream",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await createDcSteamApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const searchLiveStreams = createAsyncThunk<any, any>(
  "/stream/lives",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const data: any = await searchLiveStreamsApi(payload.searchKey);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
