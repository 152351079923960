import React from "react";

const ReferralsScreen = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-6">
        Referral Program: How it Works
      </h1>
      <div className="max-w-xl mx-auto">
        <p className="mb-4">
          Welcome to our exciting referral program! We've designed a seamless
          way for you to earn rewards simply by inviting your friends to join
          our thrilling gambling mobile app. Here's how it works:
        </p>
        <ol className="list-decimal pl-6 mb-6">
          <li className="mb-2">
            <strong>Share Your Unique Referral Link:</strong> Once you've signed
            up and started enjoying the excitement of our app, you'll receive a
            unique referral link. Share this link with your friends, family, and
            fellow gambling enthusiasts through social media, email, or any
            other preferred method.
          </li>
          <li className="mb-2">
            <strong>Friends Join Using Your Link:</strong> When someone clicks
            on your referral link, they'll be directed to download and sign up
            for our app. As they create their account, our system automatically
            recognizes that they've been referred
          </li>
        </ol>
      </div>
    </div>
  );
};

export default ReferralsScreen;