import {
  deleteTournamentMatchApi,
  getStagedPlayersApi,
  getTournamentByIdApi,
} from "api/tournament.api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

interface WithCallback {
  payload: any;
  callback?: (data: any) => void;
  errLog?: () => void;
}

export const getTournamentById = createAsyncThunk<any, WithCallback>(
  "tournament/getTournamentById",
  async ({ payload, callback, errLog }, { rejectWithValue }) => {
    try {
      const data: any = await getTournamentByIdApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      errLog?.();
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getStagedPlayers = createAsyncThunk<any, WithCallback>(
  "tournament/getStagedPlayers",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await getStagedPlayersApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTournamentMatch = createAsyncThunk<any, WithCallback>(
  "tournament/deleteTournamentMatch",
  async ({ payload, callback, errLog }, { rejectWithValue }) => {
    try {
      const data: any = await deleteTournamentMatchApi(payload);
      callback?.(data);
      toast.success("Tournament match deleted.");
      return data;
    } catch (error: any) {
      errLog?.();
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
