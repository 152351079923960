import Api from "../services/api";
import {
  confirmUserEmailOtpUrl,
  resetPasswordUrl,
  sendOTPUrl,
  setResendEmailUrl,
  setVerifyEmailUrl,
  signInUrl,
  signOutUrl,
  signUpUrl,
  userValidityUrl,
  validateOtpUrl,
} from "./urls";

export const SignOutApi = () =>
  Api.request({
    method: "post",
    url: signOutUrl,
  });

export const signInApi = (body: object) =>
  Api.request({
    publicApi: true,
    method: "post",
    url: signInUrl,
    body,
  });

export const signUpApi = (body: object) =>
  Api.request({
    publicApi: true,
    method: "post",
    url: signUpUrl,
    body,
  });

export const verifyEmailApi = (body: object) =>
  Api.request({
    publicApi: true,
    method: "post",
    url: setVerifyEmailUrl,
    body,
  });

export const ResendEmailApi = (body: object) =>
  Api.request({
    publicApi: true,
    method: "post",
    url: setResendEmailUrl,
    body,
  });
export const ConfirmEmailApi = (body: object) =>
  Api.request({
    publicApi: true,
    method: "post",
    url: confirmUserEmailOtpUrl,
    body,
  });
export const sendOTPApi = (body: object) => {
  return Api.request({
    publicApi: true,
    method: "post",
    url: sendOTPUrl,
    body,
  });
};
export const validateOtpApi = (body: object) => {
  return Api.request({
    publicApi: true,
    method: "post",
    url: validateOtpUrl,
    body,
  });
};
export const resetPasswordApi = (body: object) => {
  return Api.request({
    publicApi: true,
    method: "post",
    url: resetPasswordUrl,
    body,
  });
};

export const validUserApi = (payload: string) => {
  return Api.request({
    publicApi: true,
    validUser: payload,
    method: "post",
    url: userValidityUrl,
  });
};
