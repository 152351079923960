import Api from "../services/api";
import {
  voteMatchUrl,
  checkStreamingUrl,
  getMatchChatUrl,
  sendMatchMessageUrl,
  getStreamUrl,
} from "./urls";

export const voteMatchApi = (body: any) => {
  return Api.request({
    method: "post",
    url: voteMatchUrl,
    body,
  });
};
export const checkStreamApi = (body: any) => {
  return Api.request({
    method: "post",
    url: checkStreamingUrl,
    body,
  });
};

export const getMatchChatApi = (matchId: string) => {
  return Api.request({
    method: "get",
    url: getMatchChatUrl + `/${matchId}`,
  });
};

export const sendMatchMessageApi = (body: object) => {
  return Api.request({
    method: "post",
    url: sendMatchMessageUrl,
    body,
  });
};

export const getStreamApi = (query: any) =>
  Api.request({
    method: "get",
    url: getStreamUrl + `/${query}`,
  });
