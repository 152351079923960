import { useNavigate } from "react-router-dom";
import { ReactComponent as Support } from "assets/settings/support.svg";
import { ReactComponent as Contact } from "assets/contact.svg";
import CloseIcon from "assets/close-button.svg";

const ContactUsScreen = () => {
  const navigate = useNavigate();
  const handleClose = () => navigate("/settings");

  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen">
      <div className="container flex items-center flex-col gap-y-[20px] md:my-16 max-md:p-3">
        <div className="backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5 w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh] overflow-y-auto">
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <div className="flex flex-row items-center gap-4">
              <Contact className="h-8 w-8 max-md:h-6 max-md:w-6" />
              <span className="text-3xl max-md:text-xl text-white font-normal">
                Contact Us
              </span>
            </div>
            <button
              className=" h-8 w-8 max-md:h-6 max-md:w-6"
              onClick={handleClose}
            >
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>

          <div className="flex flex-col max-md:w-full items-center justify-center gap-5 mt-12 ">
            <Support className="w-[20vh] h-[20vh]" />
            <div className=" bg-cardBackground border border-white rounded-3xl justify-center h-[40vh] md:min-h-[300px] md:w-[30vw] w-full md:min-w-[500px] flex flex-col gap-1 md:p-10 p-5">
              <span className="text-darkGray md:text-2xl">Contact Number</span>
              <span className="text-primary md:text-xl underline">
                +1 (719) 452 0703
              </span>
              <span className="text-darkGray md:text-2xl md:mt-[5vh] mt-5">
                Email Address
              </span>
              <span className="text-primary md:text-xl underline">
                info@duelcube.com
              </span>
              <span className="text-darkGray md:text-2xl md:mt-[5vh] mt-5">
                Address
              </span>
              <span className="text-primary md:text-xl underline">
                Duel Cube LLC, 1915 lake Ave pueblo, CC 81004
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsScreen;
