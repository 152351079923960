import React from "react";
import { ReactComponent as Live } from "assets/streaming/live.svg";
import { ReactComponent as Dot } from "assets/dots.svg";
import ImageHolder from "./ImagePlaceholder";
interface StreamingLiveViewerProps {
  paused?: boolean;
  hideDetails?: boolean;
  muted?: boolean;
  controls?: boolean;
  uri: string;
  profilePicture?: string;
  streamTitle?: string;
  watchingCount?: number;
  userName?: string;
  streamRoomId?: string;
  streamToken?: string;
  thumbnail?: string;
  onPressStream?: (item: any) => void;
}

const StreamingLiveViewer: React.FC<StreamingLiveViewerProps> = ({
  paused,
  muted,
  controls,
  uri,
  profilePicture,
  streamTitle,
  watchingCount,
  userName,
  hideDetails,
  thumbnail,
  onPressStream,
}) => {
  return (
    <div className="flex flex-col gap-2 my-4">
      <button onClick={onPressStream} className="hover:animate-pulse">
        {thumbnail ? (
          <div className="relative h-[calc(100vh/3.5)]">
            <img
              src={thumbnail}
              alt="thumbnail"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        ) : (
          <div className="relative h-[calc(100vh/3.5)]">
            <img
              src={thumbnail}
              alt="thumbnail"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        )}
      </button>
      {!hideDetails && (
        <div className="flex justify-between items-center px-2">
          <div className="flex items-center gap-2.5 rounded-xl ">
            <div className="relative">
              <ImageHolder
                height={50}
                width={50}
                uri={profilePicture}
                className=" rounded-full border-2 border-brightRed"
              />
              <Live className="absolute bottom-0 left-1/2 transform -translate-x-1/2 h-3 w-10" />
            </div>
            <div className="flex flex-col items-start">
              <p className="text-white text-left text-xs max-w-[calc(100vw/1.8)]">
                {streamTitle}
              </p>
              <p className="text-placeholder text-xs">
                {userName} | {watchingCount} watching
              </p>
            </div>
          </div>
          <button>
            <Dot height={16} />
          </button>
        </div>
      )}
    </div>
  );
};

export default StreamingLiveViewer;
